import React, { memo, useMemo, useCallback, useContext, useState } from "react";
import ComboBox from "react-responsive-combo-box";
import "react-responsive-combo-box/dist/index.css";
import { AppContext } from "../AppContext";

export const TaniComboBox = memo((props) => {
  const { meishos } = useContext(AppContext);

  // 時間区分
  const optionList = useMemo(() => {
    return (meishos || [])
      .filter((v) => v.type === "TANI_KBN")
      .sort((a, b) => a.hyojiJun - b.hyojiJun)
      .map((v) => v.name);
  }, [meishos]);

  // const ComboBoxExample = () => {
  //   const data = [
  //     "America",
  //     "India",
  //     "Australia",
  //     "Argentina",
  //     "Ireland",
  //     "Indonesia",
  //     "Iceland",
  //     "Japan",
  //   ];
  //   return <ComboBox options={data} enableAutocomplete />;
  // };

  return <ComboBox {...props} options={optionList} enableAutocomplete />;
});
