import React, { useEffect, useCallback, useState, memo } from "react";
import { useHistory } from "react-router";

export const ErrorModal = (props) => {
  const { isOpen, control, title, message } = props;

  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      control.off();
    },
    [control]
  );

  useEffect(() => {}, []);
  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="dialog dialog--error" style={{ display: "block" }}>
            <div className="dialog__title">{title}</div>
            <div className="dialog__body">
              <div
                className="txt"
                dangerouslySetInnerHTML={{
                  __html: message.replace(/\n/g, "<br/>"),
                }}
              />
            </div>
            <div className="btn-layout --center mt--20">
              <a href="#" className="btn --primary --h-l modalClose" onClick={onClose}>
                <span>OK</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const InfoModal = (props) => {
  const { isOpen, control, message, onOk } = props;

  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      control.off();
    },
    [control]
  );

  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="dialog dialog--delete" style={{ display: "block" }}>
            <div className="dialog__body">
              <div className="txt" dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br/>") }} />
            </div>
            <div className="btn-layout --center mt--20">
              <a
                href="#"
                className="btn --wht-primary --h-l modalClose"
                onClick={(e) => {
                  e.preventDefault();
                  control.off();
                }}
              >
                <span>いいえ</span>
              </a>
              <a href="#" className="btn --primary --h-l modalClose" onClick={onOk || onClose}>
                <span>はい</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const DeleteModal = (props) => {
  const { isOpen, control } = props;

  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      control.off();
    },
    [control]
  );

  useEffect(() => {}, []);
  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="dialog dialog--delete" style={{ display: "block" }}>
            <div className="dialog__body">
              <div className="txt">
                編集内容が保存されていません
                <br />
                破棄してよろしいですか？
              </div>
            </div>
            <div className="btn-layout --center mt--20">
              <a href="javascript:void(0);" className="btn --wht-primary --h-l modalClose">
                <span>いいえ</span>
              </a>
              <a href="#" className="btn --primary --h-l modalClose" onClick={onClose}>
                <span>はい</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const SaveModal = (props) => {
  const { isOpen, control } = props;
  const history = useHistory();

  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      control.off();
    },
    [control]
  );

  useEffect(() => {}, []);
  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="dialog dialog--save" style={{ display: "block" }}>
            <div className="dialog__body">
              <div className="txt">注文を保存しました</div>
            </div>
            <div className="btn-layout --center mt--20">
              <a
                href="#"
                className="btn --wht-primary --h-l modalClose"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                <span>依頼一覧へ</span>
              </a>
              <a
                href="#"
                className="btn --wht-primary --h-l modalClose"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/admin/haisha-list");
                }}
              >
                <span>配車一覧へ</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const SendModal = (props) => {
  const { isOpen, control, onOk } = props;

  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      control.off();
    },
    [control]
  );

  useEffect(() => {}, []);
  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="dialog dialog--send" style={{ display: "block" }}>
            <div className="dialog__body">
              <div className="txt">
                送信します
                <br />
                よろしいですか？
              </div>
            </div>
            <div className="btn-layout --center mt--20">
              <a href="#" className="btn --wht-primary --h-l closeDialog" onClick={onClose}>
                <span>いいえ</span>
              </a>
              <a href="#" className="btn --primary --h-l closeDialog" onClick={onOk || onClose}>
                <span>はい</span>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const PictureModal = memo((props) => {
  const { isOpen, control, item } = props;
  const [pictures, setPictures] = useState([]);
  const [selectUrl, setSelectUrl] = useState("");

  const onSelectPicture = useCallback((e, url) => {
    e.preventDefault();
    setSelectUrl(url);
  }, []);

  useEffect(() => {
    if (isOpen && item?.files?.length) {
      setPictures(item?.files || []);
      setSelectUrl(item?.files[0]?.url || "");
    }
  }, [isOpen, item]);

  return (
    <>
      {isOpen && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="modal modal--picture --w-l" style={{ display: "block" }}>
            <div className="modal__header">
              <div className="modal__title">登録済回収場所の写真</div>
              <div className="modal__close">
                <a
                  href="#"
                  className="modalClose"
                  onClick={(e) => {
                    e.preventDefault();
                    control.off();
                  }}
                >
                  <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286L16.1871 0Z"
                      fill="#888888"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="modal__body">
              <div className="picture">
                <div className="picture__selected">
                  <img src={selectUrl} alt="" />
                </div>
                <div className="picture__title">その他の登録写真</div>
                <div className="picture__others">
                  {pictures?.map(({ url }) => (
                    <div key={url} className={url === selectUrl ? "--selected" : ""}>
                      <a href="#" onClick={(e) => onSelectPicture(e, url)}>
                        <img src={url} alt="" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
