import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { useBoolean } from "../hooks/useBoolean";
import ErrorModal from "../modals/ErrorModal";
import InfoModal from "../modals/InfoModal";
import { createErrorMessage } from "../libs/common";

export default function PasswordRegister(props) {
  document.body.classList.value = "--scrollable pb--40";

  const weakLoginKey = sessionStorage.getItem("weakLoginKey");
  const history = useHistory();

  // 共通モーダル制御
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [weakMe, setWeakMe] = useState({});
  const { loginCode = "", kokyaku = {} } = weakMe;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isAgree, setAgree] = useState(false);

  const executable = useMemo(() => {
    if (!!!email || !!!password || !!!password2 || !!!isAgree) return false;
    return true;
  }, [email, password, password2, isAgree]);

  const onClickResetPassword = async (e) => {
    e.preventDefault();

    if (!executable) {
      return;
    }

    try {
      const res = await axios.post("/api/password-register", {
        weakLoginKey,
        email,
        password,
        password2,
      });
      if (res) {
        infoModalControl.on();
      }
    } catch (error) {
      console.warn(error.response);
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorModalControl.on();
    }
  };

  useEffect(() => {
    if (weakLoginKey) {
      axios.post("/api/weak-me", { weakLoginKey }).then((res) => {
        setWeakMe(res.data);
      });
    }
  }, [weakLoginKey]);

  return (
    <>
      <div className="pane__header">
        <div className="pane__header-title">パスワード登録</div>
      </div>
      <div className="inner">
        <div className="features">
          <div className="features__title">
            パスワード登録（無料）するだけで
            <br />
            簡単・便利にご利用いただけます
          </div>
          <div className="features__item">
            <div className="feature">
              <div className="feature__pic">
                <img src="/assets/Images/pic_feature-01.svg" alt="" />
              </div>
              <div className="feature__txt">
                <div>回収タイミングを自動でお知らせ</div>
                <div>
                  在庫状況や、過去の回収日から回収タイミングを自動でお知らせします。
                </div>
              </div>
            </div>
            <div className="feature">
              <div className="feature__pic">
                <img src="/assets/Images/pic_feature-02.svg" alt="" />
              </div>
              <div className="feature__txt">
                <div>依頼内容の編集やキャンセル</div>
                <div>
                  回収場所の登録や、日時の変更などスムーズに行なえます。
                </div>
              </div>
            </div>
            <div className="feature">
              <div className="feature__pic">
                <img src="/assets/Images/pic_feature-03.svg" alt="" />
              </div>
              <div className="feature__txt">
                <div>入出荷履歴をまとめて確認</div>
                <div>入出荷履歴の確認や、現在の在庫状況を確認できます。</div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-for-sp mt--24">
          <div className="txt --bold --fz-m">{kokyaku.tokuisakiName} 様</div>
          <div className="txt --fz-s mt--16">
            パソコン用ログインID：{loginCode}
          </div>
        </div>
        <div className="form mt--12">
          <div className="form-layout">
            <div className="form-layout__title">管理者様メールアドレス</div>
            <div className="form-layout__detail">
              <div className="form-detail-layout">
                <span className="form-detail-layout__value">
                  <input
                    type="text"
                    placeholder="例）irai@pallet-station.com(必須)"
                    id="jsText-ResistMail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="form-layout">
            <div className="form-layout__title">パスワード</div>
            <div className="form-layout__detail">
              <div className="form-detail-layout">
                <span className="form-detail-layout__value">
                  <input
                    type="password"
                    placeholder="英・数字6文字以上"
                    id="jsText-ResistPassword"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="form-layout">
            <div className="form-layout__title">パスワード(確認)</div>
            <div className="form-layout__detail">
              <div className="form-detail-layout">
                <span className="form-detail-layout__value">
                  <input
                    type="password"
                    placeholder="英・数字6文字以上"
                    id="jsText-ResistPasswordConfirm"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-for-sp">
          <div className="txt --blk-300 --fz-ss --note mt--12">
            <span className="txt --primary --bold">
              ※1社につき、1アカウントとなっています。
            </span>
            <br />
            貴社内で、複数のご担当者が当サイトをご利用されいている場合は、必ずパスワードを周知頂くようお願いいたします。
          </div>
          <div className="hdg  --h2">
            <div className="hdg__title">利用規約</div>
          </div>
        </div>

        <div className="term">
          <div>
            この利用規約（以下、「本規約」といいます。）は、三甲リース株式会社（以下、「当社」といいます。）がこのウェブサイト上で提供する「Pallet
            Station」（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
          </div>
          <div>
            <span className="txt --bold">第1条（適用）</span>
            <br />
            本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            <br />
            当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
            <br />
            本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
          </div>
          <div>
            <span className="txt --bold">第2条（利用登録）</span>
            <br />
            本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
            <br />
            当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
            <br />
            利用登録の申請に際して虚偽の事項を届け出た場合
            <br />
            本規約に違反したことがある者からの申請である場合
            <br />
            その他、当社が利用登録を相当でないと判断した場合
          </div>
          <div>
            <span className="txt --bold">
              第3条（ユーザーIDおよびパスワードの管理）
            </span>
            <br />
            ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
            <br />
            ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
            <br />
            ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
          </div>
          <div>
            <span className="txt --bold">第4条（禁止事項）</span>
            <br />
            ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
            <br />
            法令または公序良俗に違反する行為
            <br />
            犯罪行為に関連する行為
            <br />
            本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
            <br />
            当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
            <br />
            本サービスによって得られた情報を商業的に利用する行為
            <br />
            当社のサービスの運営を妨害するおそれのある行為
            <br />
            不正アクセスをし、またはこれを試みる行為
            <br />
            他のユーザーに関する個人情報等を収集または蓄積する行為
            <br />
            不正な目的を持って本サービスを利用する行為
            <br />
            本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
            <br />
            他のユーザーに成りすます行為
            <br />
            当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
            <br />
            その他、当社が不適切と判断する行為
          </div>
          <div>
            <span className="txt --bold">
              第5条（本サービスの提供の停止等）
            </span>
            <br />
            当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            <br />
            本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            <br />
            地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
            <br />
            コンピュータまたは通信回線等が事故により停止した場合
            <br />
            その他、当社が本サービスの提供が困難と判断した場合
            <br />
            当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </div>
          <div>
            <span className="txt --bold">第6条（利用制限および登録抹消）</span>
            <br />
            当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
            <br />
            本規約のいずれかの条項に違反した場合
            <br />
            登録事項に虚偽の事実があることが判明した場合
            <br />
            当社からの連絡に対し、一定期間返答がない場合
            <br />
            本サービスについて、最終の利用から一定期間利用がない場合
            <br />
            その他、当社が本サービスの利用を適当でないと判断した場合
            <br />
            当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </div>
          <div>
            <span className="txt --bold">
              第7条（保証の否認および免責事項）
            </span>
            <br />
            当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
            <br />
            当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
            <br />
            前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。
            <br />
            当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          </div>
          <div>
            <span className="txt --bold">第8条（サービス内容の変更等）</span>
            <br />
            当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
          </div>
          <div>
            <span className="txt --bold">第9条（利用規約の変更）</span>
            <br />
            当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
          </div>
          <div>
            <span className="txt --bold">第10条（通知または連絡）</span>
            <br />
            ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
          </div>
          <div>
            <span className="txt --bold">第11条（権利義務の譲渡の禁止）</span>
            <br />
            ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
          </div>
          <div>
            <span className="txt --bold">第12条（準拠法・裁判管轄）</span>
            <br />
            本規約の解釈にあたっては、日本法を準拠法とします。
            <br />
            本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
          </div>
          <div className="ta--r">以上</div>
        </div>

        <div className="mt--24">
          <label className="jc--c">
            <input
              type="checkbox"
              id="jsCheckbox-ResistPassword"
              checked={isAgree}
              onChange={() => setAgree((prev) => !prev)}
            />
            <span></span>利用規約に同意する
          </label>
        </div>
      </div>
      <div className="btn-layout --col-1 --stack">
        <div>
          <a
            href="#"
            onClick={onClickResetPassword}
            className={`btn --primary`}
            disabled={!!!executable}
            id="jsSubmit-ResistPassword"
          >
            <span>登録する</span>
          </a>
        </div>
        <div>
          <a
            href="#"
            className="btn --wht-primary --h-s"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <span>戻る</span>
          </a>
        </div>
      </div>

      {/* モーダル */}
      <InfoModal
        isOpen={isInfoModal}
        control={infoModalControl}
        title="パスワードを登録しました"
        message="以下よりログインしてください"
        onOk={(e) => {
          e.preventDefault();
          history.push("/login");
        }}
        btnOkTitle="今すぐログインする"
        disableClose
      />
      <ErrorModal
        isOpen={isErrorModal}
        control={errorModalControl}
        title="Error"
        message={errorMessage}
      />
    </>
  );
}
