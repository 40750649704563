import React, { memo } from "react";

export const CheckBox = memo((props) => {
  const { mainClass, selected, onChange, label, imagePath } = props;

  return (
    <div className={mainClass}>
      <label className={`checkbox ${selected && "--selected"}`}>
        <input type="checkbox" checked={selected} onChange={onChange} />
        <span></span>
        {imagePath && (
          <span className="checkbox__pic">
            <img src={imagePath} alt="" />
          </span>
        )}
        <span className="checkbox__txt">{label}</span>
      </label>
    </div>
  );
});
