import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import { DateTimePicker } from "../../components/DateTimePicker";
import { TimeTextComboBox } from "../../components/TimeTextComboBox";
import { formatDate } from "../../libs/date";
import { useKeyDown } from "../../hooks/useKeyDown";
import { TONYA_CHECK_KBN } from "../../libs/common";

export default function ({
  item,
  setViewTonyaLeftDown,
  setViewTonyaLeftUp,
  setViewkaishuIraiLeftUp,
  setSelectMarkerIraiItem,
}) {
  const { me } = useContext(AppContext);
  const {
    hikitoriInfo,
    setHikitoriInfo,
    setKeiroList,
    addKeiroListItem,
    isViewBinShosaiPane,
    isHikitoriModal,
    hikitoriModalControl,
  } = useContext(AdminAppContext);

  const location = useLocation();
  const isHaisha = location.pathname.match(/haisha-list/);
  const isHikitori = location.pathname.match(/hikitori-list/);
  const isPad = location.pathname.match(/ipad/);

  const [isViewShohins, setViewShohins] = useState(false);

  // form値
  const [suryo, setSuryo] = useState("");
  const [selectShohinList, setSelectShohinList] = useState([]);
  const [hikitoriDateForm, setHikitoriDateForm] = useState("");
  const [hikitoriTimeText, setHikitoriTimeText] = useState("");
  const [biko, setBiko] = useState("");

  const keyDownRegister = useKeyDown();

  const executable = useMemo(() => {
    const baseCheck = !!suryo && !!selectShohinList.length;
    if (isPad) {
      return baseCheck;
    }
    return baseCheck && !!hikitoriDateForm;
  }, [isPad, suryo, selectShohinList, hikitoriDateForm]);

  // 「送信する」
  const onClickSubmit = async (e) => {
    e.preventDefault();

    if (!executable) {
      return;
    }

    try {
      const hikitoriDate = formatDate(hikitoriDateForm, "YYYYMMDD");
      const haishaDate = hikitoriDate;
      const kokyakuId = hikitoriInfo?.id;
      const tonyaCheckKbn = TONYA_CHECK_KBN.CHECKTYU;
      const meisais = selectShohinList.map((e, i) => {
        return {
          shohinCode: e.shohinCode,
          shohinName: e.shohinName,
          suryo: i === 0 ? suryo : 0,
          tani: "枚",
        };
      });

      if (isPad) {
        // TODO 依頼パラメータ　回収先不明のコード付与
        const irai = {
          kokyakuId,
          name: hikitoriInfo.name,
          jusho: hikitoriInfo.jusho,
          lat: hikitoriInfo.latlng.lat,
          lng: hikitoriInfo.latlng.lng,
          // hikitoriDate,
          hikitoriTimeText,
          shanaiBiko: biko,
          newMeisais: meisais,
          newTmpPaths: (hikitoriInfo.files || []).map((e) => e.path),
        };
        await axios.post("/api/irai", { ...irai });
        window.location.reload();
        return;
      }

      // 配車一覧
      if (isHaisha) {
        const irai = {
          tonyaCheckKbn,
          hikitoriDate,
          hikitoriTimeText,
          shanaiBiko: biko,
          newMeisais: meisais,
        };
        const res = await axios.put("/api/irai/" + hikitoriInfo.id, { ...irai });
        setKeiroList((preList) => {
          const newList = [...preList].map((e) => {
            if (e.id === hikitoriInfo.id) {
              return {
                ...e,
                ...res.data,
                number: parseInt(suryo),
                pallet: selectShohinList.map((x) => x.shohinName).join("、"),
              };
            }
            return e;
          });
          return newList;
        });
        setViewkaishuIraiLeftUp(false);
        hikitoriModalControl.off();
        return;
      }

      // 引取（トランコム）
      if (isHikitori) {
        const irai = {
          tonyaCheckKbn,
          hikitoriDate,
          hikitoriTimeText,
          hikitoriBiko: biko,
          newMeisais: meisais,
        };
        if (!!hikitoriInfo.id && hikitoriInfo.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU) {
          await axios.put("/api/irai/" + hikitoriInfo.id, { ...irai });
          window.location.reload();
          return;
        }
        await axios.post("/api/irai", {
          ...irai,
          id: 0,
          // hikitoriUserId: me.id,
          kokyakuId,
          hikitoriUnsoCode: me.kokyakuId, // 運送会社指定
        });
        window.location.reload();
        return;
        // setViewTonyaLeftUp(false);
        // hikitoriModalControl.off();
      }

      // 依頼一覧
      // 回収店：経路に追加
      // 注文詳細Open：引取便作成
      if (hikitoriInfo.type === "tonya") {
        addKeiroListItem({
          ...hikitoriInfo,
          subType: "hikitori",
          number: parseInt(suryo),
          meisais,
          pallet: selectShohinList.map((x) => x.shohinName).join("、"),
          kokyakuId,
          tonyaCheckKbn,
          hikitoriDate,
          hikitoriTimeText,
          shanaiBiko: biko,
        });
        setViewTonyaLeftUp(false);
      } else if (hikitoriInfo.type === "kaishuirai" && hikitoriInfo.subType === "hikitori") {
        const irai = {
          tonyaCheckKbn,
          hikitoriDate,
          hikitoriTimeText,
          shanaiBiko: biko,
          newMeisais: meisais,
        };
        const res = await axios.put("/api/irai/" + hikitoriInfo.id, { ...irai });
        if (isViewBinShosaiPane) {
          setKeiroList((preList) => {
            const newList = [...preList].map((e) => {
              if (e.id === hikitoriInfo.id) {
                return {
                  ...e,
                  ...res.data,
                  number: parseInt(suryo),
                  pallet: selectShohinList.map((x) => x.shohinName).join("、"),
                };
              }
              return e;
            });
            return newList;
          });
          setViewkaishuIraiLeftUp(false);
        }
        setSelectMarkerIraiItem((pre) => ({
          ...pre,
          ...irai,
          meisais,
          number: parseInt(suryo),
          pallet: selectShohinList.map((x) => x.shohinName).join("、"),
        }));
      } else {
        const f = {
          haishaDate,
          irais: [
            {
              kokyakuId,
              tonyaCheckKbn,
              hikitoriDate,
              hikitoriTimeText,
              shanaiBiko: biko,
              meisais,
            },
          ],
        };
        await axios.post("/api/fbin", f);
        setViewTonyaLeftDown(false);
      }

      hikitoriModalControl.off();
    } catch (error) {
      console.warn(error);
    }
  };

  const displaySetting = useMemo(() => {
    if (isPad) {
      return { title: "作成", buttonLabel: "保存（送信）" };
    }
    if (isHikitori) {
      if ([TONYA_CHECK_KBN.OK, TONYA_CHECK_KBN.NG].includes(item?.tonyaCheckKbn)) {
        return { title: "確認", buttonLabel: "" };
      }
      return { title: "作成", buttonLabel: "保存（送信）" };
    }
    if (item?.type === "tonya") {
      if (isViewBinShosaiPane) {
        return { title: "作成", buttonLabel: "経路に追加" };
      }
      return { title: "作成", buttonLabel: "送信（引取便作成）" };
    }

    if (item?.type === "kaishuirai" && item?.subType === "hikitori") {
      return { title: "編集", buttonLabel: "保存（送信）" };
    }

    return {};
  }, [item, isPad, isHikitori, isViewBinShosaiPane]);

  useEffect(() => {
    if (!!item) {
      setHikitoriInfo(item);
    }
    if (!!isHikitoriModal && item?.subType === "hikitori") {
      setSuryo(item.number);
      setSelectShohinList(item.meisais || []);
      setHikitoriDateForm(item.hikitoriDate);
      setHikitoriTimeText(item.hikitoriTimeText);
      if (isHikitori) {
        setBiko(item.hikitoriBiko || "");
      } else {
        setBiko(item.shanaiBiko || "");
      }
    } else {
      setSuryo("");
      setSelectShohinList([]);
      setHikitoriDateForm("");
      setHikitoriTimeText("");
      setBiko("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, isHikitoriModal, setHikitoriInfo]);

  return (
    <>
      {isHikitoriModal && (
        <>
          <div className="overlay" style={{ display: "block" }}></div>
          <div className="modal modal--hikitoriirai" style={{ display: "block" }}>
            <div className="modal__header">
              <div className="modal__title">{`${isPad ? "" : "引取"}依頼を${displaySetting.title}`}</div>
              <div className="modal__close">
                <a
                  href="#"
                  className="modalClose"
                  onClick={(e) => {
                    e.preventDefault();
                    setHikitoriInfo(null);
                    hikitoriModalControl.off();
                  }}
                >
                  <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286L16.1871 0Z"
                      fill="#888888"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="modal__body">
              <div className="form --modal">
                <div className="form__item">
                  <div className="form__title">取引先</div>
                  {isPad ? (
                    <input
                      type="text"
                      className="w--340 mr--5"
                      defaultValue={hikitoriInfo?.name}
                      onChange={(e) => setHikitoriInfo((pre) => ({ ...pre, name: e.target.value }))}
                    />
                  ) : (
                    <div className="form__value">{hikitoriInfo?.name}</div>
                  )}
                </div>
                {isPad && (
                  <div className="form__item">
                    <div className="form__title">住所</div>
                    <input
                      type="text"
                      className="w--340 mr--5"
                      defaultValue={hikitoriInfo?.jusho}
                      onChange={(e) => setHikitoriInfo((pre) => ({ ...pre, jusho: e.target.value }))}
                    />
                  </div>
                )}
                <div className="form__item">
                  <div className="form__title">引取パレット数</div>
                  <div className="form__value">
                    <input
                      type="text"
                      className="w--140 mr--5"
                      value={suryo}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (/^\d*$/.test(val)) {
                          setSuryo(val);
                        } else {
                          setSuryo((prev) => prev);
                        }
                      }}
                      {...keyDownRegister(1)}
                    />
                    枚
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__title">パレットの種類</div>
                  <div className="form__value">
                    <input
                      type="text"
                      className="w--full --select"
                      id="jsPalletToggle"
                      readonly="readonly"
                      onFocus={() => setViewShohins(true)}
                      value={selectShohinList?.map((e) => e.shohinName).join(" ")}
                      {...keyDownRegister(2)}
                    />
                    <div className="form__pallet" style={{ display: isViewShohins ? "block" : "none" }}>
                      {hikitoriInfo?.shohins?.map((shohin) => (
                        <div key={shohin.id}>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="pallet"
                              value={shohin.shohinCode}
                              checked={selectShohinList?.some((e) => e.shohinCode === shohin.shohinCode)}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setSelectShohinList((prev) => {
                                  if (checked) {
                                    if (!prev?.some((v2) => v2.shohinCode === shohin.shohinCode)) {
                                      return [...prev, shohin];
                                    }
                                    return prev;
                                  } else {
                                    return prev.filter((v2) => v2.shohinCode !== shohin.shohinCode);
                                  }
                                });
                              }}
                            />
                            <span></span>
                            <span className="checkbox__pic">
                              <img src={shohin.url} alt="" />
                            </span>
                            <span className="checkbox__txt">{shohin.shohinName}</span>
                          </label>
                        </div>
                      ))}
                      <div>
                        <a
                          href="#"
                          className="btn --secondary --h-s w--110"
                          id="jsPalletSelect"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewShohins(false);
                          }}
                        >
                          <span>決定</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {!isPad && (
                  <div className="form__item">
                    <div className="form__title">引取日</div>
                    <div className="form__value">
                      <DateTimePicker
                        day={hikitoriDateForm}
                        setDay={setHikitoriDateForm}
                        tabIndex={3}
                        keyDownRegister={keyDownRegister}
                        pastPermission={false}
                      />
                    </div>
                  </div>
                )}
                <div className="form__item">
                  <div className="form__title">引取時間</div>
                  <div className="form__value">
                    <TimeTextComboBox
                      className="w--140"
                      defaultValue={hikitoriTimeText}
                      onChange={(e) => setHikitoriTimeText(e.target.value)}
                      onSelect={(e) => setHikitoriTimeText(e)}
                      {...keyDownRegister(4)}
                    />
                  </div>
                </div>
                <div className="form__item --border">
                  <div className="form__title">社内備考</div>
                  <div className="form__value">
                    <textarea name="" className="w--full" onChange={(e) => setBiko(e.target.value)} value={biko} />
                    <span className="form__note">※回収店には表示されません　※社内備考は上書きされます</span>
                  </div>
                </div>
                {item?.tonyaCheckKbn === TONYA_CHECK_KBN.NG && (
                  <div className="form__item --border">
                    <div className="form__title">不可の理由</div>
                    <div className="form__value">
                      <textarea name="" className="w--full" value={item?.answerHuka} readOnly />
                    </div>
                  </div>
                )}
                {hikitoriInfo?.files && (
                  <div className="form__item --border">
                    <div className="form__title">回収場所の写真</div>
                    <div className="form__value">
                      {hikitoriInfo?.files?.map((place) => (
                        <div key={place.url}>
                          <a href="javascript:void(0);" className="picture__pic">
                            <img src={place.url} alt="" />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="btn-layout --center mt--20">
                {![TONYA_CHECK_KBN.OK, TONYA_CHECK_KBN.NG].includes(item?.tonyaCheckKbn) && (
                  <>
                    <a
                      href="#"
                      className="btn --wht-primary w--160"
                      onClick={(e) => {
                        e.preventDefault();
                        setHikitoriInfo(null);
                        hikitoriModalControl.off();
                      }}
                    >
                      <span>キャンセル</span>
                    </a>
                    <a href="#" className="btn --primary w--160" disabled={!executable} onClick={onClickSubmit}>
                      <span>{displaySetting.buttonLabel}</span>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
