import React, { useContext, useEffect, useCallback, useState, memo, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import Header from "./Header";
import Map from "./Map";
import TonyaLeftUpPane from "../../panes/TonyaLeftUpPane";
import HikitoriModal from "../../modals/admin/HikitoriModal";
import { formatDate } from "../../libs/date";
import { TONYA_CHECK_KBN } from "../../libs/common";

export default function () {
  const { hikitoriModalControl } = useContext(AdminAppContext);
  const [mode, setMode] = useState("1");
  const [hikitoriList, setHikitoriList] = useState([]);

  const [isViewTonyaLeftUp, setViewTonyaLeftUp] = useState(false);
  const [selectMarkerTonyaItem, setSelectMarkerTonyaItem] = useState();
  const [selectHikitoriItem, setSelectHikitoriItem] = useState();
  const [isViewMoreButton1, setViewMoreButton1] = useState(true);
  const [isViewMoreButton2, setViewMoreButton2] = useState(true);
  const [isViewMoreButton3, setViewMoreButton3] = useState(true);

  const mapRef = useRef();

  // タブ毎のページ
  const page1 = useRef(1);
  const page2 = useRef(1);
  const page3 = useRef(1);

  // イベント
  const onMarkerClick = useCallback(async (item, detail) => {
    // 選択したマーカーデータ+詳細情報を取得
    try {
      if (item.type === "tonya") {
        setSelectMarkerTonyaItem(detail);
      }
      setViewTonyaLeftUp(item.type === "tonya");
    } catch (error) {
      console.warn(error);
    }
  }, []);

  // 引取依頼リスト
  const onLoadHikitoriList = useCallback(
    async (e, mode, page) => {
      if (!!e) {
        e.preventDefault();
      }
      try {
        const res = await axios.get("/api/hikitori-irai", {
          params: {
            page,
            tonyaCheckKbn: mode,
          },
        });
        if (res && res.data) {
          if (!res.data.length) {
            if (mode === "1") {
              setViewMoreButton1(false);
            } else if (mode === "2") {
              setViewMoreButton2(false);
            } else if (mode === "3") {
              setViewMoreButton3(false);
            }
          }
          setHikitoriList([...hikitoriList, ...res.data]);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [hikitoriList]
  );

  // タブ押下
  const onClickTab = useCallback(
    (e, mode) => {
      e.preventDefault();
      setMode(mode);
    },
    [setMode]
  );

  // もっと見る
  const onClickMore = useCallback(
    (e, mode) => {
      if (mode === "1") {
        page1.current++;
        onLoadHikitoriList(e, mode, page1.current);
      } else if (mode === "2") {
        page2.current++;
        onLoadHikitoriList(e, mode, page2.current);
      } else if (mode === "3") {
        page3.current++;
        onLoadHikitoriList(e, mode, page3.current);
      }
    },
    [onLoadHikitoriList]
  );

  const onDetailShow = useCallback(
    (e, kbn) => {
      setSelectMarkerTonyaItem(null);

      const item = {
        ...e,
        subType: "hikitori",
        number: e.meisais.reduce((t, c) => t + parseInt(c.suryo), 0),
        tonyaCheckKbn: kbn,
      };
      setSelectHikitoriItem(item);
      hikitoriModalControl.on();
    },
    [hikitoriModalControl]
  );

  const onDelete = useCallback(async (e, id) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await axios.delete("/api/irai/" + id);
      setHikitoriList((pre) => {
        const tmpList = [...pre];
        return tmpList.filter((e) => e.id !== id);
      });
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (mapRef.current?.isGoogleAvailable()) {
        mapRef.current?.reload();
        clearInterval(timerId);
      }
    }, 100);

    // 初期
    onLoadHikitoriList(null, null, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header eventF6={mapRef.current?.reload} />

      <Map ref={mapRef} markerList={[]} {...{ onMarkerClick }} />

      <div className="trancom">
        <div className="pane">
          <div className="pane__header">
            <div className="hdg">
              <div className="hdg__text">引取依頼の一覧</div>
            </div>
          </div>
          <div className="pane__body --bg-gray">
            <div className="pane-layout">
              <div className="pane-layout__inner">
                <div className="orders">
                  <div className="orders__text">MAPからピンを選択して、回収店に引取依頼を発行することができます</div>
                </div>
                <div className="tab --trancom">
                  <div className="tab__header">
                    <div className="tab__menu">
                      <a href="#" className={`${mode === "1" && "--current"}`} onClick={(e) => onClickTab(e, "1")}>
                        依頼確認中（{hikitoriList.filter((e) => e.tonyaCheckKbn === "1").length}）
                      </a>
                      <a href="#" className={`${mode === "2" && "--current"}`} onClick={(e) => onClickTab(e, "2")}>
                        引取OK（{hikitoriList.filter((e) => e.tonyaCheckKbn === "2").length}）
                      </a>
                      <a href="#" className={`${mode === "3" && "--current"}`} onClick={(e) => onClickTab(e, "3")}>
                        引取NG（{hikitoriList.filter((e) => e.tonyaCheckKbn === "3").length}）
                      </a>
                    </div>
                  </div>
                  <div className="tab__body">
                    <div className="tab__table" style={{ display: mode === "1" ? "table" : "none" }}>
                      <div className="tab__list">
                        {hikitoriList
                          .filter((e) => e.tonyaCheckKbn === "1")
                          .map((e) => {
                            return (
                              <span
                                className="paneOpenTrancom"
                                key={e.id}
                                onClick={() => onDetailShow(e, TONYA_CHECK_KBN.CHECKTYU)}
                              >
                                <span className="fw--bold">
                                  {formatDate(e.hikitoriDate, "M/D(ddd)")}
                                  <br />
                                  {e.hikitoriTimeText}
                                </span>
                                <span className="ta--r fw--bold">
                                  {e.meisais.reduce((t, cur) => t + cur.suryo, 0).toLocaleString()}枚
                                </span>
                                <span>{e.name}</span>
                              </span>
                            );
                          })}
                      </div>
                      {isViewMoreButton1 && (
                        <div className="tab__add">
                          <a href="#" onClick={(e) => onClickMore(e, "1")}>
                            過去の依頼をもっと見る
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="tab__table" style={{ display: mode === "2" ? "table" : "none" }}>
                      <div className="tab__list">
                        {hikitoriList
                          .filter((e) => e.tonyaCheckKbn === "2")
                          .map((e) => {
                            const isBin = e.binId !== 0;
                            return (
                              <span
                                className="paneOpenTrancom"
                                key={e.id}
                                onClick={() => onDetailShow(e, TONYA_CHECK_KBN.OK)}
                              >
                                <span className="fw--bold" style={{ width: "105px" }}>
                                  {formatDate(e.hikitoriDate, "M/D(ddd)")}
                                  <br />
                                  {e.hikitoriTimeText}
                                  {isBin && (
                                    <>
                                      <br />
                                      <span className="cl--depo fw--bold">【三甲配車中】</span>
                                    </>
                                  )}
                                </span>
                                <span className="ta--r fw--bold">
                                  {e.meisais.reduce((t, cur) => t + cur.suryo, 0).toLocaleString()}枚
                                </span>
                                <span>{e.name}</span>
                              </span>
                            );
                          })}
                      </div>
                      {isViewMoreButton2 && (
                        <div className="tab__add">
                          <a href="#" onClick={(e) => onClickMore(e, "2")}>
                            過去の依頼をもっと見る
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="tab__table" style={{ display: mode === "3" ? "table" : "none" }}>
                      <div className="tab__list">
                        {hikitoriList
                          .filter((e) => e.tonyaCheckKbn === "3")
                          .map((e) => {
                            return (
                              <span
                                className="paneOpenTrancom"
                                key={e.id}
                                onClick={() => onDetailShow(e, TONYA_CHECK_KBN.NG)}
                              >
                                <span className="fw--bold">
                                  {formatDate(e.hikitoriDate, "M/D(ddd)")}
                                  <br />
                                  {e.hikitoriTimeText}
                                </span>
                                <span className="ta--r fw--bold">
                                  {e.meisais.reduce((t, cur) => t + cur.suryo, 0).toLocaleString()}枚
                                </span>
                                <span>{e.name}</span>
                                <span className="ta--r">
                                  <a href="#" onClick={(event) => onDelete(event, e.id)}>
                                    <svg
                                      width="14"
                                      height="14"
                                      viewbox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.66671 1.66634C3.66671 1.31272 3.80718 0.973581 4.05723 0.723532C4.30728 0.473484 4.64642 0.333008 5.00004 0.333008H9.00004C9.35366 0.333008 9.6928 0.473484 9.94285 0.723532C10.1929 0.973581 10.3334 1.31272 10.3334 1.66634V2.99967H13C13.1769 2.99967 13.3464 3.06991 13.4714 3.19494C13.5965 3.31996 13.6667 3.48953 13.6667 3.66634C13.6667 3.84315 13.5965 4.01272 13.4714 4.13775C13.3464 4.26277 13.1769 4.33301 13 4.33301H12.2874L11.7094 12.4277C11.6854 12.7641 11.5349 13.0789 11.2881 13.3087C11.0413 13.5386 10.7166 13.6663 10.3794 13.6663H3.62004C3.2828 13.6663 2.95809 13.5386 2.7113 13.3087C2.46451 13.0789 2.31398 12.7641 2.29004 12.4277L1.71337 4.33301H1.00004C0.82323 4.33301 0.65366 4.26277 0.528636 4.13775C0.403612 4.01272 0.333374 3.84315 0.333374 3.66634C0.333374 3.48953 0.403612 3.31996 0.528636 3.19494C0.65366 3.06991 0.82323 2.99967 1.00004 2.99967H3.66671V1.66634ZM5.00004 2.99967H9.00004V1.66634H5.00004V2.99967ZM3.04937 4.33301L3.62071 12.333H10.38L10.9514 4.33301H3.04937ZM5.66671 5.66634C5.84352 5.66634 6.01309 5.73658 6.13811 5.8616C6.26314 5.98663 6.33337 6.1562 6.33337 6.33301V10.333C6.33337 10.5098 6.26314 10.6794 6.13811 10.8044C6.01309 10.9294 5.84352 10.9997 5.66671 10.9997C5.4899 10.9997 5.32033 10.9294 5.1953 10.8044C5.07028 10.6794 5.00004 10.5098 5.00004 10.333V6.33301C5.00004 6.1562 5.07028 5.98663 5.1953 5.8616C5.32033 5.73658 5.4899 5.66634 5.66671 5.66634ZM8.33337 5.66634C8.51019 5.66634 8.67975 5.73658 8.80478 5.8616C8.9298 5.98663 9.00004 6.1562 9.00004 6.33301V10.333C9.00004 10.5098 8.9298 10.6794 8.80478 10.8044C8.67975 10.9294 8.51019 10.9997 8.33337 10.9997C8.15656 10.9997 7.98699 10.9294 7.86197 10.8044C7.73695 10.6794 7.66671 10.5098 7.66671 10.333V6.33301C7.66671 6.1562 7.73695 5.98663 7.86197 5.8616C7.98699 5.73658 8.15656 5.66634 8.33337 5.66634Z"
                                        fill="#888888"
                                      />
                                    </svg>
                                  </a>
                                </span>
                              </span>
                            );
                          })}
                      </div>
                      {isViewMoreButton3 && (
                        <div className="tab__add">
                          <a href="#" onClick={(e) => onClickMore(e, "3")}>
                            過去の依頼をもっと見る
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TonyaLeftUpPane
        isHikitori={true}
        isOpen={isViewTonyaLeftUp}
        setOpen={setViewTonyaLeftUp}
        item={selectMarkerTonyaItem}
      />

      <HikitoriModal {...{ item: selectMarkerTonyaItem || selectHikitoriItem, setViewTonyaLeftUp }} />
    </>
  );
}
