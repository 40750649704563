import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdminAppContext } from "../AdminAppContext";
import { AppContext } from "../AppContext";
import { getMeishoName, getMeishos, getTeikikaishuLabel, KIBOTIME_KBN, TONYA_CHECK_KBN } from "../libs/common";
import { formatDate } from "../libs/date";

export default function ({ isIpad = false, isHikitori = false, isOpen = false, setOpen, item = {}, onAdd, onDelete }) {
  const location = useLocation();
  const { meishos } = useContext(AppContext);
  const { keiroList, hikitoriModalControl, isViewBinShosaiPane } = useContext(AdminAppContext);

  // 定期回収画面コントロール用
  const isTeiki = location.pathname.match(/teiki/);

  const youbiList = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);
  const timeKbnList = useMemo(() => getMeishos(meishos, "TONYA_TIME_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);

  const [tonyaPaneTab, setTonyaPaneTab] = useState(isHikitori ? 2 : 0);

  const displayKiboTime = useMemo(() => {
    if (item?.user?.kaishuTimeKbn === KIBOTIME_KBN.SONOTA) {
      return item.user.kaishuTimeBiko;
    }
    if (item?.user?.kaishuTimeFrom === "" && item?.user?.kaishuTimeTo === "") {
      return "希望なし";
    }
    return getMeishoName(timeKbnList, item?.user?.kaishuTimeKbn);
  }, [item, timeKbnList]);

  const isNotifyTonya = useMemo(() => {
    const d = item?.kojins?.some((e) => e.notifyHikitoriFlg === "1");
    return !!d;
  }, [item]);

  const isKeiroListExist = useMemo(() => {
    if (!item) return false;
    return keiroList?.some((e) => e.id === item.id);
  }, [item, keiroList]);

  if (!isOpen) {
    return null;
  }

  const mainClass = isIpad
    ? "pane pane--ipad pane--tonya-ipad"
    : isHikitori
    ? "pane pane--trancom pane--tonya-trancom"
    : "pane pane--left-up pane--tonya-left-up";

  return (
    <div className={`${mainClass} ${isOpen && "isOpen"}`}>
      <div className="pane__header">
        <div className="hdg">
          <div className="hdg__text --tonya">回収店</div>
          <div className="hdg__status"></div>
          <a
            href="javascript:void(0);"
            className="hdg__close paneClose mapClose"
            rel="tonya-left-up"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                fill="#888888"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="pane__body">
        <div className="pane-layout">
          <div className="pane-layout__inner">
            <div className="title --border">
              <span>
                <ul>
                  <li>{item?.id}</li>
                  <li>
                    <span className="title__title">{item?.name}</span>
                  </li>
                </ul>
              </span>
              <div className="title__zaiko">
                現在の在庫
                <span>{item?.zaikoSuryo?.toLocaleString() || "-"}</span>枚
              </div>
            </div>
            <div className="requests --normal">
              <div className="request">
                <div className="request__title">引取依頼最終送信日</div>
                <div className="request__value">{formatDate(item?.updateDate, "YYYY/M/D")}</div>
              </div>
              <div className="request" hidden>
                <div className="request__title">最終更新者</div>
                <div className="request__value">{item?.updateLid}</div>
              </div>
              <div className="request" hidden>
                <div className="request__title">社内備考</div>
                <div className="request__value">{item?.biko}</div>
              </div>
            </div>
            <div className="btn-layout --new">
              {!isTeiki && !!isNotifyTonya && !isKeiroListExist && (
                <>
                  {item?.irai && item?.irai.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU ? null : (
                    // <a href="javascript:void(0);" class="btn --secondary --w-l pr--0 jsShowHikitoriirai">
                    //   <span>引取依頼を変更</span>
                    //   <span class="btn__change">
                    //     <div class="btn__list">
                    //       <span>引取依頼を作成</span>
                    //       <span>引取依頼を変更</span>
                    //     </div>
                    //   </span>
                    // </a>
                    <a
                      href="#"
                      className="btn --secondary jsShowHikitoriirai"
                      onClick={(e) => {
                        e.preventDefault();
                        hikitoriModalControl.on();
                      }}
                    >
                      <span>引取依頼を作成</span>
                    </a>
                  )}
                </>
              )}
            </div>
            <div className="collection-place --border">
              <div className="collection-place__text">
                <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                  item?.teikis,
                  youbiList
                )} ]`}</div>
                <div className="ico --s --location">{item?.jusho}</div>
                <div className="ico --s --tel">{item?.tel}</div>
                <div className="ico --s --driver">{item?.tanto}</div>
              </div>
              <div className="collection-place__pic --noimage" hidden></div>
            </div>
            <div className="note" hidden>
              <div>
                <div>
                  <span>
                    回収可能曜日：
                    {getMeishoName(youbiList, item?.user?.kaishuYoubiKbns, "、")}
                  </span>
                  <span></span>
                </div>
                <div>
                  <span>回収可能時間：</span>
                  <span>{displayKiboTime}</span>
                </div>
              </div>
              <div>
                <div>
                  <span>保有限度枚数：</span>
                  <span>{item?.user?.hokanGendo?.toLocaleString() || "－"}枚</span>
                </div>
                <div hidden>
                  <span>荷役：</span>
                  <span>{getMeishoName(niyakuList, item?.user?.niyakuKbn)}</span>
                </div>
              </div>
              <div>
                <span>注意事項：</span>
                <span>{item?.user?.biko}</span>
              </div>
            </div>
            <div className="tab --tonya">
              <div className="tab__header">
                <div className="tab__menu">
                  {!isHikitori && (
                    <>
                      <a
                        href="javascript:void(0);"
                        className={tonyaPaneTab === 0 && "--current"}
                        onClick={(e) => {
                          e.preventDefault();
                          setTonyaPaneTab(0);
                        }}
                      >
                        出荷元
                      </a>
                      <a
                        href="javascript:void(0);"
                        className={tonyaPaneTab === 1 && "--current"}
                        onClick={(e) => {
                          e.preventDefault();
                          setTonyaPaneTab(1);
                        }}
                      >
                        最近の回収実績
                      </a>
                    </>
                  )}
                  <a
                    href="javascript:void(0);"
                    className={tonyaPaneTab === 2 && "--current"}
                    onClick={(e) => {
                      e.preventDefault();
                      setTonyaPaneTab(2);
                    }}
                  >
                    入出庫履歴
                  </a>
                </div>
              </div>
              <div className="tab__body">
                {!isHikitori && (
                  <>
                    <table className="tab__table" style={{ display: tonyaPaneTab === 0 ? "table" : "none" }}>
                      <colgroup>
                        <col style={{ width: "42%" }} />
                        <col style={{ width: "18%" }} />
                        <col style={{ width: "25%" }} />
                        <col className="" />
                      </colgroup>
                      <tr className="tab__th">
                        <th>会社名</th>
                        <th>商品</th>
                        <th>MC</th>
                        <th>PC</th>
                      </tr>
                      {item?.shukkamotos?.map((shukkamoto, index) => (
                        <tr key={index.toString()} className="tab__tr">
                          <td>{shukkamoto.tokuisakiName}</td>
                          <td>{shukkamoto.shohinName}</td>
                          <td className="ta--r">{shukkamoto.mc}</td>
                          <td className="ta--r">{shukkamoto.pc}</td>
                        </tr>
                      ))}
                    </table>
                    <table className="tab__table" style={{ display: tonyaPaneTab === 1 ? "table" : "none" }}>
                      <colgroup>
                        <col />
                        <col style={{ width: "47%" }} />
                        <col style={{ width: "18%" }} />
                        <col style={{ width: "18%" }} />
                      </colgroup>
                      <tr className="tab__th">
                        <th>回収日</th>
                        <th>運送会社</th>
                        <th>運賃単位</th>
                        <th>回収金額</th>
                      </tr>
                      {item?.kaishujissekis?.map((kaishujisseki, index) => (
                        <tr key={index.toString()} className="tab__tr">
                          <td>{formatDate(kaishujisseki.kaishuBi, "M/D(ddd)")}</td>
                          <td>{kaishujisseki.unsoName}</td>
                          <td>{kaishujisseki.jippiUnchinSuu.toLocaleString()}</td>
                          <td className="ta--r">{kaishujisseki.kaishuKingaku.toLocaleString()}円</td>
                        </tr>
                      ))}
                    </table>
                  </>
                )}
                <table className="tab__table" style={{ display: tonyaPaneTab === 2 ? "table" : "none" }}>
                  <colgroup>
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col className="" />
                  </colgroup>
                  <tr className="tab__th">
                    <th>日付</th>
                    <th>入庫</th>
                    <th>回収</th>
                    <th>在庫</th>
                  </tr>
                  {item?.nyushukkorirekis?.map((nyushukkorireki, index) => (
                    <tr key={index.toString()} className="tab__tr">
                      <td>{formatDate(nyushukkorireki.shoribi, "M/D(ddd)")}</td>
                      <td className="ta--r">{nyushukkorireki.nyukoSuu.toLocaleString()}</td>
                      <td className="ta--r">{nyushukkorireki.shukoSuu.toLocaleString()}</td>
                      <td className="ta--r">{nyushukkorireki.zaikoSuu.toLocaleString()}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pane__footer">
        {!isIpad && !isHikitori && isViewBinShosaiPane && (
          <>
            {isKeiroListExist ? (
              <div className="btn-layout --right">
                <a href="#" className="btn --delete" onClick={(e) => onDelete(e, item)}>
                  <span>経路から削除する</span>
                </a>
              </div>
            ) : (
              <>
                {isTeiki && (
                  <div className="btn-layout --center">
                    <a
                      href="#"
                      className="btn --primary paneOpen"
                      rel="shinkichumon"
                      // type定期回収に変更
                      onClick={(e) => onAdd(e, { ...item, type: "teikikaishu" })}
                    >
                      <span>注文に追加</span>
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
