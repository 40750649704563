import React, { useContext, memo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../AppContext";

const Header = (props) => {
  const { login } = props;
  const { me } = useContext(AppContext);

  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener(
    "resize",
    function () {
      setWidth(window.innerWidth);
    },
    true
  );

  return (
    <>
      <div className="header">
        <div className="header__logo">
          <Link to={width > 1023 ? "/dashboard" : "/irai"}>
            <img src="/assets/admin/Images/lgo-h.png" alt="SANKO PALLET STATION" />
          </Link>
        </div>
        <div className="header__inquiry">
          <Link to="/contact">
            <span>お問い合わせ</span>
          </Link>
        </div>
        {!!!login && me == null && (
          <div className="header__login">
            <Link to="/login">
              <span>ログイン</span>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Header);
