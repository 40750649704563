import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import { AppContext } from "../../AppContext";
import Header from "./Header";
const data = [
  {
    name: "6",
    2021: 400,
    2020: 240,
    amt: 240,
  },
  {
    name: "7",
    2021: 300,
    2020: 138,
    amt: 221,
  },
  {
    name: "8",
    2021: 200,
    2020: 280,
    amt: 220,
  },
  {
    name: "9",
    2021: 278,
    2020: 398,
    amt: 200,
  },
  {
    name: "10",
    2021: 189,
    2020: 480,
    amt: 218,
  },
  {
    name: "11",
    2021: 239,
    2020: 380,
    amt: 250,
  },
  {
    name: "12",
    2021: 349,
    2020: 430,
    amt: 210,
  },
  {
    name: "1",
    2021: 349,
    2020: 430,
    amt: 210,
  },
  {
    name: "2",
    2021: 349,
    2020: 430,
    amt: 210,
  },
  {
    name: "3",
    2021: 349,
    2020: 430,
    amt: 210,
  },
  {
    name: "4",
    2021: 349,
    2020: 430,
    amt: 210,
  },
  {
    name: "5",
    2021: 349,
    2020: 430,
    amt: 210,
  },
];

const BarGraphItem = memo(({ data }) => {
  return (
    <BarChart
      width={848}
      height={350}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" label={{ value: "(月)", offset: -1, position: "insideBottomRight" }} />
      <YAxis label={{ value: "(円)", offset: 10, position: "insideBottomLeft" }} />
      <Tooltip />
      <Legend verticalAlign="top" align="right" />
      <Bar dataKey="2020" barSize={16} fill="#C4C4C4" />
      <Bar dataKey="2021" barSize={16} fill="#F58322" />
    </BarChart>
  );
});

const OrgBarGraphItem = memo(({}) => {
  return (
    <>
      <div className="graph__legend">
        <span>71期</span>
        <span>2020</span>
        <span>2021</span>
      </div>
      <div className="graph__graph">
        <div className="graph__vertical">
          <span>200</span>
          <span>150</span>
          <span>100</span>
          <span>50</span>
          <span>0</span>
        </div>
        <div className="graph__bar">
          <span className="bar-6-l" style={{ height: "20%" }}></span>
          <span className="bar-6-c" style={{ height: "20%" }}></span>
          <span className="bar-7-l" style={{ height: "40%" }}></span>
          <span className="bar-7-c" style={{ height: "40%" }}></span>
          <span className="bar-8-l" style={{ height: "60%" }}></span>
          <span className="bar-8-c" style={{ height: "60%" }}></span>
          <span className="bar-9-l" style={{ height: "80%" }}></span>
          <span className="bar-9-c" style={{ height: "80%" }}></span>
          <span className="bar-10-l" style={{ height: "100%" }}></span>
          <span className="bar-10-c" style={{ height: "100%" }}></span>
          <span className="bar-11-l" style={{ height: "25%" }}></span>

          <span className="bar-12-l" style={{ height: "25%" }}></span>

          <span className="bar-1-l" style={{ height: "25%" }}></span>

          <span className="bar-2-l" style={{ height: "25%" }}></span>

          <span className="bar-3-l" style={{ height: "25%" }}></span>

          <span className="bar-4-l" style={{ height: "25%" }}></span>

          <span className="bar-5-l" style={{ height: "25%" }}></span>
        </div>
      </div>
      <div className="graph__holizontal">
        <span>(円)</span>
        <span>6</span>
        <span>7</span>
        <span>8</span>
        <span>9</span>
        <span>10</span>
        <span>11</span>
        <span>12</span>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
        <span>(月)</span>
      </div>
    </>
  );
});

export default function () {
  // table map 表示データ
  const [list, setList] = useState([]);

  const getData = async () => {
    // TODO データ処理
    // return new Promise((resolve) => {
    //   setTimeout(() => resolve("OK"), 500);
    // });
    try {
      const res = await axios.get("/api/data-analysis");
      if (res && res.data) {
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />

      <div className="main main--full">
        <div className="main-inner">
          <div className="hdg">
            <div className="hdg__text">データ分析</div>
          </div>
          <div className="data">
            <div className="data__graph">
              <div className="graph">
                <div className="graph__header">
                  <div className="graph__title">今月の運賃平均</div>
                  <div className="graph__average">
                    123.4<span>円</span>
                  </div>
                  <div className="graph__lastmonth">
                    <span>前月対比</span>
                    <span>－ 3.2 円/枚</span>
                  </div>
                  <div className="graph__lastyear">
                    <span>昨年対比</span>
                    <span>＋ 3.2 円/枚</span>
                  </div>
                </div>
                <div className="graph__body">
                  <div className="graph__legend">
                    <span>71期</span>
                  </div>
                  <div class="graph__graph">
                    <BarGraphItem data={data} />
                  </div>
                </div>
              </div>
            </div>
            <div className="data__ranking">
              <div className="ranking">
                <div className="ranking__title">担当者別 発注運賃ランキング</div>
                <div className="ranking__rank --rank-1">
                  <span>1</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank --rank-2">
                  <span>2</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank --rank-3">
                  <span>3</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>4</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>5</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>6</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>7</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>8</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>9</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
                <div className="ranking__rank">
                  <span>10</span>
                  <span>山田太郎</span>
                  <span>
                    110.4 <span>円/枚</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
