import axios from "axios";
import React, { useState, createContext, useCallback, useEffect, useLayoutEffect, useContext } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { useBoolean } from "./hooks/useBoolean";
import { TONYA_CHECK_KBN } from "./libs/common";
import { useLocation, useHistory } from "react-router";
import { AppContext } from "./AppContext";
import { formatDate } from "./libs/date";

export const AdminAppContext = createContext();

/**
 * 主に注文詳細と地図に関わる処理
 */
export const AdminAppContextProvider = (props) => {
  const { me } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const [isViewBinShosaiPane, setViewBinShosaiPane] = useState(false);
  const [isHikitoriModal, hikitoriModalControl] = useBoolean(false);
  const [hikitoriInfo, setHikitoriInfo] = useState({});
  // 便情報・総距離・（TODO 才数重量）
  const [haishaInfo, setHaishaInfo] = useState({});
  // 経路情報 irai(teikikaishu) + depo
  const [keiroList, setKeiroList] = useState([]);

  const addKeiroListItem = (item) => {
    if (!!item.hikitoriUnsoCode && !!keiroList.length) {
      const isHikitoriIrai = keiroList.some(
        (e) => !!e.hikitoriUnsoCode && item.hikitoriUnsoCode !== e.hikitoriUnsoCode
      );
      if (isHikitoriIrai) {
        throw new Error("運送会社指定の引取依頼が選択されている場合は、対象の引取依頼は経路に追加できない");
      }
    }

    let tmpKeiro = [];
    if (!keiroList) {
      tmpKeiro = [item];
    } else {
      let tmpLastDepo = [...keiroList]?.find((x) => x.type === "depo");
      tmpKeiro = [...keiroList]?.filter((x) => x.type !== "depo");
      tmpKeiro = [...tmpKeiro, item];
      if (item.type !== "depo" && !!tmpLastDepo) {
        tmpKeiro = [...tmpKeiro, tmpLastDepo];
      }
    }
    setKeiroList(tmpKeiro);
  };

  const onDownKeiroItem = useCallback(
    (e, index) => {
      e.preventDefault();
      const len = keiroList?.some((x) => x.type === "depo") ? 2 : 1;

      if (index >= keiroList.length - len) {
        return;
      }
      if (!keiroList || keiroList.length - len < 1) {
        return;
      }
      const newKeiro = [...keiroList];
      newKeiro.splice(index, 2, newKeiro[index + 1], newKeiro[index]);
      setKeiroList(newKeiro);
    },
    [keiroList, setKeiroList]
  );

  const onUpKeiroItem = useCallback(
    (e, index) => {
      e.preventDefault();
      if (index === 0) {
        return;
      }
      if (!keiroList || keiroList.length < 2) {
        return;
      }
      const newKeiro = [...keiroList];
      newKeiro.splice(index - 1, 2, newKeiro[index], newKeiro[index - 1]);
      setKeiroList(newKeiro);
    },
    [keiroList, setKeiroList]
  );

  // 回収依頼画面からの制御
  // 注文詳細非表示の場合、引取依頼作成
  // ここはcontextにはいらないと思う
  // const submitHikitoriInfo = useCallback(async (f) => {
  // try {
  //   const res = await axios.post("/api/fbin", f);
  //   if (res && res.data) {
  //     window.location.reload()
  //   }
  // } catch (error) {
  //   console.warn(error);
  // }

  // // 更新直後の値を取得
  // setHikitoriInfo(async (item) => {
  //   // 注文詳細表示の場合はkeiroList への追加のみ
  //   if (isViewBinShosaiPane) {
  //     addKeiroListItem(item?.irais[0]);
  //     return item;
  //   }

  //   // 注文詳細非表示の場合、引取依頼作成
  //   try {
  //     const res = await axios.post("/api/fbin", item);
  //     if (res && res.data) {
  //     }
  //   } catch (error) {
  //     console.warn(error);
  //   }

  //   return item;
  // });

  //   return;
  // }, []);

  // 配車データ新規登録・更新送信
  const sendHaishaData = async (payee) => {
    let res = null;
    const binId = haishaInfo?.id || 0;

    // keiroList から 依頼部分整理
    let iraiFulls = [...keiroList].map((e) => {
      return {
        ...e,
        id: e.id || 0,
        type: e.type,
        hikitoriDate: dayjs(e.hikitoriDate).format("YYYYMMDD"),
        hikitoriTimeText: e.hikitoriTimeText,
        nyukoDate: dayjs(e.nyukoDate).format("YYYYMMDD"),
        nyukoTimeText: e.nyukoTimeText,
      };
    });

    // 配車日
    const haishaDate = iraiFulls[0] && iraiFulls[0].hikitoriDate;

    // 最終依頼
    let lastIrai = iraiFulls.pop() || null;
    if (lastIrai != null && lastIrai?.type !== "depo") {
      // デポでない場合、削除した最終列を戻す
      iraiFulls.push(lastIrai);
    }

    let milnetSendKbn = "1";

    // 登録用データ整理
    let irais = [...iraiFulls].map((e) => {
      const id = e.id || 0;
      const hikitoriDate = e.hikitoriDate;
      const hikitoriTimeText = e.hikitoriTimeText;
      const shanaiBiko = e.shanaiBiko;

      // 新規
      if (e?.subType === "hikitori") {
        return {
          id: e.id || 0,
          hikitoriDate,
          hikitoriTimeText,
          shanaiBiko,
          tonyaCheckKbn: TONYA_CHECK_KBN.CHECKTYU,
          kokyakuId: e.kokyakuId || null,
          meisais: e.meisais || [],
        };
      }

      // 更新
      if (id !== 0) {
        return {
          id,
          hikitoriDate: e.hikitoriDate,
          hikitoriTimeText: e.hikitoriTimeText,
        };
      }

      // おかしなデータ
      return null;
    });

    irais = [...irais].filter((e) => e !== null);

    // 回答期限日
    let kaitokigen =
      formatDate(haishaInfo?.kaitokigenDate, "YYYY/MM/DD ") + formatDate(haishaInfo?.kaitokigenTime, "HH:mm:00");

    const f = {
      ...payee,
      haishaDate,
      id: haishaInfo?.id || 0,
      unsoCode: haishaInfo?.unsoCode,
      unsoName: haishaInfo?.unsoName,
      sekisaiRitu4t: haishaInfo?.sekisaiRitu4t,
      sekisaiRitu10t: haishaInfo?.sekisaiRitu10t,
      saisuJuryo: 0,
      kiboShashu: haishaInfo?.kiboShashu,
      kiboDaisu: haishaInfo?.kiboDaisu || "1",
      kiboSuryo: haishaInfo?.kiboSuryo,
      kiboTani: haishaInfo?.kiboTani,
      kiboTanka: haishaInfo?.kiboTanka,
      kaitokigenDate: kaitokigen,
      kaitokigenText: haishaInfo?.kaitokigenText,
      totalKyori: haishaInfo?.totalKyori || 0,
      milnetSendKbn,
      irais,
      zentaiBiko: haishaInfo?.zentaiBiko,
      shanaiBiko: haishaInfo?.shanaiBiko,
      unsoBiko: haishaInfo?.unsoBiko,
    };

    // 入庫先デポ
    if (lastIrai?.type === "depo") {
      f.nyukoDepoId = lastIrai?.id;
      f.nyukoDate = lastIrai?.nyukoDate;
      f.nyukoTimeText = lastIrai?.nyukoTimeText;
    }

    try {
      if (binId === 0) {
        res = await axios.post("/api/fbin", f);
      } else {
        res = await axios.put("/api/fbin/" + binId, f);
      }
      if (res && res.data) {
      }
    } catch (error) {
      console.warn(error);
    }

    // gridから消去用にiraisを返す
    return { res, irais };
  };

  const cancelBin = useCallback(async (id) => {
    await axios.post("/api/fbin/cancel", { id });
  }, []);

  const cancelIrai = useCallback(async (id) => {
    await axios.post("/api/irai/cancel-sanko", { id });
  }, []);

  const value = {
    hikitoriInfo,
    setHikitoriInfo,
    haishaInfo,
    setHaishaInfo,
    keiroList,
    setKeiroList,
    isViewBinShosaiPane,
    setViewBinShosaiPane,
    addKeiroListItem,
    onDownKeiroItem,
    onUpKeiroItem,
    // submitHikitoriInfo,
    sendHaishaData,
    isHikitoriModal,
    hikitoriModalControl,
    cancelBin,
    cancelIrai,
  };

  // routingしてもkeiroが残るから削除
  useEffect(() => {
    setHaishaInfo({});
    setKeiroList([]);
    setViewBinShosaiPane(false);
  }, [location.pathname]);

  useLayoutEffect(() => {
    const m = location.pathname.match(/hikitori/);
    if (!me) {
      if (m) {
        history.replace("/hikitori/hikitori-login");
        return;
      }
      history.replace("/admin/shain-login");
    } else if (me.admin !== "1" && !m) {
      history.replace("/admin/shain-login");
    }
  }, [me, location.pathname, history]);

  return <AdminAppContext.Provider value={value}>{props.children}</AdminAppContext.Provider>;
};
