import React from "react";
import { Route, Switch } from "react-router-dom";
import { AdminAppContext } from "./AdminAppContext";
import Home from "./views/admin/Home";
import IraiList from "./views/admin/IraiList";
import HaishaList from "./views/admin/HaishaList";
import TeikiList from "./views/admin/TeikiList";
import DataAnalysis from "./views/admin/DataAnalysis";
import ShainLogin from "./views/admin/ShainLogin";
import Ipad from "./views/admin/Ipad";
import NotFound from "./views/NotFound";
import Test2021 from "./views/admin/Test2021";

export const routes = [
  { path: "/", exact: true, component: Home },
  {
    path: "/irai-list",
    exact: false,
    component: IraiList,
    funcKeyCode: 112,
    funcName: "F1",
    title: "依頼一覧",
  },
  {
    path: "/haisha-list",
    exact: false,
    component: HaishaList,
    funcKeyCode: 113,
    funcName: "F2",
    title: "配車一覧",
  },
  {
    path: "/teiki-list",
    exact: false,
    component: TeikiList,
    funcKeyCode: 114,
    funcName: "F3",
    title: "定期回収マスター",
  },
  {
    path: "/data-analysis",
    exact: false,
    component: DataAnalysis,
    funcKeyCode: 115,
    funcName: "F4",
    title: "データ分析一覧",
  },
  { path: "/shain-login", exact: false, component: ShainLogin },
  { path: "/ipad", exact: false, component: Ipad },
  { path: "/test2021", exact: false, component: Test2021 },
  { path: "/*", exact: false, component: NotFound },
];

export default function App() {
  return (
    <Route
      path="/admin"
      render={({ match: { url } }) => (
        <Switch>
          {routes.map(({ path, exact, component }) => (
            <Route key={path} path={`${url}${path}`} exact={exact} component={component} />
          ))}
        </Switch>
      )}
    />
  );
}
