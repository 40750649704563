import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { ReactTabulator } from "react-tabulator";

import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import Header from "./Header";
import Map from "./Map";
import BinShosaiPane from "../../panes/BinShosaiPane";
import KaishuIraiLeftUpPane from "../../panes/KaishuIraiLeftUpPane";
import TonyaLeftUpPane from "../../panes/TonyaLeftUpPane";
import DepoLeftUpPane from "../../panes/DepoLeftUpPane";
import TeikiKaishuLeftUp from "../../panes/TeikiKaishuLeftUp";
import HikitoriModal from "../../modals/admin/HikitoriModal";
import { DeleteModal, PictureModal } from "../../modals/admin/Dialogs";
import ErrorModal from "../../modals/ErrorModal";
import { DateTimePicker } from "../../components/DateTimePicker";
import { formatDate, getDisplayKiboDate, getDisplayKiboTime } from "../../libs/date";
import { useBoolean } from "../../hooks/useBoolean";
import {
  getMeishos,
  formatNumber,
  TONYA_CHECK_KBN,
  getKohyoData,
  UNSO_CHECK_KBN,
  getMeishoName,
  createErrorMessage,
  actionLog,
} from "../../libs/common";

// 依頼明細部分
const iraiColumns = [
  { title: "!", field: "alert", frozen: true, formatter: "html", width: 120 },
  { title: "依頼日", field: "date", width: 100 },
  { title: "回収店名", field: "company", width: 150 },
  { title: "住所", field: "address", width: 150 },
  { title: "回収希望日", field: "request", width: 100 },
  { title: "希望時間", field: "time", width: 100 },
  {
    title: "枚数",
    field: "number",
    hozAlign: "right",
    formatter: (cell) => cell.getValue().toLocaleString(),
    width: 100,
  },
  { title: "パレットの種類", field: "pallet", width: 150 },
  { title: "注意事項", field: "biko", width: 150 },
  { title: "truck", field: "truck", frozen: true, formatter: "html" },
];

// 便部分
const BinItem = memo(({ e, setBinId, setViewBinShosaiPane }) => {
  const toBinStatusTag = useCallback((haishaInfo) => {
    const iraiList = haishaInfo.iraiList || [];
    const unsoCheckKbn = haishaInfo?.unsoCheckKbn || TONYA_CHECK_KBN.NONE;
    if (unsoCheckKbn === TONYA_CHECK_KBN.CHECKTYU) {
      return (
        <div className="status --iraichu">
          <span>依頼中</span>
        </div>
      );
    }
    if (unsoCheckKbn === TONYA_CHECK_KBN.NG) {
      return (
        <div className="status --attention">
          <span>依頼中 | 配送NG</span>
        </div>
      );
    }
    if (unsoCheckKbn === TONYA_CHECK_KBN.OK) {
      return (
        <div className="status --kakuteizumi">
          <span>確定済</span>
        </div>
      );
    }

    // 以下下書き（運送会社へは未送信)
    if (!!iraiList.filter((e) => e.tonyaCheckKbn === TONYA_CHECK_KBN.NG).length) {
      return (
        <div class="status --attention">
          <span>下書き | 回収店NG</span>
        </div>
      );
    }

    return (
      <div className="status --shitagaki">
        <span>下書き | 未送信</span>
      </div>
    );
  }, []);

  return (
    <div className="haisha">
      <div className="haisha__header" style={{ cursor: "default" }}>
        <div
          className={`summary --${
            ["1", "3"].includes(e.unsoCheckKbn) ? "iraichu" : "2" === e.unsoCheckKbn ? "kakuteizumi" : ""
          }`}
        >
          {process.env.NODE_ENV === "development" && <span>{e.id}</span>}
          <div className="summary__status">{toBinStatusTag(e)}</div>
          <div className="summary__date">{e.haishaDate}</div>
          <div className="summary__load">
            <span className={`ico --truck-4t-${e.color4} --s --bold --fz-s`}>{e.rate4}%</span>
            <span className={`ico --truck-10t-${e.color10} --s --bold --fz-s`}>{e.rate10}%</span>
          </div>
          <div className="summary__distance">
            距離：
            <span>
              {e?.totalKyori ? Math.floor(e?.totalKyori / 1000).toLocaleString() : "－"}
              km　{e.unsoName}
            </span>
          </div>
          <div className="summary__fare">
            {e.unsoCheckKbn === TONYA_CHECK_KBN.OK ? (
              <span className="t4">確定運賃：{e.kakuteiUnchin}円</span>
            ) : (
              <span className="t4">希望運賃：{e.kiboUnchin}円</span>
            )}
          </div>
          <div className="summary__detail">
            <a
              href="#"
              className="link --secondary --fz-ss --bold paneOpen"
              rel="chumonshosai"
              onClick={(ev) => {
                ev.preventDefault();
                setBinId(e.id);
                setViewBinShosaiPane(true);
              }}
            >
              詳細
            </a>
          </div>
        </div>
      </div>
      <div className="haisha__body">
        <div className="haisha__waypoint">
          <ReactTabulator
            className="waypoint waypoint2"
            data={e.iraiList}
            columns={iraiColumns}
            options={{
              movableColumns: true,
              headerVisible: false,
              columnResized: (column) => {
                // e.preventDefault();
              },
            }}
          />
        </div>
      </div>
    </div>
  );
});

// 画面全体
export default function () {
  const { meishos } = useContext(AppContext);
  const { isViewBinShosaiPane, setViewBinShosaiPane, addKeiroListItem, keiroList, setKeiroList } =
    useContext(AdminAppContext);
  const history = useHistory();
  const mapRef = useRef();
  const keiroListRef = useRef();

  // 共通モーダル制御
  const [isPictureModal, pictureModalControl] = useBoolean(false);
  const [isDeleteModal, deleteModalControl] = useBoolean(false);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");

  // pane view
  const [isViewKaishuIraiLeftUp, setViewkaishuIraiLeftUp] = useState(false);
  const [isViewTonyaLeftUp, setViewTonyaLeftUp] = useState(false);
  const [isViewDepoLeftUp, setViewDepoLeftUp] = useState(false);
  const [isViewTeikiKaishuLeftUp, setViewTeikiKaishuLeftUp] = useState(false);

  const isViewKohyoLeftUp = useMemo(() => {
    return isViewKaishuIraiLeftUp || isViewTonyaLeftUp || isViewDepoLeftUp || isViewTeikiKaishuLeftUp;
  }, [isViewKaishuIraiLeftUp, isViewTonyaLeftUp, isViewDepoLeftUp, isViewTeikiKaishuLeftUp]);

  // 選択中のマーカー明細
  const [selectMarkerIraiItem, setSelectMarkerIraiItem] = useState();
  const [selectMarkerTonyaItem, setSelectMarkerTonyaItem] = useState();
  const [selectMarkerDepoItem, setSelectMarkerDepoItem] = useState();
  const [selectMarkerTeikiItem, setSelectMarkerTeikiItem] = useState();

  // フィルター
  // all,sitagaki,iraityu,kakuteizumi,
  // yohikitorikakunin,henkoirai,mikaito
  const statusFilter = useRef("all");

  // search state
  const [serarchDay, setSearchDay] = useState();
  const [serarchText, setSearchText] = useState("");

  // table map 表示データ
  const [haishaList, setHaishaList] = useState([]);
  const [status, setStatus] = useState({});

  // 名称マスタ
  const ngShakakuList = useMemo(() => getMeishos(meishos, "SHAKAKU_KBN"), [meishos]);
  const kiboShashuList = useMemo(() => getMeishos(meishos, "TONYA_SHASHU_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);
  const sekisaiMaster = useMemo(() => {
    try {
      return {
        4: parseInt(getMeishos(meishos, "TOSAI_RITUSU_4T")[0].name),
        10: parseInt(getMeishos(meishos, "TOSAI_RITUSU_10T")[0].name),
      };
    } catch {
      return null;
    }
  }, [meishos]);

  const getSekisaiRitu = useCallback(
    (middleKeiro) => {
      if (!!sekisaiMaster) {
        const total = middleKeiro.reduce((t, c) => t + (c.number || 0), 0);
        const rate4 = Math.floor((total / sekisaiMaster["4"]) * 100);
        const rate10 = Math.floor((total / sekisaiMaster["10"]) * 100);
        return {
          rate4,
          color4: rate4 > 100 ? "red" : "green",
          rate10,
          color10: rate10 > 100 ? "red" : "green",
        };
      }
      return {};
    },
    [sekisaiMaster]
  );

  const [binId, setBinId] = useState(null);

  const toIraiStatusHtml = useCallback((irai) => {
    const tonyaCheckKbn = irai?.tonyaCheckKbn || "0";
    const unsoCheckKbn = irai?.unsoCheckKbn || "0";
    const idoStatus = irai?.ido?.idoStatus || "0";
    if (tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU) {
      return '<span class="ico --xs --exclamation txt --fz-s --bold">引取依頼確認中</span>';
    }
    if (tonyaCheckKbn === TONYA_CHECK_KBN.NG) {
      return '<span class="ico --xs --exclamation txt --fz-s --bold">回収店NG</span>';
    }
    if (idoStatus === "6") {
      return '<span class="ico --xs --exclamation txt --fz-s --bold">配送デポNG</span>';
    }
    if (unsoCheckKbn === UNSO_CHECK_KBN.NG) {
      return '<span class="ico --xs --exclamation txt --fz-s --bold">配送不可</span>';
    }
    return "";
  }, []);

  const toTrackStatusHtml = useCallback((sharyos, row) => {
    if (row !== 0) {
      return "";
    }
    if (!sharyos || sharyos.length <= 0) {
      return "";
    }
    const sharyoOk = (sharyos || []).every((e) => !!e.shaban && !!e.untenshu);
    if (sharyoOk) {
      const tips = sharyos.reduce((t, { shaban, untenshu, untenshuTel }, i) => {
        const comma = i !== 0 || i !== sharyos.length - 1 ? "、" : "";
        return t + comma + `<span>車番：${shaban}　ドライバー：${untenshu}　TEL：${untenshuTel}</span>`;
      }, "");
      return '<span class="ico --truck-green --s">&nbsp;<span class="tips">' + tips + "</span></span>";
    } else {
      return '<span class="ico --truck-red --s">&nbsp;<span class="tips">車番・ドライバー未設定</span></span>';
    }
  }, []);

  // イベント
  const onMarkerClick = useCallback(async (item, detail) => {
    // 選択したマーカーデータ+詳細情報を取得
    try {
      if (item.type === "kaishuirai") {
        setSelectMarkerIraiItem(detail);
      }
      if (item.type === "tonya") {
        setSelectMarkerTonyaItem(detail);
      }
      if (item.type === "depo") {
        setSelectMarkerDepoItem(detail);
      }
      if (item.type === "teikikaishu") {
        setSelectMarkerTeikiItem(detail);
      }

      // leftdownは使わずleftup paneで統一
      setViewkaishuIraiLeftUp(item.type === "kaishuirai");
      setViewTonyaLeftUp(item.type === "tonya");
      setViewDepoLeftUp(item.type === "depo");
      setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const onSearch = useCallback(
    async (e) => {
      if (!!e) {
        e.preventDefault();
      }
      try {
        const params = {};
        params.words = serarchText;
        if (!!serarchDay) {
          params.ymd = formatDate(serarchDay, "YYYY-MM-DD");
        }
        params.status = statusFilter.current;

        axios.get("/api/fbin/status-count", { params }).then((res) => {
          if (res && res.data) {
            setStatus(res.data);
          }
        });

        const res = await axios.get("/api/fbin", { params });
        if (res && res.data) {
          const displayList = res.data.map((v) => {
            let kakuteiUnchin = 0;
            v.iraiList = (v.irais || []).map((v2, i2) => {
              v2.alert = toIraiStatusHtml(v2) + '<span class="num --s --kaishuirai">' + (i2 + 1) + "</span>";
              v2.date = formatDate(v2.hikitoriDate, "M/D(ddd)", v2?.hikitoriTimeText);
              v2.company = v2.name;
              v2.address = v2.jusho;
              v2.request = getDisplayKiboDate(v2.kiboDateFrom, v2.kiboDateTo);
              v2.time = getDisplayKiboTime(v2.kiboTimeFrom, v2.kiboTimeTo, v2.kiboTimeBiko);
              v2.number = v2.meisais?.reduce((total, m) => total + parseInt(m.suryo || "0"), 0);
              v2.pallet = v2.meisais?.map((x) => x.shohinName).join("、");
              v2.truck = toTrackStatusHtml(v.sharyos, i2);

              v2.ngShakakus = getMeishoName(ngShakakuList, v.kiboNgShakakuKbns, "、");
              v2.kiboSharyo = getMeishoName(kiboShashuList, v.kiboSharyoKbn);
              v2.kiboNiyaku = getMeishoName(niyakuList, v.kiboNiyakuKbn);

              kakuteiUnchin += parseFloat(v2.unsoSuryo ?? 0) * parseFloat(v2.unsoTanka ?? 0);
              return v2;
            });

            // DEPO
            if (v.nyukoDepo) {
              v.iraiList.push({
                alert: '<span class="num --s --goal">&nbsp;</span>',
                date: formatDate(v.nyukoDate, "M/D(ddd)", v?.nyukoTimeText),
                company: v.nyukoDepo?.depoName,
                address: v.nyukoDepo?.depoAddr,
                request: "",
                time: "",
                number: "",
                pallet: "",
              });
            }

            v.sortDate = v.haishaDate;
            v.haishaDate = formatDate(v.haishaDate, "M/D(ddd)");
            v.kiboUnchin = formatNumber((v.kiboSuryo || 0) * (v.kiboTanka || 0));
            v.kakuteiUnchin = formatNumber(kakuteiUnchin);

            // 積載率取得
            const sekisairitu = getSekisaiRitu(v.iraiList);

            return { ...v, ...sekisairitu };
          });

          const res2 = await axios.get("/api/irai/status-count");
          if (res2 && res2.data) {
            // setIraiStatus(res2.data);
          }
          displayList.sort(function (a, b) {
            return a.sortDate - b.sortDate;
          });
          setHaishaList(displayList);
        }
        await actionLog("配車一覧 検索", "SUCCESS", null);
      } catch (error) {
        console.error(error);
        await actionLog("配車一覧 検索", "ERROR", error.toString());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serarchText, serarchDay, getSekisaiRitu, toIraiStatusHtml, toTrackStatusHtml]
  );

  const onClickStatusFilter = useCallback(
    (e, item) => {
      e.preventDefault();
      statusFilter.current = item;
      onSearch();
    },
    [onSearch]
  );

  const onAddKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();

      try {
        addKeiroListItem(item);

        setViewkaishuIraiLeftUp(false);
        setViewTonyaLeftUp(false);
        setViewDepoLeftUp(false);
        setViewTeikiKaishuLeftUp(false);
      } catch (error) {
        const message = createErrorMessage(error);
        setMessage(message);
        errorModalControl.on();
      }
    },
    [addKeiroListItem, errorModalControl]
  );

  const onDeleteKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();
      setKeiroList((prev) => prev.filter((x) => x.id !== item.id));

      setViewkaishuIraiLeftUp(false);
      setViewTonyaLeftUp(false);
      setViewDepoLeftUp(false);
      setViewTeikiKaishuLeftUp(false);
    },
    [setKeiroList]
  );

  const onClickDetail = useCallback(async (e, item) => {
    e.preventDefault();
    // mapデーではない場合明細が無いため取得
    const detail = await getKohyoData(item);
    const kohyoItem = { ...item, ...detail };
    if (item.type === "kaishuirai") {
      setSelectMarkerIraiItem(kohyoItem);
    } else if (item.type === "tonya") {
      setSelectMarkerTonyaItem(kohyoItem);
    } else if (item.type === "depo") {
      setSelectMarkerDepoItem(kohyoItem);
    } else if (item.type === "teikikaishu") {
      setSelectMarkerTeikiItem(kohyoItem);
    }
    setViewkaishuIraiLeftUp(item.type === "kaishuirai");
    setViewTonyaLeftUp(item.type === "tonya");
    setViewDepoLeftUp(item.type === "depo");
    setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
  }, []);

  const onOpenPictureModal = useCallback(
    (e) => {
      e.preventDefault();
      pictureModalControl.on();
    },
    [pictureModalControl]
  );

  // 経路変更
  useEffect(() => {
    if (keiroList.length && keiroList.length !== keiroListRef.current?.length) {
      mapRef.current?.changeCenter(keiroList[0]);
      mapRef.current?.reload();
      keiroListRef.current = keiroList;
    }
  }, [keiroList]);

  useEffect(() => {
    onSearch();

    const timerId = setInterval(() => {
      if (mapRef.current?.isGoogleAvailable()) {
        mapRef.current?.reload();
        clearInterval(timerId);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header eventF12={onSearch} eventF6={() => mapRef.current?.reload()} />
      <div className="main">
        <div className="main-inner">
          <div className="main-layout">
            <div className="main-layout__header">
              <div className="hdg">
                <div className="hdg__text">配車一覧</div>
              </div>
              <div className="search --center">
                <div className="search__condition">
                  <div className="condition">
                    <div className="condition__date">
                      <DateTimePicker hasArrow day={serarchDay} setDay={setSearchDay} />
                    </div>
                    <div className="condition__freeword ml--25">
                      <input
                        type="text"
                        className="w--225 ico --search --s --offset"
                        placeholder="回収店・商品・作成者名"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="condition__btn">
                      <a href="#" className="btn --secondary --h-s w--80" onClick={onSearch}>
                        <span>検索</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filters --border">
                <div className="filter">
                  <div className="filter__title">状態</div>
                  <div className="filter__value">
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "all")}
                      className={statusFilter.current === "all" && "--selected"}
                    >
                      {`すべて(${status.allNum?.toLocaleString() ?? "0"})`}
                    </a>
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "sitagaki")}
                      className={statusFilter.current === "sitagaki" && "--selected"}
                    >{`下書き(${status.sitagakiNum?.toLocaleString() ?? "0"})`}</a>
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "iraityu")}
                      className={statusFilter.current === "iraityu" && "--selected"}
                    >{`依頼中(${status.iraityuNum?.toLocaleString() ?? "0"})`}</a>
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "kakuteizumi")}
                      className={statusFilter.current === "kakuteizumi" && "--selected"}
                    >{`確定済み(${status.kakuteizumiNum?.toLocaleString() ?? "0"})`}</a>
                  </div>
                </div>
                <div className="filter">
                  <div className="filter__title ">
                    <svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.99998 14.6666C4.31798 14.6666 1.33331 11.6819 1.33331 7.99992C1.33331 4.31792 4.31798 1.33325 7.99998 1.33325C11.682 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.682 14.6666 7.99998 14.6666ZM7.99998 13.3333C9.41447 13.3333 10.771 12.7713 11.7712 11.7712C12.7714 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7714 5.22888 11.7712 4.22868C10.771 3.22849 9.41447 2.66659 7.99998 2.66659C6.58549 2.66659 5.22894 3.22849 4.22874 4.22868C3.22855 5.22888 2.66665 6.58543 2.66665 7.99992C2.66665 9.41441 3.22855 10.771 4.22874 11.7712C5.22894 12.7713 6.58549 13.3333 7.99998 13.3333ZM7.33331 9.99992H8.66665V11.3333H7.33331V9.99992ZM7.33331 4.66659H8.66665V8.66659H7.33331V4.66659Z"
                        fill="#DA4453"
                      />
                    </svg>
                  </div>
                  <div className="filter__value">
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "yohikitorikakunin")}
                      className={statusFilter.current === "yohikitorikakunin" && "--selected"}
                    >{`要依頼(${status.yohikitorikakuninNum?.toLocaleString() ?? "0"})`}</a>
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "henkoirai")}
                      className={statusFilter.current === "henkoirai" && "--selected"}
                    >{`変更依頼(${status.henkoiraiNum?.toLocaleString() ?? "0"})`}</a>
                    <a
                      href="#"
                      onClick={(e) => onClickStatusFilter(e, "mikaito")}
                      className={statusFilter.current === "mikaito" && "--selected"}
                    >{`未回答項目あり(${status.mikaitoNum?.toLocaleString() ?? "0"})`}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-layout__body">
              <div className="haishas">
                {haishaList.map((e) => (
                  <BinItem
                    key={e.id.toString()}
                    e={e}
                    setBinId={setBinId}
                    setViewBinShosaiPane={setViewBinShosaiPane}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Map
        ref={mapRef}
        markerList={[]}
        {...{
          onMarkerClick,
          isViewKohyoLeftUp,
        }}
      />
      <BinShosaiPane
        onMoveMapCenter={mapRef.current?.changeCenter}
        onMapReload={mapRef.current?.reload}
        binId={binId}
        setBinId={setBinId}
        onDetail={onClickDetail}
        setHaishaList={setHaishaList}
      />

      <KaishuIraiLeftUpPane
        isOpen={isViewKaishuIraiLeftUp}
        setOpen={setViewkaishuIraiLeftUp}
        item={selectMarkerIraiItem}
        setItem={setSelectMarkerIraiItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
        onOpenPictureModal={onOpenPictureModal}
      />
      <TonyaLeftUpPane
        isOpen={isViewTonyaLeftUp}
        setOpen={setViewTonyaLeftUp}
        item={selectMarkerTonyaItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <DepoLeftUpPane
        isOpen={isViewDepoLeftUp}
        setOpen={setViewDepoLeftUp}
        item={selectMarkerDepoItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <TeikiKaishuLeftUp
        isOpen={isViewTeikiKaishuLeftUp}
        setOpen={setViewTeikiKaishuLeftUp}
        item={selectMarkerTeikiItem}
      />
      {/* 引取 */}
      <HikitoriModal {...{ item: selectMarkerIraiItem, setViewkaishuIraiLeftUp }} />
      {/* dialog */}
      <PictureModal isOpen={isPictureModal} control={pictureModalControl} item={selectMarkerIraiItem} />
      <DeleteModal isOpen={isDeleteModal} control={deleteModalControl} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={message} />
    </>
  );
}
