import React, { useEffect, useState, useCallback, useContext, useMemo, memo, useRef } from "react";
import axios from "axios";

import { AppContext } from "../AppContext";
import { AdminAppContext } from "../AdminAppContext";
import { Select } from "../components/Select";
import { DateTimePicker } from "../components/DateTimePicker";
import { InfoModal } from "../modals/admin/Dialogs";
import ErrorModal from "../modals/ErrorModal";
import { useKeyDown } from "../hooks/useKeyDown";
import { useBoolean } from "../hooks/useBoolean";
import { createErrorMessage, getMeishos } from "../libs/common";
import { formatDate } from "../libs/date";

const daysJa = ["月", "火", "水", "木", "金", "土", "日"];
const daysEn = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export default function ({ onMoveMapCenter, onDetail, teikiId = null, setTeikiId }) {
  const { me, meishos, unsos = [], shohins } = useContext(AppContext);
  const { keiroList, setKeiroList, haishaInfo, setHaishaInfo, isViewBinShosaiPane, setViewBinShosaiPane } =
    useContext(AdminAppContext);

  const [message, setMessage] = useState(null);

  const refOkEvent = useRef();
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [infoMessage, setInfoMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const sekisaiMaster = useMemo(() => {
    try {
      return {
        4: parseInt(getMeishos(meishos, "TOSAI_RITUSU_4T")[0].name),
        10: parseInt(getMeishos(meishos, "TOSAI_RITUSU_10T")[0].name),
      };
    } catch {
      return null;
    }
  }, [meishos]);

  const keyDownRegister = useKeyDown();

  // 選択可能運送会社
  const unsoList = useMemo(() => {
    return (unsos || []).map((x, i) => ({
      code: x.kokyakuId,
      name: x.depoName,
      check: x.haishaFlg,
    }));
  }, [unsos]);

  // 選択可能商品
  const shohinList = useMemo(() => {
    return (shohins || []).map((x, i) => ({
      code: x.shohinCode,
      name: x.shohinNameS,
      check: "",
    }));
  }, [shohins]);

  const middleKeiro = useMemo(() => {
    return keiroList?.filter((x) => x.type !== "depo");
  }, [keiroList]);

  const lastDepo = useMemo(() => {
    return keiroList?.find((x) => x.type === "depo");
  }, [keiroList]);

  const sekisaiRitu = useMemo(() => {
    if (!!middleKeiro && middleKeiro.length > 0 && !!sekisaiMaster) {
      const total = middleKeiro.reduce((t, c) => t + (c.number || 0), 0);
      const rate4 = Math.floor((total / sekisaiMaster["4"]) * 100);
      const rate10 = Math.floor((total / sekisaiMaster["10"]) * 100);
      return {
        4: rate4,
        color4: rate4 > 100 ? "red" : "green",
        10: rate10,
        color10: rate10 > 100 ? "red" : "green",
      };
    }
    return {};
  }, [middleKeiro, sekisaiMaster]);

  const onClickClose = useCallback(
    (e) => {
      if (!!e) {
        e.preventDefault();
      }
      // teikiIdクリアで閉じる
      setTeikiId(null);
      setHaishaInfo(null);
      setKeiroList([]);
    },
    [setTeikiId, setHaishaInfo, setKeiroList]
  );

  const onClickSwap = useCallback(
    (e, no, no2) => {
      e.preventDefault();
      if (no >= keiroList.length || no2 >= keiroList.length) {
        return;
      }
      if (no < 0 || no2 < 0) {
        return;
      }
      let tmpList = [...keiroList];
      tmpList[no] = keiroList[no2];
      tmpList[no2] = keiroList[no];
      tmpList = tmpList.map((v, i) => {
        v.meisaiNo = i + 1;
        return v;
      });
      setKeiroList(tmpList);
    },
    [keiroList, setKeiroList]
  );

  const executable = useMemo(() => {
    if ((middleKeiro || []).length === 0) return false;
    if (!lastDepo) return false;
    if (!haishaInfo) return false;

    const shohinCode = middleKeiro.every((e) => !!e.shohinCode);
    const suryo = middleKeiro.every((e) => !!e.suryo);
    if (!shohinCode || !suryo) {
      return false;
    }
    return true;
  }, [middleKeiro, lastDepo, haishaInfo]);

  const onClickSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!executable) {
        return false;
      }
      try {
        refOkEvent.current = async (e) => {
          e.preventDefault();

          const id = teikiId || 0;
          const tekiyoDate = haishaInfo?.tekiyoDate ? formatDate(haishaInfo?.tekiyoDate, "YYYYMMDD") : null;
          const kokyakuCode = middleKeiro[0].kokyakuCode || middleKeiro[0].id;
          const kokyakuName = middleKeiro[0].kokyakuName || middleKeiro[0].name;
          const nyukoDay = haishaInfo.nyukoDay || 0;
          const tehaiKbn = haishaInfo.tehaiKbn || "0";
          const week1 = haishaInfo.week1 || 0;
          const week2 = haishaInfo.week2 || 0;
          const week3 = haishaInfo.week3 || 0;
          const week4 = haishaInfo.week4 || 0;
          const week5 = haishaInfo.week5 || 0;
          const sunday = haishaInfo.sunday || 0;
          const monday = haishaInfo.monday || 0;
          const tuesday = haishaInfo.tuesday || 0;
          const wednesday = haishaInfo.wednesday || 0;
          const thursday = haishaInfo.thursday || 0;
          const friday = haishaInfo.friday || 0;
          const saturday = haishaInfo.saturday || 0;
          const fax = haishaInfo.fax || "0";
          const certificate = haishaInfo.certificate || "0";
          const holidayKbn = haishaInfo.holidayKbn || "0";

          // 登録は複数列
          const tmpList = middleKeiro.map((v) => {
            return {
              ...haishaInfo,
              kokyakuCode,
              kokyakuName,
              tekiyoDate,
              suryo: v.suryo,
              shohinCode: v.shohinCode,
              shohinName: v.shohinName,
              shukoYoteiTime: v.shukoYoteiTime,
              depoCode: lastDepo.kokyakuId || lastDepo.id,
              depoName: lastDepo.name,
              nyukoDay,
              tehaiKbn,
              week1,
              week2,
              week3,
              week4,
              week5,
              sunday,
              monday,
              tuesday,
              wednesday,
              thursday,
              friday,
              saturday,
              fax,
              certificate,
              holidayKbn,
            };
          });

          let res = undefined;
          if (id === 0) {
            res = await axios.post("/api/teiki", tmpList);
          } else {
            res = await axios.put("/api/teiki/" + id, tmpList);
          }
          window.location.reload();
        };
        setInfoMessage("登録します。\nよろしいですか？");
        infoModalControl.on();
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.onf();
      }
    },
    [teikiId, haishaInfo, middleKeiro, lastDepo, executable, infoModalControl, errorModalControl]
  );

  const onClickDelete = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (!teikiId) {
          return;
        }
        refOkEvent.current = async (e) => {
          e.preventDefault();
          await axios.delete("/api/teiki/" + teikiId);
          window.location.reload();
        };
        setInfoMessage("削除します。\nよろしいですか？");
        infoModalControl.on();
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [teikiId, infoModalControl, errorModalControl]
  );

  const setMeisai = useCallback(
    (index, payload) => {
      let tmpList = keiroList.map((v, i) => {
        if (i !== index) {
          return v;
        }
        for (const prop in payload) {
          v[prop] = payload[prop];
        }
        return v;
      });
      setKeiroList(tmpList);
    },
    [keiroList, setKeiroList]
  );

  useEffect(() => {
    if (teikiId === null) {
      setViewBinShosaiPane(false);
      return;
    }
    (async () => {
      if (teikiId !== 0) {
        try {
          let res = await axios.get("/api/teiki", { params: { id: teikiId } });
          if (res && res.data) {
            const { nyukoDepo } = res.data[0];
            setHaishaInfo(res.data[0]);

            const tmpList = res.data.map((e) => ({ ...e, type: "teikikaishu", name: e.kokyakuName, number: e.suryo }));
            tmpList.push({
              ...nyukoDepo,
              type: "depo",
              name: nyukoDepo?.depoName,
              jusho: nyukoDepo?.depoAddr,
            });

            console.info({ tmpList });
            setKeiroList(tmpList);
          }
        } catch (ex) {
          console.error(ex);
          setViewBinShosaiPane(false);
        }
      } else {
        setKeiroList([]);
      }
      setViewBinShosaiPane(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teikiId, setHaishaInfo, setViewBinShosaiPane]);

  if (!isViewBinShosaiPane) {
    return null;
  }

  return (
    <>
      <div className={`pane pane--full pane--chumonshosai isOpen`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text">注文詳細</div>
            <div className="hdg__status">
              <div className="status --teikikaishu">
                <span>定期回収</span>
              </div>
            </div>
            <a href="#" className="hdg__close paneClose" rel="chumonshosai" onClick={onClickClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body --bg-gray">
          <div className="pane-layout --col2">
            <div>
              <div className="pane-layout__inner">
                <div className="title --border">
                  <div className="title__title --bold">経路</div>
                  <div className="title__load">
                    合計
                    <span className={`ico --truck-4t-${sekisaiRitu["color4"]} --l --fz-m --bold`}>
                      {sekisaiRitu["4"]}%
                    </span>
                    <span className={`ico --truck-10t-${sekisaiRitu["color10"]} --l --fz-m --bold`}>
                      {sekisaiRitu["10"]}%
                    </span>
                  </div>
                  <div className="title__distance">
                    距離：
                    <span>
                      {haishaInfo?.totalKyori ? Math.floor(haishaInfo.totalKyori / 1000).toLocaleString() : "－"}
                      km
                    </span>
                  </div>
                </div>
                <div className="route">
                  {middleKeiro.map((v, i) => {
                    return (
                      <>
                        <div className="route-header">
                          <div className="route-header__num">
                            <span className="num --teikikaishu">{i + 1}</span>
                          </div>
                          <div className="route-header__company">
                            <div>{v.name}</div>
                            <div>
                              {v.kokyakuCode || v.id} {v.jusho}
                            </div>
                          </div>
                          <div className="route-header__down">
                            <a onClick={(e) => onClickSwap(e, i, i + 1)} href="#">
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.293 9L16.3955 7.10258L10.6348 12.8633V0H7.95109V12.8633L2.19039 7.10258L0.292969 9L9.29297 18L18.293 9Z"
                                  fill="#279E9E"
                                ></path>
                              </svg>
                            </a>
                          </div>
                          <div className="route-header__up">
                            <a onClick={(e) => onClickSwap(e, i, i - 1)} href="#">
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.29297 9L2.19039 10.8974L7.95109 5.13672L7.95109 18L10.6349 18L10.6349 5.13672L16.3955 10.8974L18.293 9L9.29297 1.12054e-06L0.29297 9Z"
                                  fill="#279E9E"
                                ></path>
                              </svg>
                            </a>
                          </div>
                          <div className="route-header__detail">
                            <a
                              href="javascript:void(0);"
                              className="link --secondary --fz-14 --bold paneOpen"
                              rel="teikikaishu-left-up"
                              onClick={(e) => onDetail(e, v)}
                            >
                              詳細
                            </a>
                          </div>
                        </div>
                        <div className="route-body">
                          <div className="route-body__item">
                            <div className="route-body__title">パレット数</div>
                            <div className="route-body__value">
                              <input
                                type="text"
                                className="w--140"
                                value={v.suryo}
                                onChange={(e) => {
                                  setMeisai(i, {
                                    suryo: e.target.value,
                                  });
                                }}
                              />
                              枚
                            </div>
                          </div>
                          <div className="route-body__item">
                            <div className="route-body__title">パレットの種類</div>
                            <div className="route-body__value">
                              <Select
                                options={shohinList}
                                placeholder="商品選択"
                                tabIndex={(i + 1) * 10}
                                keyDownRegister={keyDownRegister}
                                selectValue={v.shohinName}
                                setSelectValue={(data) => {
                                  setMeisai(i, {
                                    shohinCode: data.suggestion.code,
                                    shohinName: data.suggestion.name,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="route-body__item">
                            <div className="route-body__title">回収予定時間</div>
                            <div className="route-body__value">
                              <input
                                type="text"
                                className="w--140"
                                value={v.shukoYoteiTime}
                                onChange={(e) => {
                                  setMeisai(i, {
                                    shukoYoteiTime: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <a
                    href="#"
                    className="route-header --add mapOpen"
                    onClick={(e) => {
                      e.preventDefault();
                      onMoveMapCenter();
                    }}
                  >
                    <div className="route-header__add">
                      <span className="link --secondary --fx-24 --bold">回収先を追加する</span>
                    </div>
                  </a>
                  <div className="route-body"></div>

                  {!!lastDepo ? (
                    <div className="route-header">
                      <div className="route-header__num">
                        <span className="num --goal">&nbsp;</span>
                      </div>
                      <div className="route-header__company">
                        <div>{lastDepo?.name}</div>
                        <div>
                          {lastDepo?.kokyakuId || lastDepo?.id} {lastDepo?.jusho}
                        </div>
                        <div>
                          <span>保有枚数</span>
                          <span>
                            <span
                              style={{
                                width: lastDepo?.hoyuritu >= 100 ? 100 : (lastDepo?.hoyuritu || 0) + "%",
                              }}
                            ></span>
                          </span>
                          <span>{lastDepo?.zaikoSuryo?.toLocaleString() || "－"}枚</span>
                        </div>
                      </div>
                      <div className="route-header__detail">
                        <a
                          href="javascript:void(0);"
                          className="link --secondary --fz-14 --bold paneOpen"
                          rel="depo-left-up"
                          onClick={(e) => onDetail(e, lastDepo)}
                        >
                          詳細
                        </a>
                      </div>
                    </div>
                  ) : (
                    <a
                      href="javascript:void(0);"
                      className="route-header --add-wht paneOpen paneLeftDownClose"
                      rel="depo-left"
                      onClick={(e) => {
                        e.preventDefault();
                        onMoveMapCenter();
                      }}
                    >
                      <div className="route-header__num">
                        <span className="num --goal">&nbsp;</span>
                      </div>
                      <div className="route-header__add">
                        <span className="link --secondary --fx-24 --bold">デポを指定する</span>
                      </div>
                    </a>
                  )}
                </div>

                {!!lastDepo && (
                  <div className="warehousing --center">
                    <div className="warehousing__title">入庫日</div>
                    <div className="warehousing__date">
                      <select
                        className="w--100"
                        defaultValue={haishaInfo?.nyukoDay}
                        onChange={(e) => {
                          const nyukoDay = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, nyukoDay }));
                        }}
                      >
                        <option value="0">当日</option>
                        <option value="1">翌日</option>
                        <option value="2">翌々日</option>
                      </select>
                    </div>
                    <div className="warehousing__title">手配区分</div>
                    <div className="warehousing__date">
                      <select
                        className="w--120"
                        defaultValue={haishaInfo?.tehaiKbn}
                        onChange={(e) => {
                          const tehaiKbn = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, tehaiKbn }));
                        }}
                      >
                        <option value="0">デポ手配</option>
                        <option value="1">リース手配</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="pane-layout__inner">
                <div className="title">
                  <div className="title__title --bold">配車</div>
                  <div className="title__author">
                    作成者<span>{me?.shainName}</span>
                  </div>
                </div>
                <div className="form">
                  <div className="form__item">
                    <div className="form__title">回収日</div>
                    <div className="form__value">
                      <div className="form__collectionday">
                        <div>
                          <div>間隔</div>
                          <div>
                            <div>
                              {[1, 2, 3, 4, 5].map((key) => (
                                <label key={key}>
                                  <input
                                    type="checkbox"
                                    checked={haishaInfo && !!haishaInfo["week" + key]}
                                    onChange={(e) => {
                                      const val = e.target.checked ? 1 : 0;
                                      setHaishaInfo((prev) => ({ ...prev, ["week" + key]: val }));
                                    }}
                                  />
                                  <span></span>
                                  {`第${key}週`}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>曜日</div>
                          <div>
                            <div>
                              {daysJa.map((key, index) => (
                                <label key={key}>
                                  <input
                                    type="checkbox"
                                    checked={haishaInfo && !!haishaInfo[daysEn[index]]}
                                    onChange={(e) => {
                                      const val = e.target.checked ? 1 : 0;
                                      setHaishaInfo((prev) => ({ ...prev, [daysEn[index]]: val }));
                                    }}
                                  />
                                  <span></span>
                                  {key}
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">適用開始日</div>
                    <div className="form__value">
                      <DateTimePicker
                        className="dsp--inline-block"
                        day={haishaInfo?.tekiyoDate}
                        setDay={(tekiyoDate) => setHaishaInfo((prev) => ({ ...prev, tekiyoDate }))}
                        tabIndex={10}
                        keyDownRegister={keyDownRegister}
                      />
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">依頼先運送会社</div>
                    <div className="form__value">
                      <Select
                        options={unsoList}
                        placeholder="運送会社を選択してください"
                        keyDownRegister={keyDownRegister}
                        tabIndex={1}
                        selectValue={haishaInfo?.unsoName}
                        setSelectValue={(data) => {
                          setHaishaInfo((prev) => ({
                            ...prev,
                            unsoCode: data.suggestion.code,
                            unsoName: data.suggestion.name,
                          }));
                        }}
                        width={600}
                      />
                    </div>
                  </div>
                  <div className="form__item">
                    {/* <div className="form__title">車種希望</div>
                  <div className="form__value">
                    <ShashuComboBox
                      placeholder="希望車種を選択してください"
                      keyDownRegister={keyDownRegister}
                      tabIndex={2}
                      defaultValue={haishaInfo?.kiboShashu}
                      onChange={(e) => {
                        const kiboShashu = e.target.value;
                        setHaishaInfo((prev) => ({
                          ...prev,
                          kiboShashu,
                        }));
                      }}
                    />
                  </div> */}
                  </div>
                  <div className="form__item">
                    <div className="form__title">請求運賃</div>
                    <div className="form__value">
                      <input
                        type="text"
                        className="w--55 mr--5"
                        value={haishaInfo?.seikyuUnchinSuu || ""}
                        onChange={(e) => {
                          const seikyuUnchinSuu = e.target.value;
                          if (seikyuUnchinSuu === "" || /^\d{1,}$/.test(seikyuUnchinSuu)) {
                            setHaishaInfo((prev) => ({ ...prev, seikyuUnchinSuu }));
                          }
                        }}
                        {...keyDownRegister(3)}
                      />
                      {"× "}
                      <input
                        type="text"
                        className="w--55 mr--5"
                        value={haishaInfo?.seikyuUnchinTanka || ""}
                        onChange={(e) => {
                          const seikyuUnchinTanka = e.target.value;
                          if (seikyuUnchinTanka === "" || /^\d{1,}$/.test(seikyuUnchinTanka)) {
                            setHaishaInfo((prev) => ({ ...prev, seikyuUnchinTanka }));
                          }
                        }}
                        {...keyDownRegister(4)}
                      />
                      円&nbsp;=&nbsp;
                      {(
                        parseFloat(haishaInfo?.seikyuUnchinSuu || "0") *
                        parseFloat(haishaInfo?.seikyuUnchinTanka || "0")
                      ).toLocaleString()}
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">実費運賃</div>
                    <div className="form__value">
                      <input
                        type="text"
                        className="w--55 mr--5"
                        value={haishaInfo?.jippiUnchinSuu || ""}
                        onChange={(e) => {
                          const jippiUnchinSuu = e.target.value;
                          if (jippiUnchinSuu === "" || /^\d{1,}$/.test(jippiUnchinSuu)) {
                            setHaishaInfo((prev) => ({ ...prev, jippiUnchinSuu }));
                          }
                        }}
                        {...keyDownRegister(5)}
                      />
                      {"× "}
                      <input
                        type="text"
                        className="w--55 mr--5"
                        value={haishaInfo?.jippiUnchinTanka || ""}
                        onChange={(e) => {
                          const jippiUnchinTanka = e.target.value;
                          if (jippiUnchinTanka === "" || /^\d{1,}$/.test(jippiUnchinTanka)) {
                            setHaishaInfo((prev) => ({ ...prev, jippiUnchinTanka }));
                          }
                        }}
                        {...keyDownRegister(6)}
                      />
                      円&nbsp;=&nbsp;
                      {(
                        parseFloat(haishaInfo?.jippiUnchinSuu || "0") * parseFloat(haishaInfo?.jippiUnchinTanka || "0")
                      ).toLocaleString()}
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">運送備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.unsoBiko}
                        onChange={(e) => {
                          const unsoBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, unsoBiko }));
                        }}
                        {...keyDownRegister(7)}
                      >
                        {haishaInfo?.unsoBiko}
                      </textarea>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">デポ宛備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.depoateBiko}
                        onChange={(e) => {
                          const depoateBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, depoateBiko }));
                        }}
                        {...keyDownRegister(7)}
                      >
                        {haishaInfo?.depoateBiko}
                      </textarea>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">社内備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.shanaiBiko}
                        onChange={(e) => {
                          const shanaiBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, shanaiBiko }));
                        }}
                        {...keyDownRegister(7)}
                      >
                        {haishaInfo?.shanaiBiko}
                      </textarea>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">運送手配ＦＡＸ</div>
                    <div className="form__value">
                      <select
                        name=""
                        className="w--full"
                        defaultValue={haishaInfo?.fax}
                        onChange={(e) => {
                          const fax = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, fax }));
                        }}
                      >
                        <option value="0">しない</option>
                        <option value="1">する</option>
                      </select>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">回収証明書</div>
                    <div className="form__value">
                      <select
                        name=""
                        className="w--full"
                        defaultValue={haishaInfo?.certificate}
                        onChange={(e) => {
                          const certificate = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, certificate }));
                        }}
                      >
                        <option value="0">しない</option>
                        <option value="1">する</option>
                      </select>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">祝日回収</div>
                    <div className="form__value">
                      <select
                        name=""
                        className="w--full"
                        defaultValue={haishaInfo?.holidayKbn}
                        onChange={(e) => {
                          const holidayKbn = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, holidayKbn }));
                        }}
                      >
                        <option value="0">そのまま回収</option>
                        <option value="1">前営業日回収</option>
                        <option value="2">翌営業日回収</option>
                        <option value="3">キャンセル</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`toaster ${message && "isOpen"}`}>
            <div className="toaster__message">依頼中の内容が変更されました、データの再送信が必要です</div>
            <div className="toaster__close">
              <a href="javascript:void(0);" className="closeToaster">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                    fill="white"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          <div className="btn-layout --right">
            <a href="#" className="btn --primary jsToaster" onClick={onClickSubmit}>
              <span>保存する</span>
            </a>
            {!!teikiId && (
              <a href="#" className="btn --delete" onClick={onClickDelete}>
                <span>この定期便を削除する</span>
              </a>
            )}
          </div>
        </div>
      </div>
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={infoMessage} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={errorMessage} />
    </>
  );
}
