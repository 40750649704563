import React, { useContext, useMemo } from "react";
import { AdminAppContext } from "../AdminAppContext";
import { formatDate } from "../libs/date";

export default function ({ isIpad = false, isOpen = false, setOpen, item = {}, onAdd, onDelete }) {
  const { keiroList, isViewBinShosaiPane } = useContext(AdminAppContext);

  const isKeiroListExist = useMemo(() => {
    if (!item) return false;
    return keiroList?.some((e) => e.id === item.id);
  }, [item, keiroList]);

  if (!isOpen) {
    return null;
  }

  const mainClass = isIpad ? "pane pane--ipad pane--depo-ipad" : "pane pane--left-up pane--depo-left-up";

  var hoyuritu = (item?.zaikoSuryo / item?.hoyuKanoSuu) * 100;
  hoyuritu = isNaN(hoyuritu) ? 0 : hoyuritu;
  hoyuritu = isFinite(hoyuritu) ? hoyuritu : 0;

  return (
    <div className={`${mainClass} ${isOpen && "isOpen"}`}>
      <div className="pane__header">
        <div className="hdg">
          <div className="hdg__text --depo">デポ</div>
          <a
            href="#"
            className="hdg__close paneClose mapClose"
            rel="depo-left-up"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                fill="#888888"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="pane__body">
        <div className="pane-layout">
          <div className="pane-layout__inner">
            <div className="title --border">
              <div className="title__title">{item?.name}</div>
            </div>
            <div className="requests --normal --fz-s">
              <div className="request">
                <div className="request__title">住所</div>
                <div className="request__value">{item?.jusho}</div>
              </div>
              <div className="request">
                <div className="request__title">電話番号</div>
                <div className="request__value">{item?.tel}</div>
              </div>
              <div className="request">
                <div className="request__title">営業時間</div>
                <div className="request__value">{item?.eigyoJikan}</div>
              </div>
              <div className="request">
                <div className="request__title">休日対応</div>
                <div className="request__value">{item?.kyujitsuTaio}</div>
              </div>
              <div className="request">
                <div className="request__title">洗浄機能</div>
                <div className="request__value">{item?.senjo}</div>
              </div>
              <div className="request">
                <div className="request__title">検品数</div>
                <div className="request__value">{item?.kenpin}</div>
              </div>
              <div className="request">
                <div className="request__title">洗浄数</div>
                <div className="request__value">{item?.senjosu}</div>
              </div>
            </div>
            <div className="note">
              <div>
                <span>備考1：</span>
                <span>{item?.depoBiko}</span>
              </div>
              <div>
                <span>備考2：</span>
                <span>{item?.psBiko}</span>
              </div>
            </div>
            <div className="owned-pallets">
              <div className="owned-pallets__title">現在の保管枚数</div>
              <div className="owned-pallets__body">
                <div>
                  <span>
                    <span>{item?.zaikoSuryo?.toLocaleString() || "－"}</span>枚/
                    {item?.hoyuKanoSuu?.toLocaleString() || "－"}枚
                  </span>
                  <span>{`(${!item || hoyuritu === 0 ? "－" : hoyuritu?.toLocaleString() || "－"}%)`}</span>
                </div>
                <div>
                  <span>
                    <span style={{ width: hoyuritu >= 100 ? 100 + "%" : (hoyuritu || 0) + "%" }}></span>
                  </span>
                </div>
              </div>
            </div>
            <div className="tab --depo">
              <div className="tab__header">
                <div className="tab__menu">
                  <a href="javascript:void(0);" className="--current">
                    今後１週間の予定
                  </a>
                </div>
              </div>
              <div className="tab__body">
                <table className="tab__table">
                  <colgroup>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col className="" />
                  </colgroup>
                  <tr className="tab__th">
                    <th>日付</th>
                    <th>入庫予定数</th>
                    <th>出庫予定数</th>
                    <th>予定保管枚数(使用率)</th>
                  </tr>
                  {item?.rirekis?.map((rireki, index) => (
                    <tr key={index.toString()} className="tab__tr">
                      <td>{formatDate(rireki.shoribi, "M/D(ddd)")}</td>
                      <td className="ta--r">{rireki.nyukoYoteiSuu.toLocaleString()}</td>
                      <td className="ta--r">{rireki.shukoYoteiSuu.toLocaleString()}</td>
                      <td className="ta--r">
                        {rireki.yoteiHokanSuu.toLocaleString()}({rireki.siyouritu.toLocaleString()}%)
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pane__footer">
        {!isIpad && isViewBinShosaiPane && (
          <>
            {!!isKeiroListExist ? (
              <div className="btn-layout --right">
                <a href="#" className="btn --delete" onClick={(e) => onDelete(e, item)}>
                  <span>削除する</span>
                </a>
              </div>
            ) : (
              <div className="btn-layout --center">
                <a href="#" className="btn --primary paneOpen" rel="shinkichumon" onClick={(e) => onAdd(e, item)}>
                  <span>入庫先に指定</span>
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
