import React from "react";
import { Route, Switch } from "react-router-dom";
import { AdminAppContext } from "./AdminAppContext";
import HikitoriHome from "./views/admin/HikitoriHome";
import HikitoriLogin from "./views/admin/HikitoriLogin";
import HikitoriList from "./views/admin/HikitoriList";
import NotFound from "./views/NotFound";

export const routes = [
  { path: "/", exact: true, component: HikitoriHome },
  { path: "/hikitori-login", exact: false, component: HikitoriLogin },
  { path: "/hikitori-list", exact: false, component: HikitoriList },
  { path: "/*", exact: false, component: NotFound },
];

export default function App() {
  return (
    <Route
      path="/hikitori"
      render={({ match: { url } }) => (
        <Switch>
          {routes.map(({ path, exact, component }) => (
            <Route key={path} path={`${url}${path}`} exact={exact} component={component} />
          ))}
        </Switch>
      )}
    />
  );
}
