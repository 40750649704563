import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ReactTabulator } from "react-tabulator";
import { useInViewEffect } from "react-hook-inview";

import Header from "./Header";
import Map from "./Map";
import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import { getMeishos, getKohyoData, getTeikikaishuLabel } from "../../libs/common";
import TeikiShosaiPane from "../../panes/TeikiShosaiPane";
import KaishuIraiLeftUpPane from "../../panes/KaishuIraiLeftUpPane";
import TonyaLeftUpPane from "../../panes/TonyaLeftUpPane";
import DepoLeftUpPane from "../../panes/DepoLeftUpPane";
import TeikiKaishuLeftUp from "../../panes/TeikiKaishuLeftUp";

const teikiColumns = [
  { title: "", field: "alert", formatter: "html", width: 10 },
  { title: "回収店名", field: "name", width: 200 },
  { title: "住所", field: "jusho", width: 200 },
  { title: "商品", field: "shohinName", width: 100 },
  {
    title: "枚数",
    field: "number",
    hozAlign: "right",
    formatter: (cell) => {
      const val = cell.getValue();
      if (val === undefined) {
        return "入庫";
      }
      if (val) {
        return val.toLocaleString() + "枚";
      }
      return "";
    },
    width: 50,
  },
  { title: "依頼日", field: "insertDate", width: 100 },
  { title: "truck", field: "truck", frozen: true, formatter: "html", width: 50 },
];

const TeikiBinItem = memo((props) => {
  const { meishos } = useContext(AppContext);
  const { item, openTeikiSyosai } = props;

  const sekisaiMaster = useMemo(() => {
    try {
      return {
        4: parseInt(getMeishos(meishos, "TOSAI_RITUSU_4T")[0].name),
        10: parseInt(getMeishos(meishos, "TOSAI_RITUSU_10T")[0].name),
      };
    } catch {
      return null;
    }
  }, [meishos]);

  const sekisaiRitu = useMemo(() => {
    if (!!sekisaiMaster) {
      const total = item.meisais.reduce((total, m) => total + parseInt(m.suryo ?? "0"), 0);
      const rate4 = Math.floor((total / sekisaiMaster["4"]) * 100);
      const rate10 = Math.floor((total / sekisaiMaster["10"]) * 100);
      return {
        rate4,
        color4: rate4 > 100 ? "red" : "green",
        rate10,
        color10: rate10 > 100 ? "red" : "green",
      };
    }
    return {};
  }, [sekisaiMaster, item]);

  const [isVisible, setIsVisible] = useState(false);

  const ref = useInViewEffect(([entry], observer) => {
    if (entry.isIntersecting) {
      // イベントをアントリガー
      observer.unobserve(entry.target);
    }
    setIsVisible(entry.isIntersecting);
  }, {});

  return (
    <div className="haisha">
      <div className="haisha__header">
        <div className="summary">
          {process.env.NODE_ENV === "development" && <span>{item.id}</span>}
          <div className="summary__status">
            <div className="status --teikikaishu">
              <span>定期回収</span>
            </div>
          </div>
          {/* TODO 定期回収条件表示 */}
          <div className="summary__date">{""}</div>
          <div className="summary__load">
            <span className={`ico --truck-4t-${sekisaiRitu["color4"]} --s --bold --fz-s mr--10`}>
              {sekisaiRitu["rate4"]}%
            </span>
            <span className={`ico --truck-10t-${sekisaiRitu["color10"]} --s --bold --fz-s`}>
              {sekisaiRitu["rate10"]}%
            </span>
          </div>
          <div className="summary__distance">{/* 距離 */}</div>
          <div className="summary__detail">
            <a
              href="#"
              className="link --secondary --fz-ss --bold paneOpen"
              rel="chumonshosai"
              onClick={(e) => {
                e.preventDefault();
                openTeikiSyosai(item.id);
              }}
            >
              詳細
            </a>
          </div>
        </div>
      </div>
      <div className="haisha__body">
        <div className="haisha__waypoint">
          <div ref={ref} className="waypoint">
            {isVisible ? (
              <ReactTabulator
                className="waypoint waypoint2"
                data={item.meisais}
                columns={teikiColumns}
                options={{
                  movableColumns: true,
                  headerVisible: false,
                  columnResized: (column) => {},
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default function () {
  const { addKeiroListItem, keiroList, setKeiroList } = useContext(AdminAppContext);

  const mapRef = useRef();
  const keiroListRef = useRef();

  // pane view
  const [isViewKaishuIraiLeftUp, setViewkaishuIraiLeftUp] = useState(false);
  const [isViewTonyaLeftUp, setViewTonyaLeftUp] = useState(false);
  const [isViewDepoLeftUp, setViewDepoLeftUp] = useState(false);
  const [isViewTeikiKaishuLeftUp, setViewTeikiKaishuLeftUp] = useState(false);
  // 選択中のマーカー明細
  const [selectMarkerIraiItem, setSelectMarkerIraiItem] = useState();
  const [selectMarkerTonyaItem, setSelectMarkerTonyaItem] = useState();
  const [selectMarkerDepoItem, setSelectMarkerDepoItem] = useState();
  const [selectMarkerTeikiItem, setSelectMarkerTeikiItem] = useState();

  // search state
  const [searchText, setSearchText] = useState("");

  // teikisyosai用
  const [teikiId, setTeikiId] = useState(null);
  // table map 表示データ
  const [list, setList] = useState([]);

  const openTeikiSyosai = (id) => {
    setTeikiId(id);
  };

  const onAddKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();

      addKeiroListItem(item);

      setViewkaishuIraiLeftUp(false);
      setViewTonyaLeftUp(false);
      setViewDepoLeftUp(false);
      setViewTeikiKaishuLeftUp(false);
    },
    [addKeiroListItem]
  );

  const onDeleteKeiroList = useCallback(
    (e, item) => {
      e.preventDefault();
      setKeiroList((prev) =>
        prev.filter((x) => {
          if (item.type === "depo") {
            // 修正時IDなし
            return x.type !== "depo";
          } else {
            if (!!item.meisaiNo) {
              //　修正時IDが同じになってるので+meisaiNo
              return !(x.id === item.id && x.meisaiNo === item.meisaiNo);
            }
            return x.id !== item.id;
          }
        })
      );

      setViewkaishuIraiLeftUp(false);
      setViewTonyaLeftUp(false);
      setViewDepoLeftUp(false);
      setViewTeikiKaishuLeftUp(false);
    },
    [setKeiroList]
  );

  const onClickDetail = useCallback(async (e, item) => {
    e.preventDefault();
    // mapデータではない場合明細が無いため取得
    const detail = await getKohyoData(item);
    const kohyoItem = { ...item, ...detail };
    if (item.type === "kaishuirai") {
      setSelectMarkerIraiItem(kohyoItem);
    } else if (item.type === "tonya") {
      setSelectMarkerTonyaItem(kohyoItem);
    } else if (item.type === "depo") {
      setSelectMarkerDepoItem(kohyoItem);
    } else if (item.type === "teikikaishu") {
      setSelectMarkerTeikiItem(kohyoItem);
    }
    setViewkaishuIraiLeftUp(item.type === "kaishuirai");
    setViewTonyaLeftUp(item.type === "tonya");
    setViewDepoLeftUp(item.type === "depo");
    setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
  }, []);

  const onMarkerClick = useCallback(async (item, detail) => {
    // 選択したマーカーデータ+詳細情報を取得
    try {
      if (item.type === "kaishuirai") {
        setSelectMarkerIraiItem(detail);
      }
      if (item.type === "tonya") {
        setSelectMarkerTonyaItem(detail);
      }
      if (item.type === "depo") {
        setSelectMarkerDepoItem(detail);
      }
      if (item.type === "teikikaishu") {
        setSelectMarkerTeikiItem(detail);
      }

      // leftdownは使わずleftup paneで統一
      setViewkaishuIraiLeftUp(item.type === "kaishuirai");
      setViewTonyaLeftUp(item.type === "tonya");
      setViewDepoLeftUp(item.type === "depo");
      setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const onSearch = useCallback(
    async (e) => {
      if (!!e) {
        e.preventDefault();
      }
      try {
        const res = await axios.get("/api/teiki", {
          params: {
            words: searchText,
          },
        });
        if (res && res.data) {
          const displayList = [];
          const ids = [...new Set(res.data.map((v) => v.id))];
          for (const id of ids) {
            const meisais = res.data
              .filter((e) => e.id === id)
              .map((e, i) => {
                e.type = "teikikaishu";
                e.alert = `<div><span class="num --s --teikikaishu">${i + 1}</span></div>`;

                e.name = e.kokyakuName;
                e.number = e.suryo;
                return e;
              });

            // 入庫デポ生成
            const nyukoDepo = meisais[0].nyukoDepo;
            meisais.push({
              ...nyukoDepo,
              type: "depo",
              alert: '<span class="num --s --goal">&nbsp;</span>',
              name: nyukoDepo?.depoName,
              jusho: nyukoDepo?.depoAddr,
            });

            // meisai[0]をヘッダー情報とみなす
            displayList.push({ ...meisais[0], meisais });
          }

          // const res2 = await axios.get("/api/irai/status-count");
          // if (res2 && res2.data) {
          //   // setIraiStatus(res2.data);
          // }

          setList(displayList);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [searchText]
  );

  // 経路変更
  useEffect(() => {
    if (keiroList.length && keiroList.length !== keiroListRef.current?.length) {
      mapRef.current?.changeCenter(keiroList[0]);
      mapRef.current?.reload();
      keiroListRef.current = keiroList;
    }
  }, [keiroList]);

  useEffect(() => {
    onSearch();

    const timerId = setInterval(() => {
      if (mapRef.current?.isGoogleAvailable()) {
        mapRef.current?.reload();
        clearInterval(timerId);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header eventF12={onSearch} eventF6={() => mapRef.current?.reload()} />
      <div className="main">
        <div className="main-inner">
          <div className="main-layout">
            <div className="main-layout__header">
              <div className="hdg">
                <div className="hdg__text">定期回収マスター</div>
              </div>
              <div className="search --between --border">
                <div className="search__condition">
                  <div className="condition">
                    <div className="condition__freeword">
                      <input
                        type="text"
                        className="w--225 ico --search --s --offset"
                        placeholder="回収店名"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="condition__btn">
                      <a onClick={onSearch} href="#" className="btn --secondary --h-s w--80">
                        <span>検索</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="search__btn">
                  <a
                    href="#"
                    className="btn --primary w--185 paneOpen"
                    rel="chumonshosai"
                    onClick={(e) => {
                      e.preventDefault();
                      openTeikiSyosai(0);
                    }}
                  >
                    <span className="ico --add">新規作成</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="main-layout__body">
              <div className="haishas">
                {list.map((item, i) => (
                  <TeikiBinItem key={i.toString()} item={item} openTeikiSyosai={openTeikiSyosai} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Map
        ref={mapRef}
        markerList={[]}
        {...{
          onMarkerClick,
          // isViewKohyoLeftUp,
        }}
      />

      <TeikiShosaiPane
        onMoveMapCenter={mapRef.current?.changeCenter}
        onDetail={onClickDetail}
        {...{ teikiId, setTeikiId }}
      />

      <KaishuIraiLeftUpPane
        isOpen={isViewKaishuIraiLeftUp}
        setOpen={setViewkaishuIraiLeftUp}
        item={selectMarkerIraiItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <TonyaLeftUpPane
        isOpen={isViewTonyaLeftUp}
        setOpen={setViewTonyaLeftUp}
        item={selectMarkerTonyaItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <DepoLeftUpPane
        isOpen={isViewDepoLeftUp}
        setOpen={setViewDepoLeftUp}
        item={selectMarkerDepoItem}
        onAdd={onAddKeiroList}
        onDelete={onDeleteKeiroList}
      />
      <TeikiKaishuLeftUp
        isOpen={isViewTeikiKaishuLeftUp}
        setOpen={setViewTeikiKaishuLeftUp}
        item={selectMarkerTeikiItem}
        onDelete={onDeleteKeiroList}
      />
    </>
  );
}
