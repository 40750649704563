import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import axios from "axios";

import { AdminAppContext } from "../AdminAppContext";
import { AppContext } from "../AppContext";
import { getMeishoName, getMeishos, getTeikikaishuLabel, TONYA_CHECK_KBN } from "../libs/common";
import { formatDate, getDisplayKiboTime } from "../libs/date";
import { InfoModal } from "../modals/admin/Dialogs";
import { useBoolean } from "../hooks/useBoolean";

export default function ({
  isIpad = false,
  isOpen = false,
  setOpen,
  item = {},
  setItem,
  onAdd,
  onDelete,
  onOpenPictureModal,
}) {
  const { meishos } = useContext(AppContext);
  const { keiroList, isViewBinShosaiPane, hikitoriModalControl, setKeiroList } = useContext(AdminAppContext);

  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);
  const youbiList = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);

  const [isHikitoriOk, setHikitoriOk] = useState(false);
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOk = useRef();

  const [kaishuIraiPaneTab, setKaishuIraiPaneTab] = useState(0);

  const isKeiroListExist = useMemo(() => {
    if (!item) return false;
    return keiroList?.some((e) => e.id === item.id);
  }, [item, keiroList]);

  if (!isOpen) {
    return null;
  }

  const onHikitoriKakutei = async () => {
    try {
      refOk.current = async (e) => {
        e.preventDefault();
        const f = { tonyaCheckKbn: TONYA_CHECK_KBN.OK };
        const res = await axios.put("/api/irai/" + item.id, f);
        setKeiroList((preList) => {
          const newList = [...preList].map((e) => {
            if (e.id === item.id) {
              return { ...e, subType: "", ...f };
            }
            return e;
          });
          return newList;
        });
        setItem((pre) => ({ ...pre, subType: "", ...f }));
        infoModalControl.off();
      };
      setMessage("引取依頼を確定しますか？");
      infoModalControl.on();
    } catch (error) {
      console.error(error);
    }
  };

  const mainClass = isIpad ? "pane pane--ipad pane--kaishuirai-ipad" : "pane pane--left-up pane--kaishuirai-left-up";

  return (
    <>
      <div className={`${mainClass} ${isOpen && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text --kaishuirai">回収依頼</div>
            <div className="hdg__status">
              {item?.henkoFlg === "1" && (
                <div className="status --attention --exclamation">
                  <span>変更依頼</span>
                  {item?.date}
                </div>
              )}
              {item?.tonyaCheckKbn === TONYA_CHECK_KBN.NG && (
                <div className="status --attention">
                  <span>回収店NG</span>
                </div>
              )}
              {!isHikitoriOk && item?.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && (
                <div className="status --attention">
                  <span>引取依頼確認中</span>
                </div>
              )}
            </div>
            <a
              href="javascript:void(0);"
              className="hdg__close paneClose mapClose"
              rel="kaishuirai-left-up"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body">
          <div className="pane-layout">
            <div className="pane-layout__inner">
              <div className="title --border">
                <div className="title__title">{item?.name}</div>
                <div className="title__zaiko">
                  現在の在庫<span>{item?.zaikoSuryo?.toLocaleString()}</span>枚
                </div>
              </div>
              <div className="requests --normal">
                <div className="request">
                  <div className="request__title">回収パレット数</div>
                  <div className="request__value">
                    <span>{item?.number?.toLocaleString()}</span>枚
                  </div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望日</div>
                  <div className="request__value">{item?.request}</div>
                </div>
                <div className="request">
                  <div className="request__title">回収希望時間</div>
                  <div className="request__value">{item?.time}</div>
                </div>
                <div className="request">
                  <div className="request__title">パレットの種類</div>
                  <div className="request__value">{item?.pallet}</div>
                </div>
                <div className="request">
                  <div className="request__title">NG車格</div>
                  <div className="request__value">{item?.ngShakakus}</div>
                </div>
                <div className="request">
                  <div className="request__title">希望車種</div>
                  <div className="request__value">{item?.kiboSharyo}</div>
                </div>
                <div className="request" hidden>
                  <div className="request__title">希望荷役</div>
                  <div className="request__value">{item?.kiboNiyaku}</div>
                </div>
                <div className="request">
                  <div className="request__title">注意事項</div>
                  <div
                    className="request__value"
                    dangerouslySetInnerHTML={{ __html: item?.biko?.replace(/\n/g, "<br />") }}
                  />
                </div>
                <div className="request">
                  <div className="request__title ico --milnet">MILNET番号</div>
                  <div className="request__value">{item?.ido?.denpyoNo}</div>
                </div>

                <div className="btn-layout --new">
                  {!isHikitoriOk && item?.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && (
                    <a
                      href="#"
                      class="btn --secondary --w-l pr--0 jsShowHikitoriirai"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <span>引取依頼を変更</span>
                      <span class="btn__change">
                        <div class="btn__list">
                          <span onClick={hikitoriModalControl.on}>引取依頼を編集</span>
                          <span onClick={onHikitoriKakutei}>引取依頼を確定</span>
                        </div>
                      </span>
                    </a>
                  )}
                </div>
              </div>
              <div className="collection-place --border">
                <div className="collection-place__text">
                  <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                    item?.teikis,
                    youbiList
                  )} ]`}</div>
                  <div className="ico --s --location">{item?.jusho}</div>
                  <div className="ico --s --tel">{item?.tel || "－"}</div>
                  <div className="ico --s --driver">{item?.tanto || "－"}</div>
                </div>
                <div className={`collection-place__pic ${item?.files?.length === 0 && "--noimage"}`}>
                  {item?.files?.length > 0 && (
                    <a href="#" className="jsShowPicture" onClick={onOpenPictureModal}>
                      <img src={item?.files[0]?.url} alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div className="note">
                <div>
                  <div>
                    <span>回収可能曜日：</span>
                    <span>{getMeishoName(youbiList, item?.user?.kaishuYoubiKbns)}</span>
                  </div>
                  <div>
                    <span>回収可能時間：</span>
                    <span>{getDisplayKiboTime(item?.user?.kaishuTimeFrom, item?.user?.kaishuTimeTo)}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>保有限度枚数：</span>
                    <span>{item?.user?.hokanGendo}枚</span>
                  </div>
                  <div hidden>
                    <span>荷役：</span>
                    <span>{getMeishoName(niyakuList, item?.user?.niyakuKbn)}</span>
                  </div>
                </div>
                <div>
                  <span>注意事項：</span>
                  <span
                    className="confirm-layout"
                    dangerouslySetInnerHTML={{
                      __html: item?.user?.biko?.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
              <div className="tab --kaishuirai">
                <div className="tab__header">
                  <div className="tab__menu">
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 0 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(0);
                      }}
                    >
                      出荷元
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 1 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(1);
                      }}
                    >
                      最近の回収実績
                    </a>
                    <a
                      href="javascript:void(0);"
                      className={kaishuIraiPaneTab === 2 && "--current"}
                      onClick={(e) => {
                        e.preventDefault();
                        setKaishuIraiPaneTab(2);
                      }}
                    >
                      入出庫履歴
                    </a>
                  </div>
                </div>
                <div className="tab__body">
                  <table className="tab__table" style={{ display: kaishuIraiPaneTab === 0 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "42%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>会社名</th>
                      <th>商品</th>
                      <th>MC</th>
                      <th>PC</th>
                    </tr>
                    {item?.shukkamotos?.map((item, index) => (
                      <tr className="tab__tr" key={"shukkamoto-" + index.toString()}>
                        <td>{item.tokuisakiName}</td>
                        <td>{item.shohinName}</td>
                        <td className="ta--r">{item.mc}</td>
                        <td className="ta--r">{item.pc}</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: kaishuIraiPaneTab === 1 ? "table" : "none" }}>
                    <colgroup>
                      <col />
                      <col style={{ width: "47%" }} />
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "18%" }} />
                    </colgroup>
                    <tr className="tab__th">
                      <th>回収日</th>
                      <th>運送会社</th>
                      <th>運賃単位</th>
                      <th>回収金額</th>
                    </tr>
                    {item?.kaishujissekis?.map((item, index) => (
                      <tr className="tab__tr" key={"kaishujissekis-" + index.toString()}>
                        <td>{formatDate(item.kaishuBi, "M/D(ddd)")}</td>
                        <td>{item.unsoName}</td>
                        <td>{item.jippiUnchinSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.kaishuKingaku.toLocaleString()}円</td>
                      </tr>
                    ))}
                  </table>
                  <table className="tab__table" style={{ display: kaishuIraiPaneTab === 2 ? "table" : "none" }}>
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "25%" }} />
                      <col className="" />
                    </colgroup>
                    <tr className="tab__th">
                      <th>日付</th>
                      <th>入庫</th>
                      <th>回収</th>
                      <th>在庫</th>
                    </tr>
                    {item?.nyushukkorirekis?.map((item, index) => (
                      <tr className="tab__tr" key={"nyushukkorirekis-" + index.toString()}>
                        <td>{formatDate(item.shoribi, "M/D(ddd)")}</td>
                        <td className="ta--r">{item.nyukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.shukoSuu.toLocaleString()}</td>
                        <td className="ta--r">{item.zaikoSuu.toLocaleString()}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          {!isIpad && isViewBinShosaiPane && (
            <>
              {isKeiroListExist ? (
                <div className="btn-layout --right">
                  <a href="#" className="btn --delete -l" onClick={(e) => onDelete(e, item)}>
                    <span>この配車依頼から削除する</span>
                  </a>
                </div>
              ) : (
                <div className="btn-layout --center">
                  <a
                    href="javascript:void(0);"
                    className="btn --primary paneOpen"
                    rel="shinkichumon"
                    onClick={(e) => onAdd(e, item)}
                  >
                    <span>注文に追加</span>
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOk.current} />
    </>
  );
}
