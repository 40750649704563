import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { AppContext } from "../../AppContext";
import { useKeyDown } from "../../hooks/useKeyDown";
import { useBoolean } from "../../hooks/useBoolean";
import { createErrorMessage } from "../../libs/common";
import ErrorModal from "../../modals/ErrorModal";

export default function () {
  const history = useHistory();
  const location = useLocation();

  const { loadMe } = useContext(AppContext);
  const keyDownRegister = useKeyDown();

  const [isErrorOpen, errorControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loginCode, setLoginCode] = useState("");

  const onClickLogin = (e) => {
    e.preventDefault();
    axios
      .post("/api/shain-login", { loginCode })
      .then(async (res) => {
        await loadMe();
        if (window.navigator.userAgent.toLowerCase().includes("ipad")) {
          history.replace("/admin/ipad");
        } else {
          history.replace("/admin/irai-list");
        }
      })
      .catch((error) => {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorControl.on();
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="login">
        <div className="login__inner">
          <div className="login__logo">
            <img src="/assets/admin/Images/lgo-v.png" alt="SANKO PALLET STATION" />
          </div>
          <div className="login__id">
            <input
              type="text"
              placeholder="ログインID（社員番号）"
              value={loginCode}
              onChange={(e) => setLoginCode(e.target.value)}
              {...keyDownRegister(1)}
            />
          </div>
          <div className="login__btn">
            <a onClick={onClickLogin} href="#" className="btn --primary" {...keyDownRegister(2)}>
              <span>ログイン</span>
            </a>
          </div>
        </div>
      </div>

      <ErrorModal isOpen={isErrorOpen} control={errorControl} title="ログインエラー" message={errorMessage} />
    </>
  );
}
