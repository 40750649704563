import React, { useContext, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Transition } from "react-transition-group";

import Header from "./Header";
import IraiForm from "./IraiForm";
import { isSP } from "../libs/common";
import { AppContext } from "../AppContext";

const duration = 500;

const defaultStyle = {
  display: "block",
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function Irai() {
  const { me } = useContext(AppContext);
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [noShow, setNoShow] = useState(false);

  const displaySetting = useCallback(() => {
    if (noShow) {
      sessionStorage.setItem("alreadyModalRegistration", "1");
    }
  }, [noShow]);

  const onRegister = useCallback(
    (e) => {
      e.preventDefault();
      displaySetting();
      history.push("/password-register");
    },
    [displaySetting, history]
  );

  const onCloseModal = useCallback(
    (e) => {
      e.preventDefault();
      displaySetting();
      setOpen(false);
    },
    [displaySetting]
  );

  useEffect(() => {
    const alreadyModalRegistration = sessionStorage.getItem("alreadyModalRegistration");
    if (!!!me && isSP() && !!!alreadyModalRegistration) {
      setOpen(true);
    }
  }, [me]);

  return (
    <>
      <Header />
      <IraiForm isHavi />
      <Transition in={isOpen} timeout={duration} unmountOnExit>
        {(state) => (
          <div className="overlay overlay--primary" style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <div className="modal modal--registration" style={{ ...defaultStyle, ...transitionStyles[state] }}>
              <div className="resistration">
                <div className="resistration__pic">
                  <img src="/assets/Images/pic_resistration.svg" alt="" />
                </div>
                <div className="resistration__title">
                  パスワード登録するだけで
                  <br />
                  簡単・便利にご利用いただけます
                </div>
                <div className="resistration__detail">
                  <ul>
                    <li>回収タイミングを自動でお知らせ</li>
                    <li>依頼内容の編集やキャンセル</li>
                    <li>手配状況をまとめて管理</li>
                  </ul>
                </div>
                <div className="btn-layout --col-1 pl--16 pr--16">
                  <a herf="#" className="btn --primary" onClick={onRegister}>
                    <span>登録して利用する</span>
                  </a>
                </div>
              </div>
              <div className="btn-layout --col-1">
                <a href="#" className="btn --wht-primary modalClose" onClick={onCloseModal}>
                  <span>登録せずに回収依頼する</span>
                </a>
              </div>
              <div className="mt--25">
                <label className="jc--c">
                  <input
                    type="checkbox"
                    checked={noShow}
                    onChange={() => {
                      setNoShow((prev) => !prev);
                    }}
                  />
                  <span></span>
                  <span className="txt --fz-s">今後このメッセージを表示しない</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
}
