import React, { useState, useMemo } from "react";

export const useBoolean = (initialValue) => {
  const [bool, setBool] = useState(initialValue);

  const control = useMemo(() => {
    const on = () => setBool(true);
    const off = () => setBool(false);
    const toggle = () => setBool((prev) => !prev);
    return { on, off, toggle };
  }, []);

  return [bool, control];
};
