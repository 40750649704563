import React, { useState, useEffect, useMemo, useContext } from "react";
import Helmet from "react-helmet";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import dayjs from "dayjs";
import { AppContext } from "../AppContext";

import { basedate, months, weekdaysShort, initialMonth, getYasumiakeDay } from "../libs/date";

const Navbar = ({ nextMonth, previousMonth, onPreviousClick, onNextClick, month }) => {
  const buttonStyle = {
    color: "#888888",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
  };
  const monthStyle = {
    color: "#888888",
    fontSize: "16px",
    fontWeight: "bold",
    paddingLeft: "48px",
    paddingRight: "48px",
  };

  return (
    <div style={{ display: "flex", flex: 1, width: "100%", alignItems: "center", justifyContent: "space-evenly" }}>
      <button style={buttonStyle} onClick={() => onPreviousClick()}>
        {"〈"}
      </button>
      <span style={monthStyle}>{dayjs(month).format("YYYY年M月")}</span>
      <button style={buttonStyle} onClick={() => onNextClick()}>
        {"〉"}
      </button>
    </div>
  );
};

export default function KikanCalendar(props) {
  const { kiboDateFrom, kiboDateTo, setKiboDateFrom, setKiboDateTo, selectKiboDateType, setSelectKiboDateType } = props;
  const [state, setState] = useState({
    from: null,
    to: null,
    enteredTo: null,
  });

  const { holidays } = useContext(AppContext);
  const yasumiakeDay = useMemo(() => {
    return getYasumiakeDay(holidays);
  }, [holidays]);

  const { from, to, enteredTo } = state;
  const modifiers = { start: from, end: enteredTo };
  const disabledDays = [
    { daysOfWeek: [0] },
    yasumiakeDay ? { before: yasumiakeDay } : { before: state.from ? state.from : basedate },
  ];
  const selectedDays = [from, { from, to: enteredTo }];

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const { from, to } = state;
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      setState({
        ...state,
        to: day,
        enteredTo: day,
      });
      setKiboDateFrom(dayjs(state.from).format("YYYYMMDD"));
      setKiboDateTo(dayjs(day).format("YYYYMMDD"));
      setSelectKiboDateType(4);
    }
  };

  const handleDayMouseEnter = (day) => {
    const { from, to } = state;
    if (!isSelectingFirstDay(from, to, day)) {
      setState({
        ...state,
        enteredTo: day,
      });
    }
  };

  const handleResetClick = () => {
    setState({
      from: null,
      to: null,
      enteredTo: null,
    });
    setKiboDateFrom("");
    setKiboDateTo("");
  };

  const displayDate = useMemo(() => {
    let label = "";
    if (from && to) {
      label = dayjs(from).format("M月D日(ddd)") + " － " + dayjs(to).format("M月D日(ddd)") + " 間";
    }
    return label;
  }, [from, to]);

  useEffect(() => {
    if (selectKiboDateType !== 4) {
      setState({
        from: null,
        to: null,
        enteredTo: null,
      });
    }
  }, [selectKiboDateType]);

  return (
    <>
      <div className="calendar" id="calendar-kigenbi">
        <DayPicker
          className="RangeKikan"
          initialMonth={initialMonth}
          firstDayOfWeek={1}
          fromMonth={from}
          months={months}
          weekdaysShort={weekdaysShort}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          onDayTouchEnd={handleDayClick}
          // onDayMouseEnter={handleDayMouseEnter}
          navbarElement={(props) => Navbar(props)}
          captionElement={(props) => null}
          showOutsideDays
        />
        <Helmet>
          <style>{`
            .RangeKikan {
              padding-top: 28px;
            }
            .RangeKikan .DayPicker-Weekdays {
              padding-top: 24px;
              font-size: 12px;
            }
            .RangeKikan .DayPicker-WeekdaysRow {
              margin-top: 24px;
            }
            .RangeKikan .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #E9F5F5 !important;
              color: #000;
            }
            .RangeKikan .DayPicker-Day--start {
              background-color: #279E9E !important;
              border-radius: 10% 0% 0% 10% !important;
            }
            .RangeKikan .DayPicker-Day--end {
              background-color: #279E9E !important;
              border-radius: 0% 10% 10% 0% !important;
            }
            .RangeKikan .DayPicker-Day {
              font-size: 1.6rem;
              border-radius: 0px;
              padding: 10px;
              padding-left: 13px;
              padding-right: 13px;
              border-style: none;
              outline: none;
            }
            .RangeKikan .DayPicker-Day--today {
              color: #279E9E
            }
            .RangeKikan > .DayPicker-Day--outside .DayPicker-Day--disabled {
              color: #DCDCDC
            }
          `}</style>
        </Helmet>
      </div>

      <div className="txt --secondary --fz-m --bold ta--c pt--10">
        <div className="txt --secondary --fz-m --bold ta--c">
          {!from && !to && "期間を選択してください"}
          {from && !to && "期間を選択してください"}
          {from && to && displayDate}
        </div>
      </div>
    </>
  );
}
