import React, { memo, useCallback, useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { Helmet } from "react-helmet";

export const Select = memo((props) => {
  const { options, placeholder, keyDownRegister, tabIndex, selectValue, setSelectValue, free = false } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");

  const renderSuggestion = useCallback((suggestion) => {
    return (
      <span style={{ display: "inline-block", fontSize: 12, fontWeight: "normal" }}>
        {suggestion.name}{" "}
        {suggestion.check === 1 && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#279E9E">
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>
        )}
      </span>
    );
  }, []);

  const getSuggestions = useCallback(
    (value) => {
      const inputValue = value.trim().toUpperCase();
      const inputLength = inputValue.length;

      if (!inputLength) {
        return [];
      }
      return options.filter((obj) => obj.code.indexOf(inputValue) !== -1 || obj.name.indexOf(inputValue) !== -1);
    },
    [options]
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      setValue(value);
      const tmpList = getSuggestions(value);
      setSuggestions(tmpList);
    },
    [getSuggestions]
  );

  const inputProps = {
    placeholder,
    value,
    onChange: (e, data) => {
      setValue(data.newValue);
      // optionsに無い場合の値変更時値削除
      if (!!free) {
        setSelectValue({ suggestion: { code: "", name: data.newValue } });
      } else if (!!selectValue && !options.includes((x) => x.name === data.newValue)) {
        setSelectValue({ suggestion: {} });
      }
    },
    ...keyDownRegister(tabIndex),
  };

  useEffect(() => {
    if (!!selectValue) {
      setValue(selectValue);
    } else {
      setValue("");
    }
  }, [selectValue]);

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={(suggestion) => suggestion.name}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={(inputProps) => <input type="text" className="w--full" {...inputProps} />}
        highlightFirstSuggestion={true}
        onSuggestionSelected={(e, data) => setSelectValue(data)}
      />
      <Helmet>
        <style>
          {`
          .react-autosuggest__container {
            position: relative;
          }
          .react-autosuggest__suggestions-container {
            display: none;
          }
          .react-autosuggest__suggestions-container--open {
            display: block;
            position: absolute;
            top: 32px;
            width: 280px;
            border: 1px solid #aaa;
            background-color: #fff;
            font-size: 12px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            z-index: 10000;
          }
          .react-autosuggest__suggestions-list {
            list-style-type: none;
            max-height: 260px;
            overflow-x: auto;
          }
          .react-autosuggest__suggestion {
            cursor: pointer;
            padding: 4px;
          }
          .react-autosuggest__suggestion--highlighted {
            background-color: #ddd;
          }
          `}
        </style>
      </Helmet>
    </>
  );
});
