import React, { useRef } from "react";

// enterキーのkeydown処理
export const useKeyDown = () => {
  const refElements = useRef(new Map());

  return (index) => ({
    ref: (el) => {
      if (el) refElements.current.set(index, el);
      else refElements.current.delete(index);
    },
    onKeyDown: ({ key }) => {
      if (key !== "Enter") return;
      const sortedIndices = [...refElements.current.keys()].sort();
      const nextIndex = sortedIndices[sortedIndices.indexOf(index)] + 1;
      if (typeof nextIndex === "number") {
        if (nextIndex > [...refElements.current.keys()].length) return;
        if (!refElements.current.has(nextIndex)) return;

        refElements.current.get(nextIndex).focus();
      }
    },
  });
};
