import React, { useEffect, useState, useCallback, useMemo, memo, useRef, useContext } from "react";
import { useFileUpload } from "use-file-upload";
import axios from "axios";

import { AppContext } from "../AppContext";
import Header from "./Header";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";
import { useBoolean } from "../hooks/useBoolean";
import InfoModal from "../modals/InfoModal";
import ErrorModal from "../modals/ErrorModal";
import IraiForm from "./IraiForm";
import { formatDate } from "../libs/date";
import {
  createErrorMessage,
  getMeishos,
  getTeikikaishuLabel,
  TONYA_CHECK_KBN,
  UNSO_CHECK_KBN,
  CANCEL_KBN,
} from "../libs/common";

const IraiItem = memo((props) => {
  const {
    tehai,
    onNaiyoHenko,
    onShasinHenshu,
    onOpenBikoPane,
    onIraiCancel,
    infoModalControl,
    setMessage,
    refOkEvent,
    onPreviewShasin,
  } = props;

  const { meisais } = tehai;
  const shohinNames = meisais.map((x) => x.shohinName).join("、");
  const shohinSuryo = meisais.reduce((total, n) => total + n.suryo, 0).toLocaleString();
  // 表示用希望日
  const displayKiboDate = useMemo(() => {
    let tag = "希望なし";
    if (!tehai.kiboDateFrom && !tehai.kiboDateTo) {
      return <span>{tag}</span>;
    }
    if (tehai.kiboDateFrom === tehai.kiboDateTo) {
      tag = (
        <>
          <span>{formatDate(tehai.kiboDateFrom, "M月D日")}</span>
          {formatDate(tehai.kiboDateFrom, "(ddd)")}
        </>
      );
    } else {
      tag = (
        <>
          <span>{formatDate(tehai.kiboDateFrom, "M月D日")}</span>
          {formatDate(tehai.kiboDateFrom, "(ddd)")}－<br />
          <span>{formatDate(tehai.kiboDateTo, "M月D日")}</span>
          {formatDate(tehai.kiboDateTo, "(ddd)")}間
        </>
      );
    }
    return tag;
  }, [tehai]);

  // 表示用希望時間
  const displayKiboTime = useMemo(() => {
    let label = "希望なし";
    if (tehai.kiboTimeBiko) {
      return tehai.kiboTimeBiko;
    }
    if (!!!tehai.kiboTimeFrom && !!!tehai.kiboTimeTo) {
      return "希望なし";
    }
    label =
      formatDate("20210101" + tehai.kiboTimeFrom, "H時") + "～" + formatDate("20210101" + tehai.kiboTimeTo, "H時");
    if (label.indexOf("9") !== -1) {
      label = "午前 " + label;
    } else if (label.indexOf("17") !== -1) {
      label = "午後 " + label;
    }
    return label;
  }, [tehai]);

  const hasShasin = tehai.files && tehai.files?.length !== 0;
  const shasinMsg = hasShasin ? "回収場所の写真を編集" : "回収場所の写真を追加";

  // TODO 状態タグを正しくする
  const isDone = tehai.tonyaCheckKbn === TONYA_CHECK_KBN.NG;

  const ItemTag = useMemo(() => {
    let tagClass = "order__ico-primary",
      tagName = "依頼中";
    if (tehai.binId !== 0) {
      tagName = "手配中";
    }
    if (tehai.bin?.unsoCheckKbn === UNSO_CHECK_KBN.OK) {
      tagName = "配車手配済";
    }
    if (tehai.idoStatus === "3" || tehai.idoStatus === "4") {
      tagName = "回収済";
      tagClass = "order__ico-blk";
    }
    if (tehai.cancelKbn === CANCEL_KBN.CANCELTYU) {
      tagName = "キャンセル依頼中";
    }
    if (tehai.cancelKbn === CANCEL_KBN.ZUMI) {
      tagName = "キャンセル済";
      tagClass = "order__ico-blk";
    }
    return { tagClass, tagName };
  }, [tehai]);

  const bintehai = useMemo(() => {
    if (tehai.binId !== 0) {
      return true;
    }
    if (tehai.bin?.unsoCheckKbn === UNSO_CHECK_KBN.OK) {
      return true;
    }
    if (tehai.idoStatus === "3" || tehai.idoStatus === "4") {
      return true;
    }
    return false;
  }, [tehai]);

  const kiboDetail = useMemo(() => {
    return (
      <div>
        <div className="confirm-layout">
          <div className="confirm-layout__title">回収希望日</div>
          <div className="confirm-layout__detail">{displayKiboDate || "希望なし"}</div>
        </div>
        <div className="confirm-layout">
          <div className="confirm-layout__title">回収希望時間</div>
          <div className="confirm-layout__detail">{displayKiboTime || "希望なし"}</div>
        </div>
      </div>
    );
  }, [displayKiboDate, displayKiboTime]);

  const handleCancel = (e) => {
    e.preventDefault();

    refOkEvent.current = (e) => {
      e.preventDefault();
      onIraiCancel(tehai);
    };

    setMessage("キャンセルを三甲リースに依頼します\nよろしいですか？");
    infoModalControl.on();
  };

  return (
    <div className={`status ${isDone ? "--done" : ""}`}>
      <div className="status__detail">
        <div className="status__detail-l">
          <div className="order">
            <div className="order__l">
              <div className="order__ico">
                {process.env.NODE_ENV === "development" && <span>{tehai.id}</span>}
                <span className={ItemTag.tagClass}>{ItemTag.tagName}</span>
                {tehai.teikiFlg === "1" && <span className="order__ico-blk">定期回収</span>}
              </div>
              <div className="order__date">
                <span>{bintehai ? formatDate(tehai.hikitoriDate, "M月D日(ddd)") : displayKiboDate}</span>
              </div>
              <div className="order__txt">{bintehai ? tehai.hikitoriTimeText : displayKiboTime}</div>
            </div>
            <div className="order__r">
              <div className="order__pic">
                <div className="order__pic-pic">
                  <a
                    href="javascript:void(0);"
                    className="modalPictureOpen"
                    rel={hasShasin ? tehai.url : "kaishubashonoshashin-new"}
                    onClick={(e) => onPreviewShasin(e, isDone, hasShasin, tehai)}
                  >
                    {hasShasin && <img src={tehai.files[0].url} alt="" />}
                  </a>
                </div>
                {!isDone && (
                  <div className="order__pic-edit">
                    <a
                      href="#"
                      className="paneOpen"
                      rel={`kaishubashonoshashin-${hasShasin ? "exist" : "new"}`}
                      onClick={(e) => onShasinHenshu(e, tehai, hasShasin)}
                    >
                      {shasinMsg}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="status__detail-r">
          <div className="confirm-layouts --narrow">
            <div className="confirm-layout">
              <div className="confirm-layout__title">回収パレット数</div>
              <div className="confirm-layout__detail">{shohinSuryo}枚</div>
            </div>
            <div className="confirm-layout">
              <div className="confirm-layout__title">パレットの種類</div>
              <div className="confirm-layout__detail">{shohinNames}</div>
            </div>
            <div className="confirm-layout">
              <div className="confirm-layout__title">注意事項</div>
              <div
                className="confirm-layout__detail"
                dangerouslySetInnerHTML={{
                  __html: tehai?.biko?.replace(/\n/g, "<br />"),
                }}
              />
            </div>
            <div className="confirm-layout">
              <div className="confirm-layout__title">運送会社</div>
              <div className="confirm-layout__detail">{tehai?.bin?.unsoName || ""}</div>
            </div>
            <div className="confirm-layout">
              <div className="confirm-layout__title">車種</div>
              <div className="confirm-layout__detail">{tehai?.sharyo?.shashu || ""}</div>
            </div>
            <div className="confirm-layout">
              <div className="confirm-layout__title">ドライバー</div>
              <div className="confirm-layout__detail">
                {tehai?.sharyo?.driverName || ""}
                {tehai?.sharyo?.tel && (
                  <>
                    [{" "}
                    <a href={`tel:${tehai?.sharyo?.tel}`} className="link --secondary">
                      {tehai?.sharyo?.tel}
                    </a>{" "}
                    ]
                  </>
                )}
              </div>
            </div>
            {bintehai ? kiboDetail : ""}
          </div>
        </div>
      </div>

      {!isDone && (
        <div className="status__footer">
          <div className="status__footer-l">
            <div className="btn-layout --col-2">
              <div>
                <a
                  href="javascript:void(0);"
                  className="btn --h-s --secondary paneOpen"
                  rel="naiyohenko"
                  onClick={(e) => onNaiyoHenko(e, tehai)}
                  disabled={tehai.binId !== 0 ? "disabled" : ""}
                >
                  <span>内容を変更する</span>
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="btn --h-s --secondary paneOpen"
                  rel="biko"
                  onClick={(e) => onOpenBikoPane(e, tehai)}
                >
                  <span>注意事項を追加する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="status__footer-r">
            {tehai.binId === 0 && (
              <a
                href="#"
                className="ico --trash link --secondary --fz-s modalOpen"
                rel="order-cancel"
                onClick={handleCancel}
              >
                この回収依頼をキャンセル
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default function Tehaijokyo() {
  const { me, meishos, teikis } = useContext(AppContext);
  const [, SelectNewFile] = useFileUpload();

  const youbis = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);

  const inputFileRef = useRef();

  // STATE
  const [order, setOrder] = useState("1");
  const [selectItem, setSelectItem] = useState(null);
  const [tehaiList, setTehaiList] = useState(null);

  const [files] = useState(me?.files || []);
  const [newTmpPaths, setNewTmpPaths] = useState([]);

  // 共通モーダル制御
  const [isPictureModal, pictureModalContrl] = useBoolean(false);
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOkEvent = useRef(null);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  // 内部View制御
  const [isViewNaiyoHenko, setViewNaiyoHenko] = useState(false);
  const [isViewKaishubashonoshashinNew, setViewKaishubashonoshashinNew] = useState(false);
  const [isViewKaishubashonoshashinExit, setViewKaishubashonoshashinExit] = useState(false);
  const [isViewBiko, setViewBiko] = useState(false);

  // 定期回収条件
  const dispTeikiKaishu = useMemo(() => getTeikikaishuLabel(teikis, youbis), [teikis, youbis]);

  const getData = useCallback(async (e, order) => {
    if (!!e) {
      e.preventDefault();
    }
    try {
      const res = await axios.get("/api/dashboard?order=" + order);
      if (res && res.data) {
        setTehaiList(res.data.irais || []);
      }
      setOrder(order);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const onNaiyoHenko = useCallback((e, tehai) => {
    e.preventDefault();
    setSelectItem(tehai);
    setViewNaiyoHenko(true);
  }, []);

  const onOpenBikoPane = useCallback((e, tehai) => {
    e.preventDefault();
    setSelectItem(tehai);
    setViewBiko(true);
  }, []);

  const onAddBiko = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const res = await axios.put("/api/irai/" + selectItem.id, {
          ...selectItem,
        });

        setTehaiList((prev) => {
          return [...prev].map((x) => {
            if (x.id === selectItem.id) {
              return selectItem;
            }
            return x;
          });
        });
      } catch (error) {
        console.warn(error);
      }
      setSelectItem(null);
      setViewBiko(false);
    },
    [selectItem]
  );

  const onIraiCancel = useCallback(
    async (tehai) => {
      try {
        infoModalControl.off();

        const res = await axios.post("/api/irai/cancel-tonya", {
          id: tehai.id,
        });
        const newList = [...tehaiList].map((x) => {
          if (x.id === tehai.id) {
            return { ...x, tonyaCheckKbn: TONYA_CHECK_KBN.NG };
          }
          return x;
        });
        setTehaiList(newList);
        setSelectItem(null);

        refOkEvent.current = undefined;
        setMessage("キャンセル依頼をしました\r\n※キャンセルは未確定です。\r\n三甲リースから連絡がある場合があります");
        infoModalControl.on();
      } catch (error) {
        console.warn(error);
      }
    },
    [infoModalControl, tehaiList]
  );

  const onShasinHenshu = useCallback((e, tehai, hasShasin) => {
    e.preventDefault();
    setSelectItem(tehai);
    if (tehai.files && tehai.files.length > 0) {
      setNewTmpPaths(tehai.files);
    } else {
      setNewTmpPaths([]);
    }

    // 写真有無を確認して振り分け
    if (hasShasin) {
      setViewKaishubashonoshashinExit(true);
    } else {
      setViewKaishubashonoshashinNew(true);
    }
  }, []);

  const onShasinKakutei = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (selectItem && newTmpPaths) {
          let target = null;
          const newList = [...tehaiList].map((x) => {
            if (x.id === selectItem.id) {
              target = { ...x, files: newTmpPaths };
              return target;
            }
            return x;
          });

          if (target) {
            const res = await axios.put("/api/irai/" + selectItem.id, {
              ...selectItem,
              fileIds: [], // 一旦全部削除
              newTmpPaths: newTmpPaths.map((x) => x.path), //選択した写真を登録
              copyFileIds: [],
            });

            if (res && res.data) {
              setTehaiList(newList);
            }
            setSelectItem(null);
            setNewTmpPaths([]);
          }
        }
        setViewKaishubashonoshashinNew(false);
        setViewKaishubashonoshashinExit(false);
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [selectItem, newTmpPaths, tehaiList, errorModalControl]
  );

  const onShasinDelete = useCallback(() => {
    refOkEvent.current = (e) => {
      e.preventDefault();
      infoModalControl.off();

      // setSelectItem((pre) => ({ ...pre, files: [] }));
      setNewTmpPaths([]);

      // 決定で削除
      // const newList = [...tehaiList].map((x) => {
      //   if (x.id === newSelectItem.id) {
      //     return newSelectItem;
      //   }
      //   return x;
      // });
      // setTehaiList(newList);
      // setViewKaishubashonoshashinExit(false);
    };
    setMessage("写真を削除します\nよろしいですか？");
    infoModalControl.on();
  }, [infoModalControl]);

  const onPreviewShasin = useCallback(
    (e, isDone, hasShasin, tehai) => {
      e.preventDefault();
      if (isDone) return;

      setSelectItem(tehai);
      if (hasShasin) {
        pictureModalContrl.on();
        return;
      }
      setNewTmpPaths([]);
      setViewKaishubashonoshashinNew(true);
    },
    [pictureModalContrl]
  );

  const onFileSelect = useCallback(
    async (e) => {
      const file = e.target.files[0];
      try {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          const blob = URL.createObjectURL(file);

          const res = await axios.post("/api/file/tmp", formData);
          if (res && res.data) {
            setNewTmpPaths([{ url: blob, path: res.data[0] }]);
          }
        }
      } catch (error) {
        setErrorMessage("ファイルのアップロードに失敗しました");
        errorModalControl.on();
      }
    },
    [errorModalControl]
  );

  const renderItem = useMemo(() => {
    if (tehaiList === null) {
      return <span>取得中...</span>;
    }

    return (
      <>
        {tehaiList.length ? (
          tehaiList.map((tehai) => (
            <IraiItem
              key={tehai.id}
              {...{
                tehai,
                onNaiyoHenko,
                onShasinHenshu,
                onOpenBikoPane,
                onIraiCancel,
                infoModalControl,
                setMessage,
                refOkEvent,
                onPreviewShasin,
              }}
            />
          ))
        ) : (
          <div className="status">
            <div className="txt --fz-m --blk-300 ta--c">現在回収依頼はありません</div>
          </div>
        )}
      </>
    );
  }, [
    tehaiList,
    onNaiyoHenko,
    onShasinHenshu,
    onOpenBikoPane,
    onIraiCancel,
    infoModalControl,
    setMessage,
    refOkEvent,
    onPreviewShasin,
  ]);

  useEffect(() => {
    getData(null, "1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc current="/tehai-jyokyo" />
        <div className="main__main">
          <div className="main__main-header">
            <MainNavi current="/tehai-jyokyo" />

            <div className="hdg --h1">
              <span className="hdg__title">手配状況</span>
              <span className="hdg__note ico --calendar">{`定期回収 [ ${dispTeikiKaishu} ]`}</span>
            </div>
          </div>
          <div className="main__main-contents">
            <div className="inner padding-for-sp">
              <div className="sort --center">
                <div className="sort__title">並び替え</div>
                <div className={`sort__sort ${order === "1" && "--current"}`}>
                  <a href="#" onClick={(e) => getData(e, "1")}>
                    新しい依頼順
                  </a>
                </div>
                <div className={`sort__sort ${order === "2" && "--current"}`}>
                  <a href="#" onClick={(e) => getData(e, "2")}>
                    回収日が近い順
                  </a>
                </div>
              </div>

              {renderItem}
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>

      {isViewNaiyoHenko && (
        <div className={`pane pane--naiyohenko ${isViewNaiyoHenko && "isOpen"}`}>
          <>
            <div className="pane__header">
              <a
                href="javascript:void(0);"
                className="pane__header-back paneClose"
                onClick={(e) => {
                  e.preventDefault();
                  setViewNaiyoHenko(false);
                }}
              ></a>
              <div className="pane__header-title">内容変更</div>
            </div>
            <IraiForm item={selectItem} />
          </>
        </div>
      )}

      {/* 手配状況専用モーダル */}
      <input type="file" ref={inputFileRef} hidden accept="image/*" onChange={onFileSelect} />
      <div
        className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-new ${
          isViewKaishubashonoshashinNew && "isOpen"
        }`}
      >
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setNewTmpPaths([]);
              setViewKaishubashonoshashinNew(false);
            }}
          ></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                {newTmpPaths.length > 0 && <img src={newTmpPaths[0].url} alt="" />}
              </div>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">登録写真から選択</div>
              </div>
            </div>
            <div className="pictures --selectable">
              {files.map((place) => (
                <div
                  key={place.url}
                  className={`picture ${newTmpPaths.length > 0 && place.path === newTmpPaths[0].path && "--current"}`}
                >
                  <a
                    href="javascript:void(0);"
                    className="picture__pic"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewTmpPaths([place]);
                    }}
                  >
                    <img src={place.url} alt="" />
                  </a>
                </div>
              ))}
            </div>
            <div className="btn-layout --stack --w-s">
              <div>
                <a
                  href="#"
                  className="btn --wht-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (inputFileRef.current) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  <span>写真を選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary" onClick={onShasinKakutei}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-exist ${
          isViewKaishubashonoshashinExit && "isOpen"
        }`}
      >
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewKaishubashonoshashinExit(false);
            }}
          ></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                {selectItem && (
                  <a
                    href="javascript:void(0);"
                    className="modalPictureOpen"
                    rel={newTmpPaths.length > 0 ? newTmpPaths[0].url : ""}
                  >
                    {newTmpPaths.length > 0 && <img src={newTmpPaths[0].url} alt="" />}
                  </a>
                )}
              </div>
              <div className="selected-picture__delete">
                <a
                  href="javascript:void(0);"
                  className="ico --trash link --secondary --fz-s modalOpen"
                  rel="delete-photo"
                  onClick={onShasinDelete}
                >
                  削除する
                </a>
              </div>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">登録写真から選択</div>
              </div>
            </div>
            <div className="pictures --selectable">
              {files.map((place) => (
                <div
                  key={place.id}
                  className={`picture ${newTmpPaths.length > 0 && place.path === newTmpPaths[0].path && "--current"}`}
                >
                  <a
                    href="javascript:void(0);"
                    className="picture__pic"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewTmpPaths([place]);
                    }}
                  >
                    <img src={place.url} alt="" />
                  </a>
                </div>
              ))}
            </div>

            <div className="btn-layout --stack --w-s">
              <div>
                <a
                  href="#"
                  className="btn --wht-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (inputFileRef.current) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  <span>写真を選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary" onClick={onShasinKakutei}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--biko ${isViewBiko && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            rel=""
            onClick={(e) => {
              e.preventDefault();
              setSelectItem(null);
              setViewBiko(false);
            }}
          ></a>
          <div className="pane__header-title">備考</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__full">
                  {isViewBiko && (
                    <textarea
                      className="textarea --h-l"
                      name=""
                      placeholder="運送会社やドライバーへの備考（50文字程度）&#13;&#10;&#13;&#10;例）到着したら以下までご一報ください&#13;&#10;000-000-000"
                      value={(selectItem || {}).biko}
                      onChange={(e) => {
                        const biko = e.target.value;
                        setSelectItem((prev) => ({ ...prev, biko }));
                      }}
                    ></textarea>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="#" className="btn --primary" onClick={onAddBiko}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* モーダル */}
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={errorMessage} />
      {isPictureModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#666666",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
              opacity: isPictureModal ? 1 : 0,
            }}
          >
            {selectItem && <img alt="" src={selectItem.files[0]?.url} />}
          </div>
          <div style={{ position: "absolute", top: 16, right: 16 }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                pictureModalContrl.off();
              }}
              style={{ fontSize: 32, color: "#fff" }}
            >
              ✕
            </a>
          </div>
        </div>
      )}
    </>
  );
}
