import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useBoolean } from "../hooks/useBoolean";
import Header from "./Header";
import ErrorModal from "../modals/ErrorModal";
import InfoModal from "../modals/InfoModal";
import axios from "axios";
import { AppContext } from "../AppContext";
import { createErrorMessage } from "../libs/common";
import { useKeyDown } from "../hooks/useKeyDown";
import { TextInput } from "../components/TextInput";

export default function PasswordReset(props) {
  document.body.classList.value = "--scrollable pb--40";

  const history = useHistory();
  const params = useParams();

  const keyDownRegister = useKeyDown();

  const [isValid, setValid] = useState(false);
  const [isInfoOpen, infoControl] = useBoolean(false);
  const [isErrorOpen, errorControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const executable = useMemo(() => {
    if (!!!password || !!!password2) return false;
    if (!!!isValid) return false;
    return true;
  }, [isValid, password, password2]);

  const onPasswordReset = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      setErrorMessage("パスワードを正しく入力してください");
      errorControl.on();
      return;
    }

    try {
      const res = await axios.put("/api/password-reset/" + params.id, {
        password,
        password2,
      });

      if (res) {
        infoControl.on();
      }
    } catch (error) {
      console.warn(error.response);
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorControl.on();
    }
  };

  useEffect(() => {
    axios
      .get("/api/password-reset/" + params.id)
      .then((res) => setValid(true))
      .catch((error) => {
        console.warn(error);
        setErrorMessage("無効なリンクです");
        errorControl.on();
        setValid(false);
      });
  }, [params, errorControl]);

  return (
    <>
      <Header />

      <div className="pane__header">
        <div className="pane__header-title">パスワード再設定</div>
      </div>
      <div className="pane__main">
        <div className="inner">
          <div className="form">
            <TextInput
              title="新しいパスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="英・数字6文字以上"
              type="text"
              tabIndex={1}
              keyDownRegister={keyDownRegister}
            />
            <TextInput
              title="パスワード(確認)"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder="英・数字6文字以上"
              type="text"
              tabIndex={2}
              keyDownRegister={keyDownRegister}
            />
          </div>
          <div className="padding-for-sp">
            <div className="txt --blk-300 --fz-ss --note mt--12">
              ※この操作により、当サイトをご利用中の、他のユーザーのパスワードも変更されます。
              <br />
              貴社内で、複数のご担当者が当サイトをご利用されいている場合は、必ずパスワード変更を周知頂くようお願いいたします。
            </div>
          </div>
        </div>
        <div className="btn-layout --col-1 --stack --sticky">
          <div>
            <a
              href="#"
              className="btn --primary"
              disabled={!!!executable}
              id="jsSubmit-NewPassword"
              onClick={onPasswordReset}
              {...keyDownRegister(3)}
            >
              <span>再設定する</span>
            </a>
          </div>
          <div style={{ display: "none" }}>
            <a href="#" className="btn --wht-primary --h-s">
              <span>戻る</span>
            </a>
          </div>
        </div>
      </div>

      <InfoModal
        isOpen={isInfoOpen}
        control={infoControl}
        title="パスワードを変更しました"
        message="新しいパスワードで再ログインしてください"
        onOk={(e) => {
          e.preventDefault();
          history.push("/login");
        }}
        btnOkTitle={"今すぐログインする"}
        disableClose
      />
      <ErrorModal isOpen={isErrorOpen} control={errorControl} title="エラー" message={errorMessage} />
    </>
  );
}
