import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "./Header";

export default function () {
  useEffect(() => {}, []);

  return (
    <>
      <Link to="/admin/irai-list" class="txt --primary">
        依頼一覧
      </Link>
      <br />
      <Link to="/admin/haisha-list" class="txt --primary">
        配車一覧
      </Link>
      <br />
      <Link to="/admin/teiki-list" class="txt --primary">
        定期回収一覧
      </Link>
      <br />
      <Link to="/admin/data-analysis-listcontact-non" class="txt --primary">
        データ分析一覧
      </Link>
      <br />
      <Link to="/admin/login" class="txt --primary">
        ログイン
      </Link>
      <br />
      <Link to="/admin/404" class="txt --primary">
        404
      </Link>
      <br />
      <Link to="/admin/ipad" class="txt --primary">
        iPad
      </Link>
    </>
  );
}
