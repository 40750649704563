import React from "react";
import { Route, Switch } from "react-router-dom";

import { AppContextProvider } from "./AppContext";
import { AdminAppContextProvider } from "./AdminAppContext";
import { PrivateRoute } from "./PrivateRoute";
import { routes as admminRoutes } from "./AdminRoutes";
import { routes as hikitoriRoutes } from "./HikitoriRoutes";

import Home from "./views/Home";
import PreSignup from "./views/PreSignup";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Irai from "./views/Irai";
import IraiFinish from "./views/IraiFinish";
import Tehaijokyo from "./views/Tehaijokyo";
import TehaijokyoFinish from "./views/TehaijokyoFinish";
import MyPage from "./views/MyPage";
import NyushukkaRireki from "./views/NyushukkaRireki";
import PasswordRegister from "./views/PasswordRegister";
import PasswordReset from "./views/PasswordReset";
import Contact from "./views/Contact";
import Answer from "./views/Answer";
import AnswerAnswered from "./views/AnswerAnswered";
import AnswerThanks from "./views/AnswerThanks";
import TorokuAnnai from "./views/TorokuAnnai";
import NotFound from "./views/NotFound";

export default function App(props) {
  return (
    <AppContextProvider>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/s/:key" component={PreSignup} />
        <Route exact path="/irai" component={Irai} />
        <Route exact path="/irai-finish" component={IraiFinish} />

        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/contact" component={Contact} />

        <PrivateRoute path="/tehai-jyokyo" component={Tehaijokyo} />
        <PrivateRoute path="/tehai-jyokyo-finish" component={TehaijokyoFinish} />
        <PrivateRoute path="/mypage" component={MyPage} />
        <PrivateRoute path="/nyushukka-rireki" component={NyushukkaRireki} />

        <Route path="/password-register" component={PasswordRegister} />
        <Route path="/password-reset/:id" component={PasswordReset} />

        <Route path="/a/:key" component={Answer} />
        <Route path="/answer-answered" component={AnswerAnswered} />
        <Route path="/answer-thanks" component={AnswerThanks} />

        <Route path="/toroku-annai" component={TorokuAnnai} />

        <AdminAppContextProvider>
          <Route
            path="/admin"
            render={({ match: { url } }) => (
              <Switch>
                {admminRoutes.map(({ path, exact, component }) => (
                  <Route key={path} path={`${url}${path}`} exact={exact} component={component} />
                ))}
              </Switch>
            )}
          />
          <Route
            path="/hikitori"
            render={({ match: { url } }) => (
              <Switch>
                {hikitoriRoutes.map(({ path, exact, component }) => (
                  <Route key={path} path={`${url}${path}`} exact={exact} component={component} />
                ))}
              </Switch>
            )}
          />
        </AdminAppContextProvider>

        <Route path="*" component={NotFound} />
      </Switch>
    </AppContextProvider>
  );
}
