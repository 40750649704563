import React, {
  useContext,
  useState,
  useLayoutEffect,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useImperativeHandle,
  memo,
  forwardRef,
} from "react";
import GoogleMap from "google-map-react";
import decodePolyline from "decode-google-map-polyline";
import dayjs from "dayjs";
import axios from "axios";

import { AppContext } from "../../AppContext";
import { actionLog, getKohyoData } from "../../libs/common";
import { formatDate } from "../../libs/date";
import { AdminAppContext } from "../../AdminAppContext";
import { useLocation } from "react-router-dom";

const oneDayAfter = formatDate(dayjs().add(1, "day"), "YYYYMMDD");
const twoDayAfter = formatDate(dayjs().add(2, "day"), "YYYYMMDD");
const threeDayAfter = formatDate(dayjs().add(3, "day"), "YYYYMMDD");
const fourAfter = formatDate(dayjs().add(4, "day"), "YYYYMMDD");

//zIndex　未選択：1 gridデータ:10 GEO:100 経路：1000 選択：10000
const NO_SELECT_INDEX = 1;
const GRID_INDEX = 10;
const GEOLOCATION_INDEX = 100;
const KEIRO_INDEX = 1000;
const SELECT_INDEX = 10000;

const InitKaishuParam = {
  suryoFrom: "",
  suryoTo: "",
  suryoNoLimitFlg: "1",
  kaishuKiboDates: [],
  kaishuKiboDateAfter: "",
  zaikoGendo80PerFlg: "0",
  preHaishaFlg: "0",
};
const InitTonyaParam = {
  zaikoSuryo100Kbn: "0",
  zenkaiKaishuTukiKbn: "0",
  shukkaTuki5Kbn: "0",
  kaishuIraiNoneFlg: "0",
};
const InitTeikiParam = {
  sundayFlg: "1",
  mondayFlg: "1",
  tuesdayFlg: "1",
  wednesdayFlg: "1",
  thursdayFlg: "1",
  fridayFlg: "1",
  saturdayFlg: "1",
};

const ButtomCard = memo(
  ({
    single,
    bottomItem,
    currentButtomId,
    onMapCenter,
    setCurrentButtomId,
    onHandleMarkerClick,
    onHandleMultiItemClick,
    setMultiItem,
  }) => {
    return (
      <div className={`map-points__wrap ${single && "--close"}`}>
        <a
          href="#"
          className={`map-point --${bottomItem.type} paneOpen paneLeftDownClose  ${
            bottomItem.id === currentButtomId && "--current"
          }`}
          rel={`${bottomItem.type}-left`}
          onClick={(e) => {
            e.preventDefault();
            onMapCenter(bottomItem);
            setCurrentButtomId(bottomItem.id);
            if (!!bottomItem && bottomItem.items?.length > 1) {
              onHandleMultiItemClick(bottomItem);
              return false;
            } else {
              setMultiItem(null);
              onHandleMarkerClick(null, bottomItem);
            }
          }}
        >
          <div className="map-point__title">
            {bottomItem.type === "tonya"
              ? "回収店"
              : bottomItem.type === "depo"
              ? "デポ"
              : bottomItem.type === "teikikaishu"
              ? "定期回収"
              : bottomItem.type === "kaishuirai"
              ? "回収依頼"
              : " "}
          </div>
          <div className="map-point__company">{bottomItem?.name}</div>
          {single && bottomItem.type === "kaishuirai" && (
            <div className="map-point__date">回収希望日：{formatDate(bottomItem.kiboTimeFrom, "M/D(ddd)")}</div>
          )}
          <div className="map-point__zaiko">
            <span>在庫数</span>
            <span>{bottomItem?.zaikoSuryo?.toLocaleString()}</span>
            <span>枚</span>
          </div>
        </a>
      </div>
    );
  }
);

const Map = forwardRef(
  (
    { isIpad = false, selectRow = null, markerList = null, onMarkerClick, isViewKohyoLeftUp, onPreIraiIpad },
    mapRef
  ) => {
    const location = useLocation();
    // 定期回収画面コントロール用
    const isTeiki = location.pathname.match(/teiki/);
    // 引取トランコム
    const isHikitori = location.pathname.match(/hikitori/);

    const { me, meishos } = useContext(AppContext);
    const { keiroList, setHaishaInfo } = useContext(AdminAppContext);

    const zoom = 12;
    const [center, setCenter] = useState({ lat: 35.686945215293406, lng: 139.83757840008357 });
    const [current, setCurrent] = useState({ lat: 35.686945215293406, lng: 139.83757840008357 });

    const [apiKey, setApiKey] = useState(null);
    const [google, setGoogle] = useState();

    const mapPoints = useRef();
    const markers = useRef();

    const [markerList2, setMarkerList2] = useState([]);
    const markers2 = useRef();

    const selectRowMarker = useRef();
    const selectMarker = useRef();
    const locationMarker = useRef();

    const routes = useRef();
    const routesMarkers = useRef();

    // reload検知
    const reloadRef = useRef(false);

    // camera起動
    const inputFileRef = useRef();

    const [multiItem, setMultiItem] = useState();
    const [currentButtomId, setCurrentButtomId] = useState(-1);

    // filter関係制御
    const [mapSearchText, setMapSearchText] = useState();
    const [kaishuSearchParam, setKaishuSearchParam] = useState({ on: !isTeiki && !isHikitori, ...InitKaishuParam });
    const [tonyaSearchParam, setTonyaSearchParam] = useState({ on: true, ...InitTonyaParam });
    const [depoSearchParam, setDepoSearchParam] = useState({ on: !isHikitori });
    const [teikiSearchParam, setTeikiSearchParam] = useState({ on: !isHikitori, ...InitTeikiParam });

    const [isOpenKaishuIrai, setOpenKaishuIrai] = useState(false);
    const [isOpenTonya, setOpenTonya] = useState(false);
    const [isOpenTeiki, setOpenTeiki] = useState(false);

    const getGeolocation = useCallback(() => {
      if (!google || !isIpad) {
        return;
      }
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            const {
              coords: { latitude, longitude },
            } = position;
            const point = { lat: latitude, lng: longitude };

            setCenter(point);
            setCurrent(point);

            locationMarker.current?.setMap(null);
            locationMarker.current = new google.maps.Marker({
              map: google.map,
              position: point,
              zIndex: GEOLOCATION_INDEX,
            });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }, [google, isIpad]);

    // grid検索リスト
    const memoListForMap = useMemo(() => {
      if (!kaishuSearchParam.on) {
        return [];
      }
      const memos = markerList.reduce((memo, row) => {
        const latLng = row?.lat + "-" + row?.lng;
        if (latLng) {
          memo[[latLng]] = {
            ...row,
            ...memo[[latLng]],
            items: [...(memo[[latLng]]?.items ?? []), { ...row }],
          };
        }
        return memo;
      }, {});

      return Object.entries(memos).map(([, v]) => ({ ...v }));
    }, [markerList, kaishuSearchParam.on]);

    // map検索リスト(grid検索リスト除外)
    const memoListForMap2 = useMemo(() => {
      const types = [];
      [kaishuSearchParam.on, tonyaSearchParam.on, depoSearchParam.on, teikiSearchParam.on].forEach((v, i) => {
        if (v) {
          types.push(i === 0 ? "kaishuirai" : i === 1 ? "tonya" : i === 2 ? "depo" : "teikikaishu");
        }
      });

      let tmpList = [...markerList2].filter((x) => types.includes(x.type));

      // gridデータを除外(リロードボタン時は除外なし)
      if (reloadRef.current) {
      } else {
        if (!!markerList && markerList.length > 0) {
          const gridIds = markerList.map((x) => x.id);
          tmpList = tmpList.filter((x) => !gridIds.includes(x.id));
        }
      }

      const memos = tmpList.reduce((memo, row) => {
        const latLng = row?.lat + "-" + row?.lng;
        if (latLng) {
          memo[[latLng]] = {
            ...row,
            ...memo[[latLng]],
            items: [...(memo[[latLng]]?.items ?? []), { ...row }],
          };
        }
        return memo;
      }, {});

      return Object.entries(memos).map(([, v]) => ({ ...v }));
    }, [markerList, markerList2, kaishuSearchParam.on, tonyaSearchParam.on, depoSearchParam.on, teikiSearchParam.on]);

    const buttomList = useMemo(() => {
      if (reloadRef.current) {
        return memoListForMap2;
      } else {
        return [...memoListForMap, ...memoListForMap2];
      }
    }, [memoListForMap, memoListForMap2]);

    const getMarkerSuffix = useCallback((item) => {
      let suffix = "";
      if ("binId" in item && item.binId > 0) {
        suffix = "-check";
      }
      if (item.type === "tonya" && item.hikitoriNotify === "1") {
        suffix = "2";
      }
      return suffix;
    }, []);

    const onGoogleApiLoaded = useCallback((obj) => {
      // map, maps
      setGoogle(obj);
    }, []);

    // POST /api/map
    const onMapSearch2 = useCallback(
      async (dataKbn) => {
        if (!google) return;

        const center = google.map.getCenter();
        const zoomLevel = google.map.getZoom();
        // words検索は全国取得 dataKbn === null
        // リロード時はdistance調整
        let distance = dataKbn === null ? "10000000" : "100000";
        if (reloadRef.current) {
          distance = zoomLevel >= 15 ? "10000" : zoomLevel >= 10 ? "50000" : "100000";
        }

        const f = {
          lat: center.lat().toString(),
          lng: center.lng().toString(),
          distance: dataKbn === null ? "10000000" : distance,
          words: mapSearchText,
          irai: InitKaishuParam,
          tonya: InitTonyaParam,
          depo: true,
          teiki: InitTeikiParam,
        };
        if (dataKbn === "kaishu-irai" || kaishuSearchParam.on) {
          let kaishuKiboDates = [];
          let kaishuKiboDateAfter = "";
          if (kaishuSearchParam.oneDayAfter) {
            kaishuKiboDates.push(oneDayAfter);
          }
          if (kaishuSearchParam.twoDayAfter) {
            kaishuKiboDates.push(twoDayAfter);
          }
          if (kaishuSearchParam.threeDayAfter) {
            kaishuKiboDates.push(threeDayAfter);
          }
          if (kaishuSearchParam.kaishuKiboDateAfter) {
            kaishuKiboDateAfter = fourAfter;
          }
          f.irai = { ...kaishuSearchParam, kaishuKiboDates, kaishuKiboDateAfter };
        }
        if (dataKbn === "tonya" || tonyaSearchParam.on) {
          f.tonya = tonyaSearchParam;
        }
        // if (dataKbn === "depo" && depoSearchParam.on) {
        //   f.depo = false;
        // }
        if (dataKbn === "teiki" || teikiSearchParam.on) {
          f.teiki = teikiSearchParam;
        }
        console.info("MapSearchForm", f);

        try {
          const res = await axios.post("/api/map", f);
          if (res && res.data) {
            const list = res.data;
            onMapCenter(list[0]);
            setMarkerList2(list);
            await actionLog("配車一覧 マップ検索", "SUCCESS", null);
          }
        } catch (error) {
          console.warn("api/map", error.message);
          await actionLog("配車一覧 マップ検索", "ERROR", error.toString());
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mapSearchText, kaishuSearchParam, teikiSearchParam, tonyaSearchParam, google]
    );

    const onHandleMapSearch2 = useCallback(
      async (e, dataKbn) => {
        if (!!e) {
          e.preventDefault();
        }
        await onMapSearch2(dataKbn);
        setOpenKaishuIrai(false);
        setOpenTonya(false);
        setOpenTeiki(false);
        if (dataKbn === "kaishu-irai") {
          setKaishuSearchParam((pre) => ({ ...pre, on: true }));
        }
        if (dataKbn === "tonya") {
          setTonyaSearchParam((pre) => ({ ...pre, on: true }));
        }
        // if (dataKbn === "depo") {
        //   setDepoSearchParam((prev) => ({ ...prev, on: !prev.on }));
        // }
        if (dataKbn === "teiki") {
          setTeikiSearchParam((pre) => ({ ...pre, on: true }));
        }
      },
      [onMapSearch2]
    );

    const onCreateSelectMarker = useCallback(
      (item) => {
        selectMarker.current?.setMap(null);
        selectMarker.current = new google.maps.Marker({
          map: google.map,
          position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
          icon: "/assets/admin/Images/pin/" + item.type + getMarkerSuffix(item) + "-select.png",
          zIndex: SELECT_INDEX,
        });
        selectMarker.current.addListener("click", async (e) => {
          if (!!item && item.items?.length > 1) {
            setMultiItem(item);
            onCreateSelectMarker(item);
            mapPoints?.current?.scrollIntoView(true);
            return false;
          } else {
            setMultiItem(null);
          }

          const detail = await getKohyoData(item, meishos);
          onMarkerClick(item, detail);
        });
      },
      [google, onMarkerClick, meishos, getMarkerSuffix]
    );

    const onHandleMultiItemClick = useCallback(
      (item) => {
        setMultiItem(item);
        onCreateSelectMarker(item);
        mapPoints?.current?.scrollIntoView(true);
      },
      [mapPoints, onCreateSelectMarker]
    );

    const onHandleMarkerClick = useCallback(
      async (e, item) => {
        if (!!e) {
          e.preventDefault();
        }
        setMultiItem(null);
        // const center = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng));
        // google.map.setCenter(center);

        if (!!onMarkerClick) {
          selectRowMarker.current?.setMap(null);
          onCreateSelectMarker(item);
          const detail = await getKohyoData(item, meishos);
          onMarkerClick(item, detail);
        }
      },
      [onMarkerClick, onCreateSelectMarker, meishos]
    );

    const onMapReload = useCallback(
      (e) => {
        if (!!e) {
          e.preventDefault();
        }
        reloadRef.current = true;
        selectRowMarker.current?.setMap(null);
        selectMarker.current?.setMap(null);
        setMultiItem(null);

        // ipad用
        getGeolocation();

        onMapSearch2("");
      },
      [onMapSearch2, getGeolocation]
    );

    const onMapCenter = useCallback(
      (item) => {
        if (google && !!item) {
          const center = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng));
          google.map.setCenter(center);
        }
        if (google && keiroList && keiroList.length) {
          const first = [...keiroList].shift();
          const center = new google.maps.LatLng(parseFloat(first.lat), parseFloat(first.lng));
          google.map.setCenter(center);
        }
      },
      [google, keiroList]
    );

    const drawRoute = useCallback(async () => {
      // 経路+マーカークリア
      if (routes.current?.length > 0) {
        routes.current.forEach((poliline) => {
          poliline.setMap(null);
        });
      }
      if (routesMarkers.current?.length > 0) {
        routesMarkers.current.forEach((marker) => {
          marker.setMap(null);
        });
      }

      if (!google || (keiroList || []).length < 2) {
        setHaishaInfo((prev) => ({ ...prev, totalKyori: 0 }));
        return;
      }
      const f = [...Array(keiroList.length - 1).keys()].map((index) => {
        return { jusho: keiroList[index].jusho ?? "", toJusho: keiroList[index + 1].jusho ?? "" };
      });

      const res = await axios.post("/api/keiro", f);
      if (res && res.data) {
        const list = res.data;
        // 経路
        const tRoutes = [];
        list.forEach((v) => {
          // let steps = [];
          // try {
          //   steps = JSON.parse(v.path);
          // } catch (error) {
          //   console.warn(error);
          // }
          // if (steps && steps.length) {
          //   for (let i = 0; i < steps.length; i++) {
          //     const { polylinePoints } = steps[i];
          //     console.log("step", steps[i]);
          //     const paths = decodePolyline(polylinePoints);
          //     let line = new google.maps.Polyline({
          //       path: paths,
          //       strokeColor: "#F58322",
          //       strokeWeight: 5,
          //       map: google.map,
          //     });
          //     tRoutes.push(line);
          //   }
          // }
          try {
            const paths = decodePolyline(v.path);
            let line = new google.maps.Polyline({
              path: paths,
              strokeColor: "#F58322",
              strokeWeight: 5,
              map: google.map,
            });
            tRoutes.push(line);
          } catch (error) {
            console.warn("draw keiro", error);
          }
        });
        routes.current = !!routes.current ? [...routes.current, ...tRoutes] : tRoutes;

        // 地点マーカー
        const tMarkers = [];
        keiroList?.forEach((point, index) => {
          if (point.lat && point.lng) {
            const icon =
              (point.type === "depo"
                ? "depo-goal"
                : point.type + getMarkerSuffix(point) + "-" + (index + 1).toString()) + ".png";
            const rMarker = new google.maps.Marker({
              map: google.map,
              position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
              icon: "/assets/admin/Images/pin/" + icon,
              zIndex: KEIRO_INDEX,
            });
            rMarker.addListener("click", async (e) => {
              const detail = await getKohyoData(point, meishos);
              onMarkerClick(point, detail);
            });
            tMarkers.push(rMarker);
          }
        });
        routesMarkers.current = !!routesMarkers.current ? [...routesMarkers.current, ...tMarkers] : tMarkers;

        // 経路情報(距離)
        const totalKyori = list.reduce((t, c) => t + c.kyori, 0);
        setHaishaInfo((prev) => ({ ...prev, totalKyori }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [google, keiroList]);

    const onFileSelect = useCallback((e) => {
      e.preventDefault();
      inputFileRef.current?.click();
    }, []);

    const onFileChange = useCallback(
      async (e) => {
        const files = e.target.files;
        const geocoder = google?.maps?.Geocoder;
        const { lat, lng } = current;
        const latlng = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
        const result = await geocoder.prototype.geocode({ location: latlng });
        let jusho = result?.results[0].formatted_address;
        jusho = (jusho ?? "").replace("日本、", "").replace(/〒\d{3}-\d{4}/, "");

        const param = { files, latlng, jusho };
        onPreIraiIpad(param);
      },
      [google, current, onPreIraiIpad]
    );

    useEffect(() => {
      // gridクリック=>センター変更+周りデータ取得
      const initSelectRow = () => {
        // リロード消し
        reloadRef.current = false;
        if (google && selectRow) {
          onMapCenter(selectRow);
          const marker = new google.maps.Marker({
            map: google.map,
            position: {
              lat: parseFloat(selectRow.lat),
              lng: parseFloat(selectRow.lng),
            },
            icon: "/assets/admin/Images/pin/kaishuirai-select.png",
            zIndex: SELECT_INDEX,
            // animation: google.maps.Animation.DROP,
          });
          marker.addListener("click", async (e) => {
            if (!!onMarkerClick) {
              const detail = await getKohyoData(selectRow, meishos);
              onMarkerClick(selectRow, detail);
            }
          });
          setTimeout(async () => {
            await onMapSearch2();
          }, 0);

          selectRowMarker.current?.setMap(null);
          selectMarker.current?.setMap(null);
          selectRowMarker.current = marker;
        }
      };

      initSelectRow();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [google, selectRow]);

    useEffect(() => {
      const newMarkers = [];

      if (google) {
        if (markers.current?.length > 0) {
          markers.current.forEach((marker) => {
            marker.setMap(null);
          });
        }
        if (memoListForMap && memoListForMap.length) {
          memoListForMap.forEach((item) => {
            if (!!!item.lat || !!!item.lng) return;
            const newMarker = new google.maps.Marker({
              map: google.map,
              position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
              icon: "/assets/admin/Images/pin/kaishuirai.png",
              zIndex: GRID_INDEX,
              // animation: google.maps.Animation.DROP,
            });
            newMarker.addListener("click", (e) => {
              if (!!item && item.items?.length > 1) {
                onHandleMultiItemClick(item);
                return false;
              }
              setMultiItem(null);
              onHandleMarkerClick(null, item);
            });
            newMarkers.push(newMarker);
          });

          markers.current = newMarkers;
        }
      }
    }, [google, memoListForMap, onHandleMarkerClick, onCreateSelectMarker, onHandleMultiItemClick]);

    useEffect(() => {
      const newMarkers = [];

      if (google) {
        if (markers2.current?.length > 0) {
          markers2.current.forEach((marker) => {
            marker.setMap(null);
          });
        }
        if (memoListForMap2 && memoListForMap2.length) {
          memoListForMap2.forEach((item) => {
            if (!!!item.lat || !!!item.lng) return;

            const newMarker = new google.maps.Marker({
              map: google.map,
              position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
              icon: "/assets/admin/Images/pin/" + item.type + getMarkerSuffix(item) + ".png",
              zIndex: NO_SELECT_INDEX,
            });
            newMarker.addListener("click", () => {
              if (!!item && item.items?.length > 1) {
                onHandleMultiItemClick(item);
                return false;
              }
              setMultiItem(null);
              onHandleMarkerClick(null, item);
            });
            newMarkers.push(newMarker);
          });
        }
        markers2.current = newMarkers;
      }
    }, [google, memoListForMap2, onHandleMarkerClick, onCreateSelectMarker, onHandleMultiItemClick, getMarkerSuffix]);

    useEffect(() => {
      if (!isViewKohyoLeftUp) {
        selectMarker.current?.setMap(null);
        selectRowMarker.current?.setMap(null);
      }
    }, [isViewKohyoLeftUp]);

    useEffect(() => {
      // ルート表示
      drawRoute();
    }, [drawRoute]);

    // useEffect(() => {
    //   if (google) {
    //     google.map.addListener("center_changed", () => {
    //       // console.log('')
    //     });
    //   }
    // }, [google]);

    useLayoutEffect(() => {
      setApiKey(me?.googleApiKey || "");

      if (!!isIpad && !!window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }) => {
            setCenter({ lat: latitude, lng: longitude });
          },
          (error) => console.error({ error })
        );
      }
    }, [me, isIpad]);

    // 親コンポーネント用
    useImperativeHandle(
      mapRef,
      () => ({
        isGoogleAvailable: () => !!google,
        reload: onMapReload,
        changeCenter: onMapCenter,
        getGeolocation,
      }),
      [google, onMapReload, onMapCenter, getGeolocation]
    );

    return (
      <div className={`map ${!!isIpad && "--ipad"} ${!!isHikitori && "--trancom"} ${!!isViewKohyoLeftUp && "isOpen"}`}>
        <div className="map__body">
          {apiKey && (
            <GoogleMap
              bootstrapURLKeys={{ key: apiKey }}
              center={center}
              zoom={zoom}
              onGoogleApiLoaded={onGoogleApiLoaded}
            ></GoogleMap>
          )}
          <div className={`map-filter ${!!isIpad && "--ipad"}  ${!!isHikitori && "--ipad"}`}>
            <div className="map-filter__search">
              <input
                type="text"
                className="w--240 ico --search --s --offset"
                placeholder="マップで検索する"
                value={mapSearchText}
                onChange={(e) => setMapSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && mapSearchText) {
                    onMapSearch2(null);
                  }
                }}
              />
            </div>
            <div className="map-filter__spot">
              <span>表示</span>
              {!isHikitori && (
                <a
                  href="#"
                  className={`--kaishuirai ${kaishuSearchParam.on && "--selected"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setKaishuSearchParam((p) => ({ ...p, on: !p.on }));
                  }}
                  style={{ pointerEvents: isTeiki ? "none" : "auto" }}
                >
                  <span>回収依頼</span>
                  <span
                    className="showFilter"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenKaishuIrai(true);
                      setOpenTonya(false);
                      setOpenTeiki(false);
                    }}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.5"
                        d="M8.00033 4C8.73366 4 9.33366 3.4 9.33366 2.66667C9.33366 1.93334 8.73366 1.33334 8.00033 1.33334C7.26699 1.33334 6.66699 1.93334 6.66699 2.66667C6.66699 3.4 7.26699 4 8.00033 4ZM8.00033 6.66667C7.26699 6.66667 6.66699 7.26667 6.66699 8C6.66699 8.73334 7.26699 9.33334 8.00033 9.33334C8.73366 9.33334 9.33366 8.73334 9.33366 8C9.33366 7.26667 8.73366 6.66667 8.00033 6.66667ZM8.00033 12C7.26699 12 6.66699 12.6 6.66699 13.3333C6.66699 14.0667 7.26699 14.6667 8.00033 14.6667C8.73366 14.6667 9.33366 14.0667 9.33366 13.3333C9.33366 12.6 8.73366 12 8.00033 12Z"
                        fill="#888888"
                      ></path>
                    </svg>
                  </span>
                </a>
              )}
              <a
                href="#"
                className={`--tonya ${tonyaSearchParam.on && "--selected"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setTonyaSearchParam((p) => ({ ...p, on: !p.on }));
                }}
              >
                <span>回収店</span>
                <span
                  className="showFilter"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenKaishuIrai(false);
                    setOpenTonya(true);
                    setOpenTeiki(false);
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.5"
                      d="M8.00033 4C8.73366 4 9.33366 3.4 9.33366 2.66667C9.33366 1.93334 8.73366 1.33334 8.00033 1.33334C7.26699 1.33334 6.66699 1.93334 6.66699 2.66667C6.66699 3.4 7.26699 4 8.00033 4ZM8.00033 6.66667C7.26699 6.66667 6.66699 7.26667 6.66699 8C6.66699 8.73334 7.26699 9.33334 8.00033 9.33334C8.73366 9.33334 9.33366 8.73334 9.33366 8C9.33366 7.26667 8.73366 6.66667 8.00033 6.66667ZM8.00033 12C7.26699 12 6.66699 12.6 6.66699 13.3333C6.66699 14.0667 7.26699 14.6667 8.00033 14.6667C8.73366 14.6667 9.33366 14.0667 9.33366 13.3333C9.33366 12.6 8.73366 12 8.00033 12Z"
                      fill="#888888"
                    ></path>
                  </svg>
                </span>
              </a>
              {!isHikitori && (
                <a
                  href="#"
                  className={`--depo ${depoSearchParam.on && "--selected"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    // onHandleMapSearch2(null, "depo");
                    setDepoSearchParam((p) => ({ ...p, on: !p.on }));
                  }}
                >
                  <span>デポ</span>
                </a>
              )}
              {!isHikitori && (
                <a
                  href="#"
                  className={`--teikikaishu ${teikiSearchParam.on && "--selected"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setTeikiSearchParam((p) => ({ ...p, on: !p.on }));
                  }}
                >
                  <span>定期回収</span>
                  <span
                    className="showFilter"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenKaishuIrai(false);
                      setOpenTonya(false);
                      setOpenTeiki(true);
                    }}
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.5"
                        d="M8.00033 4C8.73366 4 9.33366 3.4 9.33366 2.66667C9.33366 1.93334 8.73366 1.33334 8.00033 1.33334C7.26699 1.33334 6.66699 1.93334 6.66699 2.66667C6.66699 3.4 7.26699 4 8.00033 4ZM8.00033 6.66667C7.26699 6.66667 6.66699 7.26667 6.66699 8C6.66699 8.73334 7.26699 9.33334 8.00033 9.33334C8.73366 9.33334 9.33366 8.73334 9.33366 8C9.33366 7.26667 8.73366 6.66667 8.00033 6.66667ZM8.00033 12C7.26699 12 6.66699 12.6 6.66699 13.3333C6.66699 14.0667 7.26699 14.6667 8.00033 14.6667C8.73366 14.6667 9.33366 14.0667 9.33366 13.3333C9.33366 12.6 8.73366 12 8.00033 12Z"
                        fill="#888888"
                      ></path>
                    </svg>
                  </span>
                </a>
              )}
            </div>
          </div>

          <div className="map-filter-menu --kaishuirai" style={{ display: isOpenKaishuIrai ? "block" : "none" }}>
            <div className="map-filter-menu__header">
              <div>回収依頼</div>
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenKaishuIrai(false);
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                      fill="#888888"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="map-filter-menu__body">
              <div>
                <div>回収依頼枚数</div>
                <div>
                  <div className="map-filter-menu__range">
                    <div>
                      <multi-range></multi-range>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={kaishuSearchParam?.suryoFrom || ""}
                        onChange={(e) => {
                          const suryoFrom = e.target.value;
                          if (/^\d*$/.test(suryoFrom)) {
                            setKaishuSearchParam((p) => ({ ...p, suryoFrom }));
                          }
                        }}
                      />
                      &nbsp;枚&nbsp;～&nbsp;
                      <input
                        type="text"
                        value={kaishuSearchParam?.suryoTo || ""}
                        onChange={(e) => {
                          const suryoTo = e.target.value;
                          if (/^\d*$/.test(suryoTo)) {
                            setKaishuSearchParam((p) => ({ ...p, suryoTo }));
                          }
                        }}
                      />
                      &nbsp;枚
                      <label className="ml--20">
                        <input
                          type="checkbox"
                          className="--primary"
                          checked={kaishuSearchParam?.suryoNoLimitFlg}
                          onChange={(e) => {
                            setKaishuSearchParam((p) => ({
                              ...p,
                              suryoNoLimitFlg: e.target.checked,
                            }));
                          }}
                        />
                        <span></span>制限なし
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>回収希望日</div>
                <div>
                  <a
                    href="#"
                    className={`btn-s ${kaishuSearchParam.oneDayAfter && "--selected"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setKaishuSearchParam((p) => ({
                        ...p,
                        oneDayAfter: !p.oneDayAfter,
                      }));
                    }}
                  >{`明日 ${formatDate(oneDayAfter, "DD(ddd)")}`}</a>
                  <a
                    href="#"
                    className={`btn-s ${kaishuSearchParam.twoDayAfter && "--selected"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setKaishuSearchParam((p) => ({
                        ...p,
                        twoDayAfter: !p.twoDayAfter,
                      }));
                    }}
                  >
                    {`${formatDate(twoDayAfter, "DD(ddd)")}`}
                  </a>
                  <a
                    href="#"
                    className={`btn-s ${kaishuSearchParam.threeDayAfter && "--selected"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setKaishuSearchParam((p) => ({
                        ...p,
                        threeDayAfter: !p.threeDayAfter,
                      }));
                    }}
                  >
                    {`${formatDate(threeDayAfter, "DD(ddd)")}`}
                  </a>
                  <a
                    href="#"
                    className={`btn-s ${kaishuSearchParam.kaishuKiboDateAfter && "--selected"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setKaishuSearchParam((p) => ({
                        ...p,
                        kaishuKiboDateAfter: !p.kaishuKiboDateAfter,
                      }));
                    }}
                  >
                    それ以降
                  </a>
                </div>
              </div>
              <div>
                <div>在庫限度率</div>
                <div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={kaishuSearchParam?.zaikoGendo80PerFlg === "1" ? true : false}
                        onChange={(e) => {
                          setKaishuSearchParam((p) => {
                            const zaikoGendo80PerFlg = e.target.checked ? "1" : "0";
                            return { ...p, zaikoGendo80PerFlg };
                          });
                        }}
                      />
                      <span></span>80％以上のみ表示する
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>配車状況</div>
                <div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={kaishuSearchParam?.preHaishaFlg === "1" ? true : false}
                        onChange={(e) => {
                          setKaishuSearchParam((p) => {
                            const preHaishaFlg = e.target.checked ? "1" : "0";
                            return { ...p, preHaishaFlg };
                          });
                        }}
                      />
                      <span></span>配車前のみ表示
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-filter-menu__footer">
              <div>
                <a href="#" className="btn --primary" onClick={(e) => onHandleMapSearch2(e, "kaishu-irai")}>
                  <span>この条件で表示</span>
                </a>
                <a
                  href="#"
                  className="link --primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setKaishuSearchParam({ on: true, ...InitKaishuParam });
                  }}
                >
                  条件クリア
                </a>
              </div>
            </div>
          </div>

          <div className="map-filter-menu --tonya" style={{ display: isOpenTonya ? "block" : "none" }}>
            <div className="map-filter-menu__header">
              <div>回収店</div>
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTonya(false);
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                      fill="#888888"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="map-filter-menu__body">
              <div>
                <div>在庫枚数</div>
                <div className="half">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.zaikoSuryo100Kbn === "1" ? true : false}
                        onChange={(e) => {
                          const zaikoSuryo100Kbn = e.target.checked ? "1" : "0";
                          setTonyaSearchParam((pre) => ({ ...pre, zaikoSuryo100Kbn }));
                        }}
                      />
                      <span></span>100枚以上
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.zaikoSuryo100Kbn === "0" ? true : false}
                        onChange={(e) => {
                          const zaikoSuryo100Kbn = e.target.checked ? "0" : "1";
                          setTonyaSearchParam((pre) => ({ ...pre, zaikoSuryo100Kbn }));
                        }}
                      />
                      <span></span>すべて
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>前回回収から</div>
                <div className="half">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.zenkaiKaishuTukiKbn === "1" ? true : false}
                        onChange={(e) => {
                          const zenkaiKaishuTukiKbn = e.target.checked ? "1" : "0";
                          setTonyaSearchParam((pre) => ({ ...pre, zenkaiKaishuTukiKbn }));
                        }}
                      />
                      <span></span>1ヶ月以上経過
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.zenkaiKaishuTukiKbn === "0" ? true : false}
                        onChange={(e) => {
                          const zenkaiKaishuTukiKbn = e.target.checked ? "0" : "1";
                          setTonyaSearchParam((pre) => ({ ...pre, zenkaiKaishuTukiKbn }));
                        }}
                      />
                      <span></span>すべて
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>出荷数</div>
                <div className="half">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.shukkaTuki5Kbn === "1" ? true : false}
                        onChange={(e) => {
                          const shukkaTuki5Kbn = e.target.checked ? "1" : "0";
                          setTonyaSearchParam((pre) => ({ ...pre, shukkaTuki5Kbn }));
                        }}
                      />
                      <span></span>月に5回以上
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.shukkaTuki5Kbn === "0" ? true : false}
                        onChange={(e) => {
                          const shukkaTuki5Kbn = e.target.checked ? "0" : "1";
                          setTonyaSearchParam((pre) => ({ ...pre, shukkaTuki5Kbn }));
                        }}
                      />
                      <span></span>すべて
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>回収依頼状況</div>
                <div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={tonyaSearchParam?.kaishuIraiNoneFlg === "1" ? true : false}
                        onChange={(e) => {
                          const kaishuIraiNoneFlg = e.target.checked ? "1" : "0";
                          setTonyaSearchParam((pre) => ({ ...pre, kaishuIraiNoneFlg }));
                        }}
                      />
                      <span></span>回収依頼中は非表示
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-filter-menu__footer">
              <div>
                <a href="#" className="btn --primary" onClick={(e) => onHandleMapSearch2(e, "tonya")}>
                  <span>この条件で表示</span>
                </a>
                <a
                  href="#"
                  className="link --primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setTonyaSearchParam({ on: true, ...InitTonyaParam });
                  }}
                >
                  条件クリア
                </a>
              </div>
            </div>
          </div>

          <div className="map-filter-menu --teikikaishu" style={{ display: isOpenTeiki ? "block" : "none" }}>
            <div className="map-filter-menu__header">
              <div>定期回収</div>
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTeiki(false);
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                      fill="#888888"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="map-filter-menu__body">
              <div>
                <div>回収曜日</div>
                <div className="space-between">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.mondayFlg === "1"}
                        onClick={(e) => {
                          const mondayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, mondayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.tuesdayFlg === "1"}
                        onClick={(e) => {
                          const tuesdayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, tuesdayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.wednesdayFlg === "1"}
                        onClick={(e) => {
                          const wednesdayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, wednesdayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.thursdayFlg === "1"}
                        onClick={(e) => {
                          const thursdayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, thursdayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.fridayFlg === "1"}
                        onClick={(e) => {
                          const fridayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, fridayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.saturdayFlg === "1"}
                        onClick={(e) => {
                          const saturdayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, saturdayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="--primary"
                        checked={teikiSearchParam?.sundayFlg === "1"}
                        onClick={(e) => {
                          const sundayFlg = e.target.checked ? "1" : "0";
                          setTeikiSearchParam((pre) => ({ ...pre, sundayFlg }));
                        }}
                      />
                      <span></span>
                    </label>
                  </div>
                  <div>
                    <span>月</span>
                    <span>火</span>
                    <span>水</span>
                    <span>木</span>
                    <span>金</span>
                    <span>土</span>
                    <span>日</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-filter-menu__footer">
              <div>
                <a href="#" className="btn --primary" onClick={(e) => onHandleMapSearch2(e, "teiki")}>
                  <span>この条件で表示</span>
                </a>
                <a
                  href="#"
                  className="link --primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setTeikiSearchParam({ on: true, ...InitTeikiParam });
                  }}
                >
                  条件クリア
                </a>
              </div>
            </div>
          </div>

          <div id="map-reload" className="map-reload">
            <a onClick={onMapReload} href="#"></a>
          </div>
          {isIpad && (
            <div id="map-camera" className="map-camera">
              <a onClick={onFileSelect} href="#"></a>
              <input
                ref={inputFileRef}
                type="file"
                hidden
                accept="image/*"
                capture="environment"
                onChange={onFileChange}
              />
            </div>
          )}
        </div>

        <div
          className="map__footer"
          style={{ display: !!memoListForMap?.length || !!memoListForMap2?.length ? "flex" : "none" }}
        >
          <div ref={mapPoints} className="map-points">
            {/* マーカー押下時複数items */}
            {multiItem?.items?.map((bottomItem) => (
              <ButtomCard
                key={bottomItem.id}
                single
                {...{
                  bottomItem,
                  currentButtomId,
                  setCurrentButtomId,
                  onMapCenter,
                  onHandleMarkerClick,
                  onHandleMultiItemClick,
                  setMultiItem,
                }}
              />
            ))}
            {buttomList?.map((bottomItem, index) => (
              <ButtomCard
                key={index.toString() + bottomItem.id}
                {...{
                  bottomItem,
                  currentButtomId,
                  setCurrentButtomId,
                  onMapCenter,
                  onHandleMarkerClick,
                  onHandleMultiItemClick,
                  setMultiItem,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default memo(Map);
