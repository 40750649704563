import React, { useContext, useEffect, useCallback, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../../AppContext";
import { routes } from "../../AdminRoutes";

const Menu = (props) => {
  const { login, isMenuOpen, menuOpenController } = props;
  const { me } = useContext(AppContext);
  const history = useHistory();
  const menus = routes.filter((route) => "funcKeyCode" in route);

  return (
    <>
      <div className={`menu ${isMenuOpen && "--current"}`}>
        <div className="menu__menu">
          {menus.map(({ path, funcName, title }) => (
            <Link
              key={path}
              to={"/admin" + path}
              className={
                history.location.pathname === "/admin" + path && "--current"
              }
            >
              <span>
                <span>{funcName}</span>
                <span>{title}</span>
              </span>
            </Link>
          ))}
        </div>
        <div className="menu__close">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              menuOpenController.off();
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="56" height="56" fill="url(#paint0_linear)"></rect>
              <path
                d="M38.7807 14.5L28 25.2807L17.2193 14.5L14.5 17.2193L25.2807 28L14.5 38.7807L17.2193 41.5L28 30.7193L38.7807 41.5L41.5 38.7807L30.7193 28L41.5 17.2193L38.7807 14.5Z"
                fill="white"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="0"
                  y1="0"
                  x2="56"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F55807"></stop>
                  <stop offset="1" stopColor="#FF953A"></stop>
                </linearGradient>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default memo(Menu);
