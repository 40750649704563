import React, { memo, useCallback } from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { weekdaysShort } from "dayjs/locale/ja";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

const Navbar = ({ nextMonth, previousMonth, onPreviousClick, onNextClick, month }) => {
  const buttonStyle = {
    color: "#888888",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "12px",
    fontWeight: "bold",
    cursor: "pointer",
  };
  const monthStyle = {
    flexGrow: 1,
    color: "#888888",
    fontSize: "12px",
    fontWeight: "bold",
    paddingLeft: "24px",
    paddingRight: "24px",
    flexWrap: "none",
    textAlign: "center",
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
        paddingTop: 8,
      }}
    >
      <button style={buttonStyle} onClick={() => onPreviousClick()}>
        {"〈"}
      </button>
      <span style={monthStyle}>{dayjs(month).format("YYYY年M月")}</span>
      <button style={buttonStyle} onClick={() => onNextClick()}>
        {"〉"}
      </button>
    </div>
  );
};

export const DayPicker = memo((props) => {
  const { hasArrow = false, day, setDay, tabIndex, keyDownRegister } = props;

  let register = null;
  if (tabIndex && keyDownRegister) {
    register = { ...keyDownRegister(tabIndex) };
  }

  const onArrowMinus = useCallback(
    (e) => {
      e.preventDefault();
      if (!day || !DateUtils.isDate(day)) {
        setDay(new Date());
      }
      setDay(dayjs(day).add(-1, "day").toDate());
    },
    [day, setDay]
  );

  const onArrowPlus = useCallback(
    (e) => {
      e.preventDefault();
      if (!day || !DateUtils.isDate(day)) {
        setDay(new Date());
      }
      setDay(dayjs(day).add(1, "day").toDate());
    },
    [day, setDay]
  );

  return (
    <>
      {hasArrow && (
        <a href="#" className="arrow-minus" onClick={onArrowMinus}>
          <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="8" fill="#279E9E"></rect>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.9968 21.3334L12.6635 16L17.9968 10.6667L19.3335 12L15.3302 16L19.3335 20L17.9968 21.3334Z"
              fill="white"
            ></path>
          </svg>
        </a>
      )}
      <DayPickerInput
        dayPickerProps={{
          className: "SelectDay",
          firstDayOfWeek: 1,
          weekdaysShort: weekdaysShort,
          navbarElement: Navbar,
          captionElement: () => null,
          showOutsideDays: true,
        }}
        format="YYYY/MM/DD"
        parseDate={(str, format) => {
          const parsed = dayjs(str).format(format);
          if (DateUtils.isDate(parsed)) {
            return parsed;
          }
          return undefined;
        }}
        formatDate={(date, format) => {
          return dayjs(date).format(format);
        }}
        component={(props) => (
          <input type="text" className="datepicker w--110 mr--5 ml--5 hasDatepicker" {...props} {...register} />
        )}
        // inputProps={{ }}
        placeholder=""
        value={day}
        onDayChange={(day) => setDay(day)}
      />
      {hasArrow && (
        <a href="#" className="arrow-plus" onClick={onArrowPlus}>
          <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
              fill="#279E9E"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.0032 10.6666L19.3365 16L14.0032 21.3333L12.6665 20L16.6698 16L12.6665 12L14.0032 10.6666Z"
              fill="white"
            ></path>
          </svg>
        </a>
      )}
      <Helmet>
        <style>{`
            .DayPicker-wrapper{
              border-style: none;
              outline: none;
            }
            .SelectDay {
              padding-top: 28px;
            }
            .SelectDay .DayPicker-Weekdays {
              padding-top: 24px;
              font-size: 12px;
            }
            .SelectDay .DayPicker-WeekdaysRow {
              margin-top: 24px;
            }
            .SelectDay .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
              background-color: #E9F5F5 !important;
              color: #000;
            }
            .SelectDay .DayPicker-Day {
              font-size: 1.6rem;
              border-radius: 0px;
              padding: 10px;
              margin: 10px;
              padding-left: 13px;
              padding-right: 13px;
              border-style: none;
              border-radius: 3px;
              outline: none;
            }
            .SelectDay .DayPicker-Day--today {
              color: #279E9E
            }
            .SelectDay .DayPicker-Day--start {
              color: #000 !important;
              background-color: #E9F5F5 !important;
              border-radius: 10% 0% 0% 10% !important;
            }
            .SelectDay .DayPicker-Day--end {
              background-color: #279E9E !important;
              border-radius: 0% 10% 10% 0% !important;
            }
            .SelectDay > .DayPicker-Day--outside .DayPicker-Day--disabled {
              color: #DCDCDC
            }
          `}</style>
      </Helmet>
    </>
  );
});
