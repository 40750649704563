import React, { useContext, useMemo, useState } from "react";

import { AppContext } from "../AppContext";
import { AdminAppContext } from "../AdminAppContext";
import { getMeishoName, getMeishos, getTeikikaishuLabel, KIBOTIME_KBN } from "../libs/common";
import { formatDate } from "../libs/date";

export default function ({ isIpad = false, isOpen = false, setOpen, item = {}, onDelete }) {
  const { meishos } = useContext(AppContext);
  const { isViewBinShosaiPane } = useContext(AdminAppContext);

  const youbiList = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);
  const timeKbnList = useMemo(() => getMeishos(meishos, "TONYA_TIME_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);

  const [teikiPaneTab, setTeikiPaneTab] = useState(0);

  const displayKiboTime = useMemo(() => {
    if (item?.user?.kaishuTimeKbn === KIBOTIME_KBN.SONOTA) {
      return item.user.kaishuTimeBiko;
    }
    if (item?.user?.kaishuTimeFrom === "" && item?.user?.kaishuTimeTo === "") {
      return "希望なし";
    }
    return getMeishoName(timeKbnList, item?.user?.kaishuTimeKbn);
  }, [item, timeKbnList]);

  if (!isOpen) {
    return null;
  }

  const mainClass = isIpad ? "pane pane--ipad pane--teikikaishu-ipad" : "pane pane--left-up pane--teikikaishu-left-up";

  return (
    <div className={`${mainClass} ${isOpen && "isOpen"}`}>
      <div className="pane__header">
        <div className="hdg">
          <div className="hdg__text --teikikaishu">定期回収</div>
          <a
            href="javascript:void(0);"
            className="hdg__close paneClose mapClose"
            rel="teikikaishu-left-up"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                fill="#888888"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="pane__body">
        <div className="pane-layout">
          <div className="pane-layout__inner">
            <div className="title --border">
              <div className="title__title">{item?.name}</div>
              <div className="title__zaiko">
                現在の在庫<span>{item?.zaikoSuryo?.toLocaleString() || "－"}</span>枚
              </div>
            </div>
            <div className="collection-place">
              <div className="collection-place__text">
                <div className="ico --s --calendar">{`定期回収 [ ${getTeikikaishuLabel(
                  item?.teikis,
                  youbiList
                )} ]`}</div>
                <div className="ico --s --location">{item?.jusho}</div>
                <div className="ico --s --tel">{item?.tel}</div>
                <div className="ico --s --driver">{item?.tanto}</div>
              </div>
              <div className="collection-place__pic --noimage">
                {/* <a href="javascript:void(0);" className="jsShowPicture">
                  <img src="https://dummyimage.com/140x96/ccc/fff" alt="" />
                </a> */}
              </div>
            </div>
            <div className="note">
              <div>
                <div>
                  <span>回収可能曜日：</span>
                  <span>{getMeishoName(youbiList, item?.user?.kaishuYoubiKbns)}</span>
                </div>
                <div>
                  <span>回収可能時間：</span>
                  <span>{displayKiboTime}</span>
                </div>
              </div>
              <div>
                <div>
                  <span>保有限度枚数：</span>
                  <span>{item?.user?.hokanGendo?.toLocaleString() || "－"}枚</span>
                </div>
                <div hidden>
                  <span>荷役：</span>
                  <span>{getMeishoName(niyakuList, item?.user?.niyakuKbn)}</span>
                </div>
              </div>
              <div>
                <span>注意事項：</span>
                <span>{item?.user?.biko}</span>
              </div>
            </div>
            <div className="tab --teikikaishu">
              <div className="tab__header">
                <div className="tab__menu">
                  <a
                    href="javascript:void(0);"
                    className={teikiPaneTab === 0 && "--current"}
                    onClick={(e) => {
                      e.preventDefault();
                      setTeikiPaneTab(0);
                    }}
                  >
                    出荷元
                  </a>
                  <a
                    href="javascript:void(0);"
                    className={teikiPaneTab === 1 && "--current"}
                    onClick={(e) => {
                      e.preventDefault();
                      setTeikiPaneTab(1);
                    }}
                  >
                    最近の回収実績
                  </a>
                  <a
                    href="javascript:void(0);"
                    className={teikiPaneTab === 2 && "--current"}
                    onClick={(e) => {
                      e.preventDefault();
                      setTeikiPaneTab(2);
                    }}
                  >
                    入出庫履歴
                  </a>
                </div>
              </div>
              <div className="tab__body">
                <table
                  className="tab__table"
                  style={{
                    display: teikiPaneTab === 0 ? "table" : "none",
                  }}
                >
                  <colgroup>
                    <col style={{ width: "42%" }} />
                    <col style={{ width: "18%" }} />
                    <col style={{ width: "25%" }} />
                    <col className="" />
                  </colgroup>
                  <tr className="tab__th">
                    <th>会社名</th>
                    <th>商品</th>
                    <th>MC</th>
                    <th>PC</th>
                  </tr>
                  {item?.shukkamotos?.map((shukkamoto, index) => (
                    <tr key={index.toString()} className="tab__tr">
                      <td>{shukkamoto.tokuisakiName}</td>
                      <td>{shukkamoto.shohinName}</td>
                      <td className="ta--r">{shukkamoto.mc}</td>
                      <td className="ta--r">{shukkamoto.pc}</td>
                    </tr>
                  ))}
                </table>
                <table
                  className="tab__table"
                  style={{
                    display: teikiPaneTab === 1 ? "table" : "none",
                  }}
                >
                  <colgroup>
                    <col />
                    <col style={{ width: "47%" }} />
                    <col style={{ width: "18%" }} />
                    <col style={{ width: "18%" }} />
                  </colgroup>
                  <tr className="tab__th">
                    <th>回収日</th>
                    <th>運送会社</th>
                    <th>運賃単位</th>
                    <th>回収金額</th>
                  </tr>
                  {item?.kaishujissekis?.map((kaishujisseki, index) => (
                    <tr key={index.toString()} className="tab__tr">
                      <td>{formatDate(kaishujisseki.kaishuBi, "M/D(ddd)")}</td>
                      <td>{kaishujisseki.unsoName}</td>
                      <td>{kaishujisseki.jippiUnchinSuu.toLocaleString()}</td>
                      <td className="ta--r">{kaishujisseki.kaishuKingaku.toLocaleString()}円</td>
                    </tr>
                  ))}
                </table>
                <table
                  className="tab__table"
                  style={{
                    display: teikiPaneTab === 2 ? "table" : "none",
                  }}
                >
                  <colgroup>
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col className="" />
                  </colgroup>
                  <tr className="tab__th">
                    <th>日付</th>
                    <th>入庫</th>
                    <th>回収</th>
                    <th>在庫</th>
                  </tr>
                  {item?.nyushukkorirekis?.map((nyushukkorireki, index) => (
                    <tr key={index.toString()} className="tab__tr">
                      <td>{formatDate(nyushukkorireki.shoribi, "M/D(ddd)")}</td>
                      <td className="ta--r">{nyushukkorireki.nyukoSuu.toLocaleString()}</td>
                      <td className="ta--r">{nyushukkorireki.shukoSuu.toLocaleString()}</td>
                      <td className="ta--r">{nyushukkorireki.zaikoSuu.toLocaleString()}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pane__footer">
        {!isIpad && isViewBinShosaiPane && (
          <div className="btn-layout --right">
            <a href="#" className="btn --delete" onClick={(e) => onDelete(e, item)}>
              <span>経路から削除する</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
