import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "./AppContext";

/**
 * 要認証URL
 */
export const PrivateRoute = (props) => {
  const { me } = useContext(AppContext);
  if (me === null) {
    // 遷移元内包
    return <Redirect to={{ pathname: "/login", state: { from: props.location.pathname } }} />;
  }
  return <Route {...props} />;
};
