import React, { useCallback, useState } from "react";

export default function InfoModal(props) {
  const { isOpen, control, title, message, onOk, btnOkTitle, disableClose } = props;
  const [disable, setDisable] = useState(false);

  const onClose = useCallback((e) => {
    e.preventDefault();
    control.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickPrimary = useCallback(
    async (e) => {
      setDisable(true);
      try {
        if (!!onOk) {
          await onOk(e);
        } else {
          onClose(e);
        }
      } finally {
        setDisable(false);
      }
    },
    [onOk, onClose]
  );

  return (
    <>
      {isOpen && (
        <div className="overlay" style={{ display: "block", zIndex: 999999 }}>
          <div className="modal modal--error modal--not-match-password" style={{ display: "block" }}>
            <div className="modal__title">{title}</div>
            <div className="modal__detail">
              <div className="txt" dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br />") }} />
            </div>

            <div className={`btn-layout --col-${!!!disableClose ? 2 : 1}`}>
              {!!!disableClose && (
                <div>
                  <a
                    href="#"
                    className="btn --wht-primary"
                    onClick={onClose}
                    style={{ pointerEvents: disable ? "none" : "all" }}
                  >
                    <span>いいえ</span>
                  </a>
                </div>
              )}
              <div>
                <a
                  href="#"
                  className="btn --primary modalClose"
                  onClick={onClickPrimary}
                  style={{ pointerEvents: disable ? "none" : "all" }}
                >
                  <span>{btnOkTitle || "はい"}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
