import React, { useState, useContext, useRef, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../AppContext";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";
import Header from "./Header";
import InfoModal from "../modals/InfoModal";
import ErrorModal from "../modals/ErrorModal";
import { useBoolean } from "../hooks/useBoolean";
import { useKeyDown } from "../hooks/useKeyDown";

import {
  createErrorMessage,
  formatNumber,
  EDIT_USER_KBN,
  KIBOTIME_KBN,
  NIYAKU_KBN,
  NOTIFY_KBN,
  KIBOSHASHU_KBN,
  getMeishos,
  getTeikikaishuLabel,
} from "../libs/common";
import { weekdaysShort, weekdaysLong } from "../libs/date";
import { TextInput } from "../components/TextInput";
import { CheckBox } from "../components/CheckBox";

// 個人設定初期値
const initialKojin = {
  notify: NOTIFY_KBN.MAIL, // MAIL or TEL
};

export default function MyPage(props) {
  const { me = {}, meishos, teikis, loadMe, resetMe } = useContext(AppContext);
  const { kokyaku } = me;

  // 名称マスタ
  const ngShakakuList = useMemo(() => getMeishos(meishos, "SHAKAKU_KBN"), [meishos]);
  const kiboShashuList = useMemo(() => getMeishos(meishos, "TONYA_SHASHU_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);
  const youbis = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);
  const kaishuTimes = useMemo(() => getMeishos(meishos, "TONYA_TIME_KBN"), [meishos]);

  const history = useHistory();
  const keyDownRegister = useKeyDown();

  const inputFileRef = useRef();

  // 共通モーダル制御
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOkEvent = useRef(null);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Pane制御
  const [isPanePasswordhenko, setPanePasswordhenko] = useState(false);
  const [isPaneKaishuJokenHenko, setPaneKaishuJokenHenko] = useState(false);
  const [isPaneKaishuKanoYobi, setPaneKaishuKanoYobi] = useState(false);
  const [isViewJikan, setViewJikan] = useState(false);
  const [isViewSonota, setViewSonota] = useState(false);
  const [isViewNgShakaku, setViewNgShakaku] = useState(false);
  const [isViewKiboShashu, setViewKiboShashu] = useState(false);
  const [isViewNieki, setViewNieki] = useState(false);
  const [isViewKojinsettei, setViewKojinsettei] = useState(false);
  const [isViewKaishubashonoshashinExist, setViewKaishubashonoshashinExist] = useState(false);

  // STATE
  const [kojins, setKojins] = useState([]);

  const [workMail, setWorkMail] = useState(me.email || "");

  const [selectKaishuYoubiKbns, setSelectKaishuYoubiKbns] = useState(me.kaishuYoubiKbns?.split(","));
  const [selectKaishuTimeFrom, setSelectKaishuTimeFrom] = useState(me.kaishuTimeFrom);
  const [selectKaishuTimeTo, setSelectKaishuTimeTo] = useState(me.kaishuTimeTo);
  const [selectKaishuTimeKbn, setSelectKaishuTimeKbn] = useState(me.kaishuTimeKbn);
  const [selectKaishuTimeBiko, setSelectKaishuTimeBiko] = useState(me.kaishuTimeBiko);
  const [selectHokanGendo, setSelectHokanGendo] = useState(me.hokanGendo || 0);
  const [selectNgShakakuKbns, setSelectNgShakakuKbns] = useState(me.ngShakakuKbns?.split(","));
  const [selectKiboSharyoKbn, setSelectKiboSharyoKbn] = useState(me.sharyoKbn);
  const [selectNiyaku, setSelectNiyaku] = useState(me.niyakuKbn);
  const [selectBiko, setSelectBiko] = useState(me.biko);

  const [isPictureModal, infoPictureControl] = useBoolean(false);
  const [selectPicture, setSelectPicture] = useState(null);

  const [files, setFiles] = useState(me.files || []);
  const [newTmpFiles, setNewTmpFiles] = useState([]);
  const [selectFile, setSelectFile] = useState();

  const [editKojinKbn, setEditKojinKbn] = useState(EDIT_USER_KBN.NEW);
  const [selectKojin, setSelectKojin] = useState(initialKojin);

  // 回収条件
  const kaishuYobis = (selectKaishuYoubiKbns || []).map((x) => (youbis.find((z) => z.kbn === x) || {}).name).join("・");
  const kaishuTime =
    me.kaishuTimeKbn === "3" ? me.kaishuTimeBiko : (kaishuTimes.find((y) => y.kbn === me.kaishuTimeKbn) || {}).name;
  const hokanGendo = (me.hokanGendo || "－").toLocaleString();
  const nieki = (niyakuList.find((y) => y.kbn === me.niyakuKbn) || {}).name;
  const fileExist = me.files && me.files.length ? "登録あり" : "登録なし";

  // 定期回収条件
  const dispTeikiKaishu = useMemo(() => getTeikikaishuLabel(teikis, youbis), [teikis, youbis]);

  // 編集表示用回収可能曜日
  const displayKanoDays = useMemo(() => {
    let label = "";
    if (selectKaishuYoubiKbns && selectKaishuYoubiKbns.length) {
      label = selectKaishuYoubiKbns.map((day) => weekdaysShort[day]).join("・");
    }
    return label;
  }, [selectKaishuYoubiKbns]);

  // 編集表示用希望時間
  const displayKiboTime = useMemo(() => {
    if (selectKaishuTimeKbn === KIBOTIME_KBN.SONOTA) {
      return selectKaishuTimeBiko;
    }
    if (selectKaishuTimeFrom === "" && selectKaishuTimeTo === "") {
      return "希望なし";
    }
    return (kaishuTimes.find((y) => y.kbn === selectKaishuTimeKbn) || {}).name;
  }, [selectKaishuTimeKbn, kaishuTimes, selectKaishuTimeFrom, selectKaishuTimeTo, selectKaishuTimeBiko]);

  // 編集表示用NG車格
  const displayNgShakaku = useMemo(() => {
    let label = "選択(任意)";
    if (selectNgShakakuKbns && selectNgShakakuKbns.length > 0) {
      label = selectNgShakakuKbns
        .map((ngShakakuKbn) => {
          return (ngShakakuList.find((x) => x.kbn === ngShakakuKbn) || {}).name;
        })
        .join("、");
    }
    return label;
  }, [selectNgShakakuKbns, ngShakakuList]);

  // 編集表示用希望車種
  const displayShashu = useMemo(() => {
    let label = "選択(任意)";
    if (selectKiboSharyoKbn) {
      label = (kiboShashuList.find((x) => x.kbn === selectKiboSharyoKbn) || {}).name;
    }
    return label;
  }, [selectKiboSharyoKbn, kiboShashuList]);

  // 編集表示用荷役
  const displayNieki = useMemo(() => {
    let label = "選択(任意)";
    if (!!!selectNiyaku || selectNiyaku === "") {
      return label;
    }
    return (niyakuList.find((y) => y.kbn === selectNiyaku) || {}).name;
  }, [selectNiyaku, niyakuList]);

  const getKojins = useCallback(async () => {
    try {
      const res = await axios.get("/api/dashboard");
      if (res && res.data) {
        const tmp = res.data.kojins.map((kojin) => {
          kojin.notify = kojin.tel ? NOTIFY_KBN.TEL : NOTIFY_KBN.MAIL; // notify設定を追加
          return kojin;
        });
        setKojins(tmp);
      }
    } catch (error) {
      setErrorMessage(error.toString());
      errorModalControl.on();
    }
  }, [errorModalControl]);

  const handleSelectKiboTime = useCallback((e, kbn) => {
    e.preventDefault();
    setSelectKaishuTimeKbn(kbn);
    switch (kbn) {
      case KIBOTIME_KBN.AM:
        setSelectKaishuTimeFrom("0900");
        setSelectKaishuTimeTo("1200");
        setSelectKaishuTimeBiko("");
        break;
      case KIBOTIME_KBN.PM:
        setSelectKaishuTimeFrom("1300");
        setSelectKaishuTimeTo("1700");
        setSelectKaishuTimeBiko("");
        break;
      case KIBOTIME_KBN.SONOTA:
        setSelectKaishuTimeFrom("");
        setSelectKaishuTimeTo("");
        setSelectKaishuTimeBiko(e.target.value);
        break;
      default:
        setSelectKaishuTimeFrom("");
        setSelectKaishuTimeTo("");
        setSelectKaishuTimeBiko("");
        break;
    }
  }, []);

  const onKiboTime = useCallback(
    (e) => {
      e.preventDefault();

      setSelectKaishuTimeFrom(selectKaishuTimeFrom);
      setSelectKaishuTimeTo(selectKaishuTimeTo);
      setSelectKaishuTimeBiko(selectKaishuTimeBiko);
      setViewSonota(false);
      setViewJikan(false);
    },
    [selectKaishuTimeFrom, selectKaishuTimeTo, selectKaishuTimeBiko]
  );

  const onNieki = useCallback(
    (e) => {
      e.preventDefault();
      setSelectNiyaku(selectNiyaku);
      setViewNieki(false);
    },
    [selectNiyaku]
  );

  const onClickPasswordChange = useCallback(
    (e) => {
      e.preventDefault();
      refOkEvent.current = async (e) => {
        e.preventDefault();
        infoModalControl.off();

        try {
          const loginCode = me.loginCode;
          const res = await axios.post("/api/password-reset-mail", {
            loginCode,
          });
          if (res) {
            refOkEvent.current = undefined;
            setMessage("パスワード再設定用メールを\n送信しました");
            infoModalControl.on();
          }
        } catch (error) {
          console.warn(error);
          setErrorMessage("メール送信に失敗しました");
          errorModalControl.on();
        }
      };

      setMessage("管理者様のメールアドレスに\nパスワード再設定用のURLを送ります。\nよろしいですか？");
      infoModalControl.on();
    },
    [infoModalControl, errorModalControl, me]
  );

  const onClickMailChange = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/user-email", {
        email: workMail,
      });
      loadMe();
      setPanePasswordhenko(false);
    } catch (error) {
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorModalControl.on();
    }
  };

  const onClickAddFile = useCallback(async (e) => {
    try {
      const files = e.target.files;
      if (files && files.length) {
        const formData = new FormData();
        const tmps = [];
        for (let file of files) {
          const blob = URL.createObjectURL(file);
          formData.append("file", file, file.name);
          tmps.push({ source: blob });
        }
        const res = await axios.post("/api/file/tmp", formData);
        if (res && res.data) {
          const newFiles = (res.data || []).map((path, index) => ({
            id: 0,
            path,
            url: tmps[index].source,
          }));
          setNewTmpFiles((prev) => [...prev, ...newFiles]);
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      inputFileRef.current.value = "";
    }
  }, []);

  const onClickDeleteFile = useCallback(
    async (e, file) => {
      e.preventDefault();

      refOkEvent.current = async (e) => {
        e.preventDefault();
        infoModalControl.off();

        try {
          if (file.id === 0) {
            setNewTmpFiles((prev) => prev?.filter(({ path }) => path !== file.path));
          } else {
            await axios.put("/api/file/" + file.id);
            setFiles((files) => files.filter(({ id }) => id !== file.id));
          }

          refOkEvent.current = null;
          setMessage("削除しました");
          infoModalControl.on();
        } catch (error) {
          console.warn(error);
          setErrorMessage("写真を削除できませんでした");
          errorModalControl.on();
        }
      };

      setMessage("削除してもよろしいですか？");
      infoModalControl.on();
    },
    [infoModalControl, errorModalControl]
  );

  const onKaishuJokenHenko = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/user-kaishujoken", {
        kaishuYoubiKbns: selectKaishuYoubiKbns?.join(",") ?? "",
        kaishuTimeKbn: selectKaishuTimeKbn || "",
        kaishuTimeFrom: selectKaishuTimeFrom || "",
        kaishuTimeTo: selectKaishuTimeTo || "",
        kaishuTimeBiko: selectKaishuTimeBiko || "",
        hokanGendo: selectHokanGendo,
        ngShakakuKbns: selectNgShakakuKbns?.join(",") ?? "",
        sharyoKbn: selectKiboSharyoKbn || "",
        niyakuKbn: selectNiyaku || NIYAKU_KBN.COMMON,
        biko: selectBiko || "",
        files,
        newTmpPaths: newTmpFiles,
      });
      loadMe();
      setPaneKaishuJokenHenko(false);
    } catch (error) {
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorModalControl.on();
    }
  };

  const executableKojin = useMemo(() => {
    const { email, tel, notify } = selectKojin;

    if (notify === NOTIFY_KBN.MAIL && !!!email) return false;
    if (notify === NOTIFY_KBN.TEL && !!!tel) return false;

    return true;
  }, [selectKojin]);

  const onCreateKojin = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (!executableKojin) return;

        const d = {
          ...selectKojin,
          notifyZaikoSuryo: parseInt(selectKojin.notifyZaikoSuryo || 0),
          notifyKeikaDay: parseInt(selectKojin.notifyKeikaDay || 0),
          email: selectKojin.notify === NOTIFY_KBN.MAIL ? selectKojin.email : "",
          tel: selectKojin.notify === NOTIFY_KBN.TEL ? selectKojin.tel : "",
          id: 0,
        };

        const res = await axios.post("/api/kojin", { ...d });
        if (res && res.data) {
          setViewKojinsettei(false);
          setSelectKojin(initialKojin);
          // 作成時は再取得
          getKojins();
        }
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [selectKojin, errorModalControl, executableKojin, getKojins]
  );

  const onUpdateKojin = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (!executableKojin) return;

        const d = {
          ...selectKojin,
          notifyZaikoSuryo: parseInt(selectKojin.notifyZaikoSuryo || 0),
          notifyKeikaDay: parseInt(selectKojin.notifyKeikaDay || 0),
          email: selectKojin.notify === NOTIFY_KBN.MAIL ? selectKojin.email : "",
          tel: selectKojin.notify === NOTIFY_KBN.TEL ? selectKojin.tel : "",
        };

        const res = await axios.put("/api/kojin/" + selectKojin.id, { ...d });
        if (res && res.data) {
          setKojins((prev) => {
            return [...prev].map((x) => {
              if (x.id === selectKojin.id) return d;
              return x;
            });
          });
          setViewKojinsettei(false);
          setSelectKojin(initialKojin);
        }
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [selectKojin, errorModalControl, executableKojin]
  );

  const onDeleteKojin = useCallback(() => {
    refOkEvent.current = async (e) => {
      e.preventDefault();
      try {
        infoModalControl.off();

        const res = await axios.delete("/api/kojin/" + selectKojin.id);
        if (res) {
          setKojins((prev) => {
            return [...prev].filter((x) => x.id !== selectKojin.id);
          });

          setViewKojinsettei(false);
          setSelectKojin(initialKojin);
        }
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    };

    setMessage("ユーザーの情報を削除します\nよろしいですか？");
    infoModalControl.on();
  }, [selectKojin, infoModalControl, errorModalControl]);

  const onLogout = useCallback(
    (e) => {
      e.preventDefault();
      axios
        .post("/api/logout")
        .then(() => {
          resetMe();
          history.replace("/login");
        })
        .catch((error) => {
          setErrorMessage(error.toString());
          errorModalControl.on();
        });
    },
    [history, resetMe, errorModalControl]
  );

  useEffect(() => {
    getKojins();
  }, [getKojins]);

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc current="/mypage" />
        <div className="main__main">
          <div className="main__main-header">
            <MainNavi current="/mypage" />
            <div className="hdg --h1">
              <span className="hdg__title">マイページ</span>
              <span className="hdg__note">
                <a href="#" className="btn --gry-secondary --h-xs" onClick={onLogout}>
                  ログアウト
                </a>
              </span>
            </div>
          </div>
          <div className="main__main-contents">
            <div className="inner">
              <div className="layout --col-2">
                <div>
                  <div className="padding-for-sp">
                    <div className="hdg  --h2">
                      <div className="hdg__title">会社情報</div>
                      <div className="hdg__btn">
                        <a href="#" className="btn --gry-secondary --h-xs" onClick={onClickPasswordChange}>
                          <span>パスワードを変更する</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form p--16">
                    <div className="txt --fz-m --bold">{kokyaku?.ryaku} 様</div>
                    <div className="txt --fz-s mt--16">
                      {`パソコン用ログインID：${me.loginCode}`}
                      <br />
                      PW：＊＊＊＊＊
                    </div>
                    <div className="ico --calendar --s txt --fz-ss --blk-300 mt--16">{`定期回収 [ ${dispTeikiKaishu} ]`}</div>
                  </div>

                  <div className="padding-for-sp">
                    <div className="hdg  --h2">
                      <div className="hdg__title">管理者様メールアドレス</div>
                      <div className="hdg__btn">
                        <a
                          href="javascript:void(0);"
                          className="btn --gry-secondary --h-xs paneOpen"
                          rel="kanrishameru"
                          onClick={() => setPanePasswordhenko(true)}
                        >
                          <span>変更する</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form p--16">
                    <div className="confirm-layouts pt--0">
                      <div className="confirm-layout">
                        <div className="confirm-layout__detail">{me.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="padding-for-sp">
                    <div className="hdg  --h2">
                      <div className="hdg__title">回収条件</div>
                      <div className="hdg__btn">
                        <a
                          href="javascript:void(0);"
                          onClick={() => setPaneKaishuJokenHenko(true)}
                          className="btn --gry-secondary --h-xs paneOpen"
                          rel="kaishujokenhenko"
                        >
                          <span>回収条件を変更する</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="form p--16">
                    <div className="confirm-layouts pt--0">
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">回収可能な曜日</div>
                        <div className="confirm-layout__detail">{kaishuYobis}</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">回収可能な時間帯</div>
                        <div className="confirm-layout__detail">{kaishuTime}</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">保管限度枚数</div>
                        <div className="confirm-layout__detail">{hokanGendo}枚</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">NG車格</div>
                        <div className="confirm-layout__detail">{me.ngShakakuKbns ? displayNgShakaku : "－"}</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">希望車種</div>
                        <div className="confirm-layout__detail">{me.sharyoKbn ? displayShashu : "－"}</div>
                      </div>
                      <div className="confirm-layout" hidden>
                        <div className="confirm-layout__title">荷役</div>
                        <div className="confirm-layout__detail">{nieki || "－"}</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">回収場所の写真</div>
                        <div className="confirm-layout__detail">{fileExist}</div>
                      </div>
                      <div className="confirm-layout">
                        <div className="confirm-layout__title">注意事項</div>
                        <div className="confirm-layout__detail">{me.biko}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="padding-for-sp">
                <div className="hdg  --h2-txt">
                  <div className="hdg__title">個人設定一覧</div>
                </div>
                <div className="txt --fz-ss --blk-300">
                  ユーザーごとに、受け取る通知の種類や、通知の連絡先を設定できます。
                </div>
              </div>
              <div className="form mt--12">
                {kojins.map((kojin, index) => (
                  <a
                    key={kojin.id}
                    href="javascript:void(0);"
                    className="form-layout paneOpen"
                    rel="kojinsettei"
                    onClick={() => {
                      setEditKojinKbn(EDIT_USER_KBN.EDIT);
                      setSelectKojin(kojin);
                      setViewKojinsettei(true);
                    }}
                  >
                    <div className="form-layout__detail">
                      <div className="form-detail-layout">
                        <span className="form-detail-layout__value --left">
                          <span>{kojin.name}</span>
                        </span>
                        <span className="form-detail-layout__link"></span>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className="btn-layout --col-1">
              <div>
                <a
                  href="javascript:void(0);"
                  className="btn --wht-primary --h-s paneOpen"
                  rel="kojinsettei"
                  onClick={() => {
                    setEditKojinKbn(EDIT_USER_KBN.NEW);
                    setSelectKojin(initialKojin);
                    setViewKojinsettei(true);
                  }}
                >
                  <span>ユーザーを追加</span>
                </a>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>

      <div className={`pane pane--kaishujokenhenko ${isPaneKaishuJokenHenko && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setNewTmpFiles([]);
              setPaneKaishuJokenHenko(false);
            }}
            className="pane__header-back paneClose"
          ></a>
          <div className="pane__header-title">回収条件変更</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form">
              <a
                href="javascript:void(0);"
                onClick={() => setPaneKaishuKanoYobi(true)}
                className="form-layout paneOpen"
                rel="kaishukanonayobi"
              >
                <div className="form-layout__title">
                  <span>回収可能な曜日</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <span>{displayKanoDays}</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void(0);"
                className="form-layout paneOpen"
                rel="kaishukanonajikan"
                onClick={() => setViewJikan(true)}
              >
                <div className="form-layout__title">
                  <span>回収可能な時間帯</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className="form-detail-layout__value">
                      <span>{displayKiboTime}</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <TextInput
                title="保管限度枚数"
                type="text"
                inputmode="numeric"
                value={selectHokanGendo}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val === "" || /^\d{1,}$/.test(val)) {
                    setSelectHokanGendo(parseInt(e.target.value || "0"));
                  }
                }}
                placeholder="入力(必須)"
                unitLayout={<span className="form-detail-layout__unit">枚</span>}
              />
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">車両設定</div>
              </div>
            </div>
            <div className="form">
              <a
                href="#"
                className="form-layout paneOpen"
                rel="ngshakaku"
                onClick={(e) => {
                  e.preventDefault();
                  setViewNgShakaku(true);
                }}
              >
                <div className="form-layout__title">
                  <span>NG車格</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span
                      className={`form-detail-layout__value ${
                        (!!!selectNgShakakuKbns || selectNgShakakuKbns.length === 0) && "--empty"
                      }`}
                    >
                      <span>{displayNgShakaku}</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <a
                href="#"
                className="form-layout paneOpen"
                rel="kiboshashu"
                onClick={(e) => {
                  e.preventDefault();
                  setViewKiboShashu(true);
                }}
              >
                <div className="form-layout__title">
                  <span>希望車種</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className={`form-detail-layout__value ${!!!selectKiboSharyoKbn && "--empty"}`}>
                      <span>{displayShashu}</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
              <a
                href="#"
                className="form-layout paneOpen"
                rel="nieki"
                onClick={(e) => {
                  e.preventDefault();
                  setViewNieki(true);
                }}
                hidden
              >
                <div className="form-layout__title">
                  <span>荷役</span>
                </div>
                <div className="form-layout__detail">
                  <div className="form-detail-layout">
                    <span className={`form-detail-layout__value ${!!!selectNiyaku && "--empty"}`}>
                      <span>{displayNieki}</span>
                    </span>
                    <span className="form-detail-layout__link"></span>
                  </div>
                </div>
              </a>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">回収場所の写真</div>
              </div>
            </div>
            <div className="pictures">
              {[...files, ...newTmpFiles].map((file, index) => (
                <div key={file.url} className="picture">
                  <a
                    href="#"
                    className="picture__pic modalPictureOpen"
                    rel={file.path}
                    onClick={(e) => {
                      e.preventDefault();
                      infoPictureControl.on();
                      setSelectPicture(file);
                    }}
                  >
                    <img src={file.url} alt="" />
                  </a>
                  <a
                    href="#"
                    className="picture__edit paneOpen"
                    rel="kaishubashonoshashin-exist"
                    onClick={(e) => onClickDeleteFile(e, file)}
                  >
                    写真を削除
                  </a>
                </div>
              ))}
              <div className="picture">
                <input type="file" ref={inputFileRef} multiple hidden accept="image/*" onChange={onClickAddFile} />
                {/* <input type="file" ref={inputFileRef} multiple hidden accept="image/*" capture="environment" onChange={onClickAddFile} /> */}
                <a
                  href="#"
                  className="picture__pic --add paneOpen"
                  onClick={(e) => {
                    e.preventDefault();
                    if (inputFileRef.current) {
                      inputFileRef.current.click();
                    }
                  }}
                  rel="kaishubashonoshashin-new"
                ></a>
              </div>
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">注意事項</div>
              </div>
            </div>
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__full">
                  <textarea
                    name=""
                    placeholder="回収に関する注意事項など入力してください（任意 50文字程度）"
                    value={selectBiko}
                    onChange={(e) => setSelectBiko(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="#" className="btn --primary" onClick={onKaishuJokenHenko}>
                <span>変更を保存する</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kanrishameru ${isPanePasswordhenko && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setPanePasswordhenko(false);
            }}
          ></a>
          <div className="pane__header-title">管理者様メールアドレス</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form ">
              <TextInput
                addClass="form-layout--kojin-mail"
                title="メールアドレス"
                type="text"
                inputmode="email"
                value={workMail}
                onChange={(e) => setWorkMail(e.target.value)}
                placeholder="例）irai@pallet-station.com(必須)"
              />
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="#" className="btn --primary paneClose showToaster" onClick={onClickMailChange}>
                <span>変更を保存する</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kojinsettei ${isViewKojinsettei && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={() => setViewKojinsettei(false)}
          ></a>
          <div className="pane__header-title">個人設定</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="padding-for-sp">
              <div className="hdg  --h2 --narrow">
                <div className="hdg__title">ユーザー情報</div>
              </div>
            </div>
            <div className="form">
              <TextInput
                title="名前"
                type="text"
                value={(selectKojin || {}).name || ""}
                onChange={(e) => {
                  const name = e.target.value;
                  setSelectKojin((prev) => ({ ...prev, name }));
                }}
                placeholder="入力(必須)"
                tabIndex={1}
                keyDownRegister={keyDownRegister}
              />
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">連絡先</div>
                <div className="hdg__btn" id="jsKojinNotificationType" data-type="mail">
                  <a
                    href="javascript:void(0);"
                    className="btn --gry-secondary --h-xs btn--kojin-notification"
                    onClick={() => {
                      setSelectKojin((preKojin) => ({
                        ...preKojin,
                        notify: selectKojin?.notify === NOTIFY_KBN.MAIL ? NOTIFY_KBN.TEL : NOTIFY_KBN.MAIL,
                      }));
                    }}
                  >
                    <span>{selectKojin?.notify === NOTIFY_KBN.MAIL ? "携帯電話に通知" : "メールに通知"}</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="form ">
              {selectKojin?.notify === NOTIFY_KBN.TEL ? (
                <TextInput
                  addClass="form-layout--kojin-mobilephone"
                  title="携帯電話番号"
                  type="text"
                  inputmode="tel"
                  value={(selectKojin || {}).tel || ""}
                  onChange={(e) => {
                    const tel = e.target.value;

                    if (tel === "" || /^(\d|-){1,13}$/.test(tel)) {
                      setSelectKojin((prev) => ({ ...prev, tel }));
                    }
                  }}
                  placeholder="例）09000000000(必須)"
                  tabIndex={2}
                  keyDownRegister={keyDownRegister}
                />
              ) : (
                <TextInput
                  addClass="form-layout--kojin-mail"
                  title="メールアドレス"
                  type="text"
                  inputmode="email"
                  value={(selectKojin || {}).email || ""}
                  onChange={(e) => {
                    const email = e.target.value;
                    setSelectKojin((prev) => ({ ...prev, email }));
                  }}
                  placeholder="例）irai@pallet-station.com(必須)"
                  tabIndex={2}
                  keyDownRegister={keyDownRegister}
                />
              )}
              <div className="form-layout">
                <div className="form-layout__detail">
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={((selectKojin || {}).notifyHikitoriFlg || "0") === "1"}
                        onChange={(e) => {
                          const notifyHikitoriFlg = e.target.checked ? "1" : "0";
                          setSelectKojin((prev) => ({
                            ...prev,
                            notifyHikitoriFlg,
                          }));
                        }}
                      />
                      <span></span>
                      <span>三甲リースからの引取の依頼を受け取る</span>
                    </label>
                  </div>
                  <div className="form-detail-layout__note">※引取依頼の可否を決める方はチェックを入れてください。</div>
                </div>
              </div>
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">アラートのタイミング</div>
              </div>
            </div>
            <div className="form">
              <TextInput
                title="在庫が貯まったら通知"
                inputmode="numeric"
                value={(selectKojin || {}).notifyZaikoSuryo || ""}
                onChange={(e) => {
                  const notifyZaikoSuryo = formatNumber(e.target.value);

                  setSelectKojin((prev) => ({
                    ...prev,
                    notifyZaikoSuryo,
                  }));
                }}
                placeholder="入力(任意)"
                type="text"
                unitLayout={<span className="form-detail-layout__unit">枚</span>}
                tabIndex={3}
                keyDownRegister={keyDownRegister}
              />
              <TextInput
                title="前回の回収からの経過日数で通知"
                inputmode="numeric"
                value={(selectKojin || {}).notifyKeikaDay || ""}
                onChange={(e) => {
                  const notifyKeikaDay = formatNumber(e.target.value);

                  setSelectKojin((prev) => ({
                    ...prev,
                    notifyKeikaDay,
                  }));
                }}
                placeholder="入力(任意)"
                type="text"
                unitLayout={<span className="form-detail-layout__unit">日</span>}
                tabIndex={4}
                keyDownRegister={keyDownRegister}
              />
            </div>
            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">個別の通知設定</div>
              </div>
            </div>
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__detail">
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={((selectKojin || {}).notifyAllIraiFlg || "0") === "1"}
                        onChange={(e) => {
                          const notifyAllIraiFlg = e.target.checked ? "1" : "0";
                          setSelectKojin((prev) => ({
                            ...prev,
                            notifyAllIraiFlg,
                          }));
                        }}
                      />
                      <span></span>
                      <span>全ての回収依頼の通知を受け取る</span>
                    </label>
                  </div>
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={((selectKojin || {}).notifyAllKanryoFlg || "0") === "1"}
                        onChange={(e) => {
                          const notifyAllKanryoFlg = e.target.checked ? "1" : "0";
                          setSelectKojin((prev) => ({
                            ...prev,
                            notifyAllKanryoFlg,
                          }));
                        }}
                      />
                      <span></span>
                      <span>全ての手配が完了した際に通知を受け取る</span>
                    </label>
                  </div>
                  <div className="form-detail-layout__checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={((selectKojin || {}).notifyAllShabanFlg || "0") === "1"}
                        onChange={(e) => {
                          const notifyAllShabanFlg = e.target.checked ? "1" : "0";
                          setSelectKojin((prev) => ({
                            ...prev,
                            notifyAllShabanFlg,
                          }));
                        }}
                      />
                      <span></span>
                      <span>全ての車番、ドライバー連絡を受け取る</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="#"
                className="btn --primary paneClose showToaster"
                disabled={!!!executableKojin}
                onClick={editKojinKbn === EDIT_USER_KBN.EDIT ? onUpdateKojin : onCreateKojin}
              >
                <span>{editKojinKbn === EDIT_USER_KBN.EDIT ? "変更を保存する" : "追加する"}</span>
              </a>
            </div>

            {editKojinKbn === EDIT_USER_KBN.EDIT && (
              <div className="ta--c pt--30">
                <a
                  href="javascript:void(0);"
                  className="txt --fz-m ico --trash link --secondary modalOpen"
                  rel="delete-account"
                  onClick={onDeleteKojin}
                >
                  このユーザーを削除する
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`pane pane--kaishukibojikan ${isViewJikan && "isOpen"}`}>
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose" onClick={() => setViewJikan(false)} />

          <div className="pane__header-title" id="jsGroup-Kaishukibojikan" data-type="0">
            回収希望時間
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKaishuTimeKbn === KIBOTIME_KBN.AM ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={(e) => handleSelectKiboTime(e, KIBOTIME_KBN.AM)}
                >
                  <span>午前　9 - 12時</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKaishuTimeKbn === KIBOTIME_KBN.PM ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={(e) => handleSelectKiboTime(e, KIBOTIME_KBN.PM)}
                >
                  <span>午後　13 - 17時</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKaishuTimeKbn === KIBOTIME_KBN.SONOTA ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={() => setViewSonota(true)}
                >
                  <span>その他時間指定</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={onKiboTime}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--sonotajikantaishitei ${isViewSonota && "isOpen"}`}>
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose" onClick={() => setViewSonota(false)} />

          <div className="pane__header-title">その他時間指定</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__full">
                  <textarea
                    className="textarea --h-l"
                    name=""
                    placeholder="ご希望時間（50文字程度）&#13;&#10;&#13;&#10;例）11時から～14時の間希望。&#13;&#10;ただし12時～13時は昼休憩で席外しています"
                    value={selectKaishuTimeBiko}
                    onChange={(e) => setSelectKaishuTimeBiko(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneClose"
                id="jsSubmitSonotajikantaishitei"
                onClick={() => {
                  setSelectKaishuTimeKbn(KIBOTIME_KBN.SONOTA);
                  setSelectKaishuTimeBiko(selectKaishuTimeBiko);
                  setViewJikan(false);
                  setViewSonota(false);
                }}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--ngshakaku ${isViewNgShakaku && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewNgShakaku(false);
            }}
          ></a>

          <div className="pane__header-title">NG車格</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="padding-for-sp">
              <div className="txt --blk-300 --fz-ss --bold">許可できない車格がある場合は選択してください</div>
            </div>
            <div className="ngvehicles">
              {isViewNgShakaku &&
                ngShakakuList.map(({ kbn, name }) => (
                  <CheckBox
                    key={kbn}
                    mainClass="ngvehicle"
                    selected={selectNgShakakuKbns?.some((x) => x === kbn)}
                    onChange={(e) => {
                      const currentChecked = e.target.checked;
                      if (!!!selectNgShakakuKbns) {
                        setSelectNgShakakuKbns([kbn]);
                        return;
                      }
                      setSelectNgShakakuKbns((prev) => {
                        if (currentChecked) {
                          if (prev?.some((x) => x === kbn)) {
                            return prev;
                          } else {
                            return [...prev, kbn];
                          }
                        } else {
                          return [...prev].filter((x) => x !== kbn);
                        }
                      });
                    }}
                    label={name}
                  />
                ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={() => setViewNgShakaku(false)}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kiboshashu ${isViewKiboShashu && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="#"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewKiboShashu(false);
            }}
          ></a>

          {/* <!-- data-typeで指定したindexのボタンが選択色になります --> */}
          <div className="pane__header-title" id="jsGroup-Kiboshashu" data-type="0">
            希望車種
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboSharyoKbn === KIBOSHASHU_KBN.NONE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={() => setSelectKiboSharyoKbn(KIBOSHASHU_KBN.NONE)}
                >
                  <span>希望なし</span>
                </a>
              </div>
              {kiboShashuList.map((kiboShashu) => (
                <div key={`kiboshashu-${kiboShashu.kbn}`}>
                  <a
                    href="javascript:void(0);"
                    className={
                      "btn " +
                      (selectKiboSharyoKbn === kiboShashu.kbn ? "--secondary " : "--wht-secondary ") +
                      "btn-group-kiboshashu"
                    }
                    onClick={() => setSelectKiboSharyoKbn(kiboShashu.kbn)}
                  >
                    <span>{kiboShashu.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0)" className="btn --primary paneClose" onClick={() => setViewKiboShashu(false)}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--nieki ${isViewNieki && "isOpen"}`}>
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose" onClick={() => setViewNieki(false)}></a>

          {/* <!-- data-typeで指定したindexのボタンが選択色になります --> */}

          <div className="pane__header-title" id="jsGroup-Nieki" data-type="2">
            荷役
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              {niyakuList.map((niyaku) => (
                <div key={`niyaku-${niyaku.kbn}`}>
                  <a
                    href="javascript:void(0);"
                    className={
                      "btn " + (selectNiyaku === niyaku.kbn ? "--secondary " : "--wht-secondary ") + "btn-group-nieki"
                    }
                    onClick={() => setSelectNiyaku(niyaku.kbn)}
                  >
                    <span>{niyaku.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={onNieki}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--kaishukanonayobi ${isPaneKaishuKanoYobi && "isOpen"}`}>
        <div className="pane__header">
          <a
            href="javascript:void(0)"
            onClick={() => setPaneKaishuKanoYobi(false)}
            className="pane__header-back paneOpen"
            rel="kaishujokenhenko"
          ></a>
          <div className="pane__header-title">回収可能な曜日</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="txt --blk-300 --fz-ss --bold padding-for-sp">回収可能な曜日を選択してください</div>
            <div className="yobis">
              {["1", "2", "3", "4", "5", "6", "0"].map((num) => (
                <CheckBox
                  key={`dayOfWeek-${num}`}
                  mainClass="yobi"
                  selected={selectKaishuYoubiKbns && selectKaishuYoubiKbns.some((x) => x === num)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (!!!selectKaishuYoubiKbns || selectKaishuYoubiKbns.length === 0) {
                      setSelectKaishuYoubiKbns([num]);
                      return;
                    }
                    setSelectKaishuYoubiKbns((prev) => {
                      if (prev && checked) {
                        if (prev.some((x) => x === num)) {
                          return prev;
                        } else {
                          const tmp = [...prev, num]
                            .filter((x) => !!x)
                            .sort((pre, nxt) => {
                              if (pre < nxt) return -1;
                              if (pre > nxt) return 1;
                              return 0;
                            });

                          return tmp;
                        }
                      } else {
                        return [...prev].filter((x) => x !== num);
                      }
                    });
                  }}
                  label={weekdaysLong[num]}
                />
              ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneOpen"
                rel="kaishujokenhenko"
                onClick={() => {
                  setSelectKaishuYoubiKbns(selectKaishuYoubiKbns);
                  setPaneKaishuKanoYobi(false);
                }}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-exist ${
          isViewKaishubashonoshashinExist && "isOpen"
        }`}
      >
        <div className="pane__header">
          <a
            href="#"
            className="pane__header-back paneOpen"
            rel="kaishujokenhenko"
            onClick={(e) => {
              e.preventDefault();
              setViewKaishubashonoshashinExist(false);
            }}
          ></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                <a href="javascript:void(0);" className="modalPictureOpen" rel={selectFile?.url}>
                  <img src={selectFile?.url} alt="" />
                </a>
              </div>
              <div className="selected-picture__delete">
                <a
                  href="javascript:void(0);"
                  className="ico --trash link --secondary --fz-s modalOpen"
                  rel="delete-photo"
                >
                  削除する
                </a>
              </div>
            </div>

            <div className="btn-layout --stack --w-s">
              <div>
                <a href="javascript:void(0);" className="btn --wht-secondary">
                  <span>写真を撮る</span>
                </a>
              </div>
              <div>
                <a href="javascript:void(0);" className="btn --wht-secondary">
                  <span>ファイルから選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary">
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="pane pane--kaishubashonoshashin pane--kaishubashonoshashin-new">
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneOpen" rel="kaishujokenhenko"></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic"></div>
            </div>

            <div className="btn-layout --stack --w-s">
              <div>
                <a href="#" className="btn --wht-secondary">
                  <span>写真を撮る</span>
                </a>
              </div>
              <div>
                <a href="#" className="btn --wht-secondary">
                  <span>ファイルから選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary">
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* モーダル */}
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={errorMessage} />

      {isPictureModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#666666",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
            }}
          >
            {selectPicture && <img src={selectPicture.url} alt="" />}
          </div>
          <div style={{ position: "absolute", top: 16, right: 16 }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                infoPictureControl.off();
              }}
              style={{ fontSize: 32, color: "#fff" }}
            >
              ✕
            </a>
          </div>
        </div>
      )}
    </>
  );
}
