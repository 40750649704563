import React, { useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo } from "react";
import Header from "./Header";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { useBoolean } from "../hooks/useBoolean";
import NotFound from "../views/NotFound";
import InfoModal from "../modals/InfoModal";
import ErrorModal from "../modals/ErrorModal";
import { TextInput } from "../components/TextInput";
import { formatDate } from "../libs/date";
import { createErrorMessage } from "../libs/common";

export default () => {
  const params = useParams();
  const answerKey = params.key;
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [isViewNgRiyu, setViewNgRiyu] = useState(false);
  const [initKaishusuu, setInitKaishusuu] = useState(0);
  const motoSuryo = useRef();
  const [kaishusuu, setKaishusuu] = useState(0);
  const [shohins, setShohins] = useState("");
  const [answerHuka, setAnswerHuka] = useState("");
  const [answer, setAnswer] = useState();
  const hasRiyu = answerHuka !== "";

  // 共通モーダル制御
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOkEvent = useRef(null);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onKaishusuuHenko = (e) => {
    const val = e.target.value;
    const m = val.match(/^\d+$/);
    if (!!m) {
      setKaishusuu(parseInt(val));
    } else {
      setKaishusuu("");
    }
  };

  // 表示用希望日
  const displayKiboDate = useMemo(() => {
    let label = "希望なし";
    if (!!answer?.hikitoriDate) {
      label = formatDate(answer.hikitoriDate, "M月D日(ddd)");
    }
    return label;
  }, [answer]);

  const onNgRiyuHenko = useCallback((e) => {
    e.preventDefault();
    setViewNgRiyu(true);
  }, []);

  const onSendAnswer = useCallback(
    async (e, flg) => {
      e.preventDefault();

      try {
        await axios.put("/api/answer/" + answerKey, { answerHuka, cancelFlg: flg, hikitoriSuryo: kaishusuu });

        infoModalControl.off();
        history.push("/answer-thanks");
      } catch (error) {
        const errorMessage = createErrorMessage(error);
        setErrorMessage(errorMessage);
        errorModalControl.on();
      }
    },
    [history, answerHuka, answerKey, kaishusuu, infoModalControl, errorModalControl]
  );

  const onKakuninModal = useCallback(
    (e) => {
      e.preventDefault();

      refOkEvent.current = (e) => {
        onSendAnswer(e, "0");
      };

      setMessage("回収日を確定します\nよろしいですか？");
      infoModalControl.on();
    },
    [refOkEvent, infoModalControl, onSendAnswer]
  );

  useEffect(() => {
    if (loaded) {
      if (answer && answer.answerDate) {
        history.replace("/answer-answered");
      }

      const suryo = (answer?.meisais || []).reduce((total, cur) => total + cur.suryo, 0);
      const shohins = answer?.meisais.map((meisai) => meisai.shohinName).join("、");
      setInitKaishusuu(suryo);
      setKaishusuu(suryo);
      motoSuryo.current = suryo;
      setShohins(shohins);
    }
  }, [history, answer, loaded]);

  useLayoutEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/answer/" + answerKey);
        if (res && res.data) {
          setAnswer(res.data);
        }
      } catch (e) {
        console.warn(e);
        setAnswer(null);
      } finally {
        setLoaded(true);
      }
    })();
  }, [answerKey]);

  if (!!!loaded) {
    return null;
  }

  if (!!!answer) {
    return <NotFound />;
  }

  return (
    <>
      <Header />
      <div className="main">
        <div className="main__main">
          <div className="main__main-header">
            <div className="hdg --h1 --ta-c">
              <span className="hdg__title">三甲リースからの引取依頼</span>
            </div>
          </div>
          <div className="main__main-contents">
            <div className="inner">
              <div className="txt --fz-m ta--c">
                以下の日時に引取に伺います。
                <br />
                問題なければ回収可能なパレット数を確認後
                <br />
                「了承する」を押してください。
              </div>
              <div className="answer">
                <div className="answer__title">
                  <span>{displayKiboDate}</span>
                  <span>{answer.hikitoriTimeText}</span>
                </div>
                <div className="confirm-layouts --border">
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">回収パレット数</div>
                    <div className="confirm-layout__detail">{initKaishusuu.toLocaleString()}枚</div>
                  </div>
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">パレットの種類</div>
                    <div className="confirm-layout__detail">{shohins}</div>
                  </div>
                </div>
              </div>
              <div className="form mt--12">
                <TextInput
                  title="引取可能パレット数"
                  value={kaishusuu}
                  type="text"
                  inputmode="numeric"
                  onChange={onKaishusuuHenko}
                  placeholder="入力(必須)"
                  unitLayout={<span className="form-detail-layout__unit">枚</span>}
                />
              </div>
            </div>
            <div className="btn-layout --stack --sticky">
              <div>
                <a
                  href="#"
                  className="btn --primary modalOpen"
                  rel="order-commit"
                  disabled={!!!kaishusuu}
                  onClick={onKakuninModal}
                >
                  <span>了承する</span>
                </a>
              </div>
              <div>
                <a href="#" className="btn --wht-primary paneOpen" rel="fukanoriyu" onClick={onNgRiyuHenko}>
                  <span>不可</span>
                </a>
                <div className={`pane pane--fukanoriyu ${isViewNgRiyu && "isOpen"}`}>
                  <div className="pane__header">
                    <a
                      href="#"
                      className="pane__header-back paneClose"
                      onClick={(e) => {
                        e.preventDefault();
                        setViewNgRiyu(false);
                      }}
                    ></a>
                    <div className="pane__header-title">不可の理由</div>
                  </div>
                  <div className="pane__main">
                    <div className="inner">
                      <div className="form">
                        <div className="form-layout">
                          <div className="form-layout__full">
                            <textarea
                              className="textarea --h-l"
                              name=""
                              id="jsText-Hukanoriyu"
                              placeholder="不可の理由をご記入ください（必須 50文字程度）&#13;&#10;&#13;&#10;&#13;&#10;&#13;&#10;例）6月25日(金)の午前中なら大丈夫です&#13;&#10;&#13;&#10;例）その週にまだパレットの入庫があるので来週改めて依頼します"
                              value={answerHuka}
                              onChange={(e) => setAnswerHuka(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-layout --col-1 --sticky">
                      <div>
                        <a
                          href="#"
                          className={`btn --primary`}
                          id="jsSubmit-Hukanoriyu"
                          disabled={!!!hasRiyu}
                          onClick={(e) => onSendAnswer(e, "1")}
                        >
                          <span>送信する</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="回答エラー" message={errorMessage} />
    </>
  );
};
