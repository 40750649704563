import React from "react";
import { useLocation } from "react-router-dom";

import Header from "./Header";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";

export default function IraiFinish() {
  const { state } = useLocation();

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc current="/irai" />
        <div className="main__main">
          <MainNavi current="/irai" />
          <div className="main__main-contents">
            <div className="inner">
              <div className="frame">
                <div className="hdg --message">
                  <span className="hdg__title">
                    ありがとうございます
                    <br />
                    回収依頼を受け付けました
                    <br />
                  </span>
                </div>
                <div align="center">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScttS0dTM7SkSKJwiDaKNBpbgjZN5YmAm4Zt_b05ei3RLLyDw/viewform?usp=sf_link"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span style={{ color: "#888888" }}>
                      <br />
                      パレット回収アンケート実施中
                    </span>
                    <img src="/assets/Images/pic_another_tab.svg" alt="" />
                  </a>
                </div>
                <div className="confirm-layouts --border">
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">回収パレット数</div>
                    <div className="confirm-layout__detail">{state.kaishuSuryo}枚</div>
                  </div>

                  <div className="confirm-layout">
                    <div className="confirm-layout__title">パレットの種類</div>
                    <div className="confirm-layout__detail">{state.kaishuShubetu}</div>
                  </div>

                  <div className="confirm-layout">
                    <div className="confirm-layout__title">回収希望日</div>
                    <div className="confirm-layout__detail">{state.kiboDate}</div>
                  </div>
                  <div class="confirm-layout">
                    <div class="confirm-layout__title">NG車格</div>
                    <div class="confirm-layout__detail">{state.ngshakaku}</div>
                  </div>
                  <div class="confirm-layout">
                    <div class="confirm-layout__title">希望車種</div>
                    <div class="confirm-layout__detail">{state.kiboshashu}</div>
                  </div>
                  <div class="confirm-layout" hidden>
                    <div class="confirm-layout__title">荷役</div>
                    <div class="confirm-layout__detail">{state.nieki}</div>
                  </div>
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">回収希望時間</div>
                    <div className="confirm-layout__detail">{state.kiboTime}</div>
                  </div>
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">通知設定</div>
                    <div className="confirm-layout__detail">
                      <div>{state.notifyKanryo}</div>
                      <div>{state.notifyShaban}</div>
                    </div>
                  </div>
                  {state.mail && (
                    <div className="confirm-layout">
                      <div className="confirm-layout__title">メールアドレス</div>
                      <div className="confirm-layout__detail">{state.mail}</div>
                    </div>
                  )}
                  {state.telno && (
                    <div className="confirm-layout">
                      <div className="confirm-layout__title">携帯電話番号</div>
                      <div className="confirm-layout__detail">{state.telno}</div>
                    </div>
                  )}
                  <div className="confirm-layout">
                    <div className="confirm-layout__title">注意事項</div>
                    <div
                      className="confirm-layout"
                      dangerouslySetInnerHTML={{
                        __html: state?.biko?.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>
    </>
  );
}
