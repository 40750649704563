import React, { useCallback } from "react";

export default function ErrorModal(props) {
  const { isOpen, control, title, message } = props;

  const onClose = useCallback((e) => {
    e.preventDefault();
    control.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpen && (
        <div className="overlay" style={{ display: "block", zIndex: 999999 }}>
          <div className="modal modal--error modal--not-match-password" style={{ display: "block" }}>
            <div class="modal__header">
              <div class="modal__title">{title}</div>
            </div>
            <div class="modal__body">
              <div class="modal__detail">
                <div className="txt" dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br/>") }} />
              </div>
              <div class="btn-layout --col-1 --center mt--20">
                <div>
                  <a href="#" className="btn --primary modalClose" onClick={onClose}>
                    <span>OK</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
