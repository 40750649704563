import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import Tabulator from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet

import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateDatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import "antd/dist/antd.css";
// Add Japanese Locale
import jaJP from "antd/lib/locale/ja_JP";
import { ConfigProvider } from "antd";
import "dayjs/locale/ja";

import ComboBox from "react-responsive-combo-box";
import "react-responsive-combo-box/dist/index.css";

import Header from "./Header";

const DatePicker = generateDatePicker(dayjsGenerateConfig);

const DateTimePicker = ({ day, setDay }) => {
  return (
    <div>
      <ConfigProvider locale={jaJP}>
        <DatePicker format="YYYY/MM/DD" placeholder="select date" value={day} onChange={setDay} />
      </ConfigProvider>
    </div>
  );
};

const ComboBoxExample = () => {
  const data = ["America", "India", "Australia", "Argentina", "Ireland", "Indonesia", "Iceland", "Japan"];
  return <ComboBox options={data} enableAutocomplete />;
};

export default function () {
  useEffect(() => {}, []);

  return (
    <div className="wrapper">
      <Header />
      <div className="main">
        <div className="main-inner">
          <div className="hdg">
            <div className="hdg__text">テスト</div>
          </div>
          <div className="search --border">
            <div className="search__condition">
              <div className="condition">
                <div className="condition__date">
                  {/* <input type="text" className="w--110 datepicker hasDatepicker" id="dp1633314182803" /> */}
                  <DateTimePicker />
                </div>
                <div className="condition__freeword">
                  <ComboBoxExample />
                </div>
                <div className="condition__btn">
                  <a href="#" className="btn --secondary --h-s w--80">
                    <span>検索</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="search__result">
              <div className="result">
                <div className="result__new">
                  <span>新規依頼：</span>
                  <span></span>
                </div>
                <div className="result__change">
                  <span>変更依頼：</span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
