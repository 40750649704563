import axios from "axios";
import React, { useState, createContext, useEffect } from "react";

export const AppContext = createContext();

/**
 * 事前処理、共通処理
 */
export const AppContextProvider = (props) => {
  // 共通ステータス、処理
  const [me, setMe] = useState(null);
  const [meishos, setMeishos] = useState(null);
  const [shohins, setShohins] = useState(null);
  const [hikitoriShohins, setHikitoriShohins] = useState(null);
  const [teikis, setTeikis] = useState(null);
  const [unsos, setUnsos] = useState(null);
  const [holidays, setHolidays] = useState();

  const loadMe = async () => {
    try {
      const res = await axios.post("/api/me");
      if (res && res.data && res.data.me) {
        const mergeMe = {
          ...res.data.me,
          googleApiKey: res.data.googleApiKey ?? "",
        };
        setMe(mergeMe);
      }
      if (res && res.data && res.data.meishos) {
        setMeishos(res.data.meishos);
      }
      if (res && res.data && res.data.shohins) {
        setShohins(res.data.shohins);
      }
      if (res && res.data && res.data.hikitoriShohins) {
        setHikitoriShohins(res.data.hikitoriShohins);
      }
      if (res && res.data && res.data.teikis) {
        setTeikis(res.data.teikis);
      }
      if (res && res.data && res.data.unsos) {
        setUnsos(res.data.unsos);
      }
      if (res && res.data && res.data.holidays) {
        const holidays = res.data.holidays.map((e) => e.ymd);
        setHolidays(holidays);
      }
      return true;
    } catch (error) {
      console.warn("loadMe error", error);
      return false;
    }
  };

  const loadWeakMe = async (weakLoginKey) => {
    try {
      const res = await axios.post("/api/weak-me", { weakLoginKey });
      const { meishos, shohins } = res.data;
      setMeishos(meishos);
      setShohins(shohins);

      if (res && res.data && res.data.holidays) {
        const holidays = res.data.holidays.map((e) => e.ymd);
        setHolidays(holidays);
      }

      return true;
    } catch (error) {
      console.warn("loadWeakMe error", error);
      return false;
    }
  };

  const resetMe = () => {
    setMe(null);
    setMeishos(null);
    sessionStorage.setItem("weakLoginKey", "");
  };

  const value = {
    me,
    setMe,
    meishos,
    shohins,
    hikitoriShohins,
    teikis,
    unsos,
    holidays,
    loadMe,
    loadWeakMe,
    resetMe,
  };

  // ローディング中
  const [loading, setLoading] = useState(true);

  // 事前処理
  useEffect(() => {
    loadMe()
      .then()
      .catch((error) => console.warn(error))
      .finally(() => setLoading(false));
  }, []);

  return loading ? <></> : <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};
