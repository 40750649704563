import React, { memo, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import "antd/es/date-picker/style/index";
import "antd/dist/antd.css";
import "dayjs/locale/ja";
import jaJP from "antd/lib/locale/ja_JP";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateDatePicker from "antd/es/date-picker/generatePicker";
import { Helmet } from "react-helmet";

const DatePicker = generateDatePicker(dayjsGenerateConfig);

export const DateTimePicker = memo(
  ({ day, setDay, tabIndex, keyDownRegister, pastPermission = true, hasArrow, className = null }) => {
    let pickerProps = {};
    if (!!keyDownRegister) {
      pickerProps = keyDownRegister(tabIndex);
    }

    const toDayjs = (day) => {
      if (!day) {
        return null;
      }
      if (typeof day == "string") {
        let m = (day || "").match(/^(\d{4})(\d{2})(\d{2})$/);
        if (m) {
          return dayjs(m[1] + "-" + m[2] + "-" + m[3]);
        }
        return dayjs(day);
      }
      return day;
    };

    const onArrowMinus = useCallback(
      (e) => {
        e.preventDefault();
        if (day && dayjs(day).isValid()) {
          setDay(dayjs(day).add(-1, "day"));
          return;
        }
        setDay(dayjs());
      },
      [day, setDay]
    );

    const onArrowPlus = useCallback(
      (e) => {
        e.preventDefault();
        if (day && dayjs(day).isValid()) {
          setDay(dayjs(day).add(1, "day"));
          return;
        }
        setDay(dayjs());
      },
      [day, setDay]
    );

    return (
      <>
        {hasArrow && (
          <a href="#" className="arrow-minus mr--5" onClick={onArrowMinus}>
            <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="8" fill="#279E9E"></rect>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.9968 21.3334L12.6635 16L17.9968 10.6667L19.3335 12L15.3302 16L19.3335 20L17.9968 21.3334Z"
                fill="white"
              ></path>
            </svg>
          </a>
        )}
        <ConfigProvider locale={jaJP}>
          <DatePicker
            className={className}
            value={toDayjs(day)}
            format="YYYY/MM/DD"
            placeholder=""
            onChange={setDay}
            disabledDate={(day) => (!!pastPermission ? false : day <= dayjs().add(-1, "day"))}
            {...pickerProps}
          />
        </ConfigProvider>
        {hasArrow && (
          <a href="#" className="arrow-plus ml--5" onClick={onArrowPlus}>
            <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
                fill="#279E9E"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0032 10.6666L19.3365 16L14.0032 21.3333L12.6665 20L16.6698 16L12.6665 12L14.0032 10.6666Z"
                fill="white"
              ></path>
            </svg>
          </a>
        )}
        <Helmet>
          <style>{`
            .ant-picker-dropdown {
              z-index: 999999;
            }
          `}</style>
        </Helmet>
      </>
    );
  }
);
