import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";

import Header from "./Header";
import Map from "./Map";
import { AppContext } from "../../AppContext";
import { AdminAppContext } from "../../AdminAppContext";
import { useBoolean } from "../../hooks/useBoolean";
import KaishuIraiLeftUpPane from "../../panes/KaishuIraiLeftUpPane";
import TonyaLeftUpPane from "../../panes/TonyaLeftUpPane";
import DepoLeftUpPane from "../../panes/DepoLeftUpPane";
import TeikiKaishuLeftUp from "../../panes/TeikiKaishuLeftUp";
import { ErrorModal, PictureModal } from "../../modals/admin/Dialogs";
import HikitoriModal from "../../modals/admin/HikitoriModal";

export default function () {
  const mapRef = useRef();
  const { hikitoriShohins } = useContext(AppContext);
  const { setHikitoriInfo, hikitoriModalControl } = useContext(AdminAppContext);

  // 選択中のマーカー明細
  const [selectMarkerIraiItem, setSelectMarkerIraiItem] = useState();
  const [selectMarkerTonyaItem, setSelectMarkerTonyaItem] = useState();
  const [selectMarkerDepoItem, setSelectMarkerDepoItem] = useState();
  const [selectMarkerTeikiItem, setSelectMarkerTeikiItem] = useState();

  const [isPictureModal, pictureModalControl] = useBoolean(false);
  const [isViewKaishuIraiLeftUp, setViewkaishuIraiLeftUp] = useState(false);
  const [isViewTonyaLeftUp, setViewTonyaLeftUp] = useState(false);
  const [isViewDepoLeftUp, setViewDepoLeftUp] = useState(false);
  const [isViewTeikiKaishuLeftUp, setViewTeikiKaishuLeftUp] = useState(false);

  const [message, setMessage] = useState("");
  const [isErrorModal, errorModalControl] = useBoolean(false);

  const isViewKohyoLeftUp = useMemo(() => {
    return isViewKaishuIraiLeftUp || isViewTonyaLeftUp || isViewDepoLeftUp || isViewTeikiKaishuLeftUp;
  }, [isViewKaishuIraiLeftUp, isViewTonyaLeftUp, isViewDepoLeftUp, isViewTeikiKaishuLeftUp]);

  // table map 表示データ
  const [list, setList] = useState([]);

  const onMarkerClick = useCallback(async (item, detail) => {
    // 選択したマーカーデータ+詳細情報を取得
    try {
      if (item.type === "kaishuirai") {
        setSelectMarkerIraiItem(detail);
      }
      if (item.type === "tonya") {
        setSelectMarkerTonyaItem(detail);
      }
      if (item.type === "depo") {
        setSelectMarkerDepoItem(detail);
      }
      if (item.type === "teikikaishu") {
        setSelectMarkerTeikiItem(detail);
      }

      setViewkaishuIraiLeftUp(item.type === "kaishuirai");
      setViewTonyaLeftUp(item.type === "tonya");
      setViewDepoLeftUp(item.type === "depo");
      setViewTeikiKaishuLeftUp(item.type === "teikikaishu");
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const onPreIraiIpad = useCallback(
    async ({ files, latlng, jusho }) => {
      try {
        if (files && files.length) {
          // fileは1件
          const tmps = [];
          const formData = new FormData();
          for (let file of files) {
            const blob = URL.createObjectURL(file);
            formData.append("file", file, file.name);
            tmps.push({ url: blob, name: file.name });
          }
          const res = await axios.post("/api/file/tmp", formData);
          const newFiles = (res.data || []).map((path, index) => ({
            path,
            ...tmps[index],
          }));
          setHikitoriInfo({
            id: "009324300",
            name: "【問屋】不明先からの回収（iPadから送信）",
            files: newFiles,
            latlng,
            jusho,
            shohins: hikitoriShohins ?? [],
          });
          hikitoriModalControl.on();
        }
      } catch (error) {
        setMessage("ファイルのアップロードに失敗しました");
        setHikitoriInfo(null);
        errorModalControl.on();
      }
    },
    [hikitoriShohins, errorModalControl, setHikitoriInfo, hikitoriModalControl]
  );

  const onOpenPictureModal = useCallback(
    (e) => {
      e.preventDefault();
      pictureModalControl.on();
    },
    [pictureModalControl]
  );

  useEffect(() => {
    const timerId = setInterval(() => {
      if (mapRef.current?.isGoogleAvailable()) {
        mapRef.current?.getGeolocation();
        clearInterval(timerId);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />

      <Map
        isIpad
        ref={mapRef}
        markerList={list}
        {...{
          // selectRow,
          onMarkerClick,
          isViewKohyoLeftUp,
          onPreIraiIpad,
        }}
      />

      <KaishuIraiLeftUpPane
        isIpad
        isOpen={isViewKaishuIraiLeftUp}
        setOpen={setViewkaishuIraiLeftUp}
        item={selectMarkerIraiItem}
        onOpenPictureModal={onOpenPictureModal}
      />
      <TonyaLeftUpPane isIpad isOpen={isViewTonyaLeftUp} setOpen={setViewTonyaLeftUp} item={selectMarkerTonyaItem} />
      <DepoLeftUpPane isIpad isOpen={isViewDepoLeftUp} setOpen={setViewDepoLeftUp} item={selectMarkerDepoItem} />
      <TeikiKaishuLeftUp
        isIpad
        isOpen={isViewTeikiKaishuLeftUp}
        setOpen={setViewTeikiKaishuLeftUp}
        item={selectMarkerTeikiItem}
      />

      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={message} />
      <HikitoriModal {...{ item: selectMarkerIraiItem, setViewkaishuIraiLeftUp }} />
      <PictureModal isOpen={isPictureModal} control={pictureModalControl} item={selectMarkerIraiItem} />
    </div>
  );
}
