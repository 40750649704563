import React from "react";
import { Link } from "react-router-dom";
export default () => {
  return (
    <>
      <div
        className="overlay overlay--primary"
        style={{ display: "block" }}
      ></div>
      <div className="modal modal--registration" style={{ display: "block" }}>
        <div className="resistration">
          <div className="resistration__pic">
            <img src="/assets/Images/pic_resistration.svg" alt="" />
          </div>
          <div className="resistration__title">
            パスワード登録するだけで
            <br />
            簡単・便利にご利用いただけます
          </div>
          <div className="resistration__detail">
            <ul>
              <li>回収タイミングを自動でお知らせ</li>
              <li>依頼内容の編集やキャンセル</li>
              <li>手配状況をまとめて管理</li>
            </ul>
          </div>
          <div className="btn-layout --col-1 pl--16 pr--16">
            <a href="password-resistration.html" className="btn --primary">
              <span>登録して利用する</span>
            </a>
          </div>
        </div>
        <div className="btn-layout --col-1">
          <Link to="/irai" className="btn --wht-primary modalClose">
            <span>登録せずに回収依頼する</span>
          </Link>
        </div>
        <div className="mt--25">
          <label className="jc--c">
            <input type="checkbox" />
            <span></span>
            <span className="txt --fz-s">今後このメッセージを表示しない</span>
          </label>
        </div>
      </div>
    </>
  );
};
