import React, { useState, useEffect, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../AppContext";
import Header from "./Header";
import InfoModal from "../modals/InfoModal";
import ErrorModal from "../modals/ErrorModal";
import { useBoolean } from "../hooks/useBoolean";
import { useKeyDown } from "../hooks/useKeyDown";
import { createErrorMessage, isSP } from "../libs/common";
import { TextInput } from "../components/TextInput";

export default function () {
  const history = useHistory();
  const location = useLocation();
  // const isPreSignup = location.state && location.state.isPreSignup;
  const stateLoginCode = (location.state && location.state.loginCode) ?? "";

  const { loadMe } = useContext(AppContext);
  const weakLoginKey = sessionStorage.getItem("weakLoginKey");

  const [isInfoOpen, infoControl] = useBoolean(false);
  const [isErrorOpen, errorControl] = useBoolean(false);
  const [isAboutOpen, aboutControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tokuisakiName, setTokuisakiName] = useState("");
  const [loginCode, setLoginCode] = useState(stateLoginCode);
  const [password, setPassword] = useState("");

  const keyDownRegister = useKeyDown();

  const executableLogin = useMemo(() => {
    if (weakLoginKey) {
      if (!!!password) return false;
    } else {
      if (!!!loginCode || !!!password) return false;
    }

    return true;
  }, [weakLoginKey, loginCode, password]);

  const onClickLogin = async (e) => {
    e.preventDefault();

    let f = {
      password,
    };
    // PreSignup.js から遷移した場合
    // if (isPreSignup || !!!loginCode) {
    if (!!!loginCode) {
      f.weakLoginKey = weakLoginKey;
    } else {
      f.loginCode = loginCode;
    }
    try {
      // ログイン処理
      await axios.post("/api/login", f);
      // ログイン後ridirect
      let isLoaded = await loadMe();
      if (isLoaded) {
        const baseRedirectPage = isSP() ? "/mypage" : "/dashboard";
        const to = (location.state && location.state.from) ?? baseRedirectPage;
        history.replace(to);
      }
    } catch (error) {
      console.warn(error.response);
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorControl.on();
    }
  };

  const onPasswordReset = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/api/password-reset-mail", { loginCode });
      infoControl.off();
    } catch (error) {
      infoControl.off();
      console.warn(error.response);
      const message = createErrorMessage(error);
      setErrorMessage(message);
      errorControl.on();
    }

    // history.push("/password-reset");
  };

  useEffect(() => {
    if (!!weakLoginKey) {
      axios
        .post("/api/weak-me", { weakLoginKey })
        .then((res) => {
          if (res && res.data && res.data.kokyaku) {
            setTokuisakiName(res.data.kokyaku.ryaku);
          }
        })
        .catch((error) => {
          console.error("weak-me error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header login />
      <div className="main">
        <div className="main__main">
          {/* <pre>{JSON.stringify(history, null, 2)}</pre> */}
          <div className="login">
            <div className="login__title">ログイン</div>
            {weakLoginKey && <div className="login__company">{tokuisakiName} 様</div>}
            <div className="login__form">
              {!!!weakLoginKey && (
                <TextInput
                  title="ログインID"
                  value={loginCode}
                  onChange={(e) => setLoginCode(e.target.value)}
                  placeholder="X桁の数字"
                  type="text"
                  tabIndex={1}
                  keyDownRegister={keyDownRegister}
                />
              )}
              <TextInput
                addClass="--full"
                title="パスワード"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="英・数字6文字以上"
                type="password"
                tabIndex={2}
                keyDownRegister={keyDownRegister}
              />
            </div>
            <div className={`login__about ${isSP() && "for-pc"}`}>
              <a
                href="#"
                className="link --secondary modalOpen"
                rel="aboutid"
                onClick={(e) => {
                  e.preventDefault();
                  aboutControl.on();
                }}
              >
                ログインIDとは？
              </a>
            </div>
            <div className="login__forget">
              <a
                href="#"
                className="link --secondary paneOpen"
                rel="repassword"
                onClick={(e) => {
                  e.preventDefault();
                  infoControl.on();
                }}
              >
                パスワードを忘れた方
              </a>
            </div>
            <div className="login__btn">
              <div className="btn-layout --stack">
                <div>
                  <a
                    onClick={onClickLogin}
                    href="#"
                    className="btn --primary"
                    disabled={!!!executableLogin}
                    id="jsSubmit-Login"
                    {...keyDownRegister(3)}
                  >
                    <span>ログイン</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>

      {isAboutOpen && (
        <div className="overlay" style={{ display: "block" }}>
          <div className="modal modal--aboutid" style={{ display: "block" }}>
            <div className="txt --fz-m --bold ta--c">ログインIDとは？</div>
            <div className="txt --fz-s ta--c mt--8">
              1社ごとに割り振られたIDです。
              <br />
              スマホ用ページの「パスワード登録画面」、または「マイページ」からご確認いただけます。
            </div>
            <div className="layout --col-2-fix">
              <div>
                <div className="hdg  --h2">
                  <div className="hdg__title">パスワード登録画面</div>
                </div>
                <div className="img --full">
                  <img src="/assets/Images/pic_aboutid-01.png" alt="" />
                </div>
              </div>
              <div>
                <div className="hdg  --h2">
                  <div className="hdg__title">マイページ</div>
                </div>
                <div className="img --full">
                  <img src="/assets/Images/pic_aboutid-02.png" alt="" />
                </div>
              </div>
            </div>
            <div className="btn-layout --col-1">
              <div>
                <a
                  href="#"
                  className="btn --wht-primary --h-s --w-s modalClose"
                  onClick={(e) => {
                    e.preventDefault();
                    aboutControl.off();
                  }}
                >
                  <span>閉じる</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <InfoModal
        isOpen={isInfoOpen}
        control={infoControl}
        message={"管理者様のメールアドレスに\nパスワード再設定用のURLを送ります。\nよろしいですか？"}
        onOk={onPasswordReset}
      />
      <ErrorModal isOpen={isErrorOpen} control={errorControl} title="ログインエラー" message={errorMessage} />
    </>
  );
}
