import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../AppContext";

const links = [
  { name: "ダッシュボード", to: "/dashboard" },
  { name: "回収依頼", to: "/irai" },
  { name: "手配状況", to: "/tehai-jyokyo" },
  { name: "入出荷履歴", to: "/nyushukka-rireki" },
  { name: "マイページ", to: "/mypage" },
];

const MainNaviPc = (props) => {
  const { current } = props;
  const { me } = useContext(AppContext);

  if (me == null) {
    return null;
  }

  return (
    <div className="main__navi">
      <div className="navi">
        <ul>
          {links.map(({ name, to }) => (
            <li key={name + to}>
              <Link key={name + to} to={to} className={to === current ? "--current" : ""}>
                {name}
              </Link>
            </li>
          ))}
          <li class="navi--manual">
            <a href="/assets/r03sdhy219/Manual_PC.pdf" target="_blank">
              使い方マニュアルをみる
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(MainNaviPc);
