import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "./Header";

export default function () {
  useEffect(() => {}, []);

  return (
    <>
      <div className="main">
        <div className="main__main">
          <div className="main__main-header"></div>
          <div className="main__main-contents">
            <div className="inner">
              <div className="frame --center">
                <div className="ta--c">
                  <img src="/assets/admin/Images/pic_404.svg" alt="" />
                </div>
                <div className="hdg --message mt--40">ページが見つかりませんでした</div>
                <div className="txt --fz-s ta--c mt--16">
                  お探しのページを見つけることができませんでした。お手数ですがトップよりサイドアクセスしてください。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
