import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Transition } from "react-transition-group";

import { AppContext } from "../AppContext";

export default () => {
  const [interop, setInterop] = useState(false);
  const [navigate, setNavigate] = useState(false);
  const { loadWeakMe } = useContext(AppContext);
  const params = useParams();
  const history = useHistory();
  const signupKey = params.key || "";
  // 現状、autoLoginKey を setItem() している個所がないので無効。
  const autoLoginKey = localStorage.getItem("autoLoginKey");
  useEffect(() => {
    if (navigate) {
      (async () => {
        try {
          let res = await axios.post("/api/check-login", {
            autoLoginKey,
            signupKey,
          });
          if (res && res.data) {
            if (res.data.autoLoginKey && res.data.autoLoginKey === autoLoginKey) {
              // history.replace("/mypage");
              return;
            }

            // 既にEmailなど登録済
            if (res.data.loginCode) {
              sessionStorage.removeItem("weakLoginKey");
              history.replace("/login", { loginCode: res.data.loginCode });
              return;
            }

            // 弱いログインUUIDを取得
            sessionStorage.setItem("weakLoginKey", res.data.weakLoginKey);

            // 非ログインでも依頼できるようにマスター系取得
            await loadWeakMe(res.data.weakLoginKey);

            // history.replace("/irai", { state: { isPreSignup: true } });
            history.replace("/irai");
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [autoLoginKey, history, signupKey, loadWeakMe, navigate]);

  useLayoutEffect(() => {
    setInterop(true);
  }, []);

  return (
    <Transition
      in={interop}
      timeout={1500}
      classNames="splash"
      unmountOnExit
      onEntered={() => setInterop(false)}
      onExited={() => setNavigate(true)}
    >
      <div className="splash splash-inout">
        <div className="splash__logo">
          <img src="/assets/Images/lgo-v.png" alt="SANKO PALLET STATION" />
        </div>
      </div>
    </Transition>
  );
};
