import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

dayjs.locale(ja);

export const formatDate = (date, format, supplement = "") => {
  if (typeof date === "string") {
    const m = date?.match(/^(\d{4})(\d{2})(\d{2})$/);
    if (m) {
      date = m[1] + "/" + m[2] + "/" + m[3];
    }
  }
  try {
    return dayjs(date).format(format) + (supplement || "");
  } catch (error) {
    console.warn(error);
  }
};

export const toDayjs = (v) => {
  return dayjs(v);
};

export const isSameDay = (a, b) => {
  return dayjs(a).format("YYYYMMDD") === dayjs(b).format("YYYYMMDD");
};

export const isCompareDay = (a, b) => {
  return dayjs(a).isBefore(dayjs(b));
};

export const oneDayAfter = (d) => {
  const day_ = dayjs().toDate();
  return day_.setDate(d.getDate() + 1);
};

export const today = dayjs().toDate();
export const todayYmd = dayjs().format("YYYYMMDD");

const cloneDate = new Date(today.getTime());
export const basedate =
  cloneDate.getHours() < 17
    ? new Date(cloneDate.setDate(cloneDate.getDate() + 3))
    : new Date(cloneDate.setDate(cloneDate.getDate() + 4));
export const initialMonth = new Date(basedate.getFullYear(), basedate.getMonth());

export const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];

export const weekdaysShort = ["日", "月", "火", "水", "木", "金", "土"];
export const weekdaysLong = ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];

export const twoDaysAfter = dayjs().add(2, "day").format("YYYYMMDD");
export const threeDaysAfter = dayjs().add(3, "day").format("YYYYMMDD");

export const harfYearAfter = dayjs().add(6, "month").toDate();

export const getDisplayKiboDate = (from, to) => {
  let label = "";
  if (!!!to) {
    return "";
  }
  if (from === to) {
    label = dayjs(from).format("M/D(ddd)");
  } else {
    if (!!!from) {
      label = dayjs(to).format("M/D(ddd)") + "迄";
    } else {
      label = dayjs(from).format("M/D(ddd)") + "－" + dayjs(to).format("M/D(ddd)") + "間";
    }
  }
  return label;
};

export const getDisplayKiboTime = (from, to, biko) => {
  let label = "";
  if (!!!from || !!!to) {
    return biko || "";
  }
  label = dayjs("2021/01/01 " + from).format("HH:mm") + "～" + dayjs("2021/01/01 " + to).format("HH:mm");
  return label;
};

export const getYasumiakeDay = (holidays) => {
  var base = basedate;
  base.setDate(base.getDate() - 3);
  const todayYmd = formatDate(base, "YYYYMMDD");
  base.setDate(base.getDate() + 1);
  const tomorrow = formatDate(base, "YYYYMMDD");
  base.setDate(base.getDate() + 1);
  const afterTomorrow = formatDate(base, "YYYYMMDD");
  base.setDate(base.getDate() + 1);
  const afterTomorrow2 = formatDate(base, "YYYYMMDD");

  //今日または明日が休みでない
  if (
    todayYmd !== holidays[0] &&
    tomorrow !== holidays[0] &&
    afterTomorrow !== holidays[0] &&
    afterTomorrow2 !== holidays[0]
  ) {
    return basedate;
  }

  if (todayYmd === holidays[0]) {
    for (var i = 1; i < 10; i++) {
      if (
        holidays[i] >
        dayjs()
          .add(i + 1, "day")
          .format("YYYYMMDD")
      ) {
        return basedate.getHours() < 17
          ? dayjs()
              .add(i + 3, "day")
              .toDate()
          : dayjs()
              .add(i + 4, "day")
              .toDate();
      }
    }
  } else if (tomorrow === holidays[0]) {
    for (i = 1; i < 10; i++) {
      if (
        holidays[i] >
        dayjs()
          .add(i + 2, "day")
          .format("YYYYMMDD")
      ) {
        return basedate.getHours() < 17
          ? dayjs()
              .add(i + 3, "day")
              .toDate()
          : dayjs()
              .add(i + 4, "day")
              .toDate();
      }
    }
  } else if (afterTomorrow === holidays[0]) {
    for (i = 1; i < 10; i++) {
      if (
        holidays[i] >
        dayjs()
          .add(i + 3, "day")
          .format("YYYYMMDD")
      ) {
        return basedate.getHours() < 17
          ? dayjs()
              .add(i + 3, "day")
              .toDate()
          : dayjs()
              .add(i + 4, "day")
              .toDate();
      }
    }
  } else {
    for (i = 1; i < 10; i++) {
      if (
        holidays[i] >
        dayjs()
          .add(i + 4, "day")
          .format("YYYYMMDD")
      ) {
        return basedate.getHours() < 17
          ? dayjs()
              .add(i + 3, "day")
              .toDate()
          : dayjs()
              .add(i + 4, "day")
              .toDate();
      }
    }
  }
  return basedate;
};

export const getSecondDay = (d) => {
  console.log(d);
  if (d) {
    var day_2 = new Date();
    //月末 31日等でsetMonthをすると繰り上げが起こるため
    //3/31 で 4月をセットすると5月になる
    day_2.setDate(1);
    day_2.setMonth(d.getMonth());
    day_2.setDate(d.getDate() + 1);
    if (day_2.getDay() === 0) {
      return day_2.setDate(day_2.getDate() + 1);
    } else {
      return day_2;
    }
  }

  const day_ = new Date();
  day_.setDate(day_.getDate() + 3);
  if (day_.getDay() === 0) {
    return day_.setDate(day_.getDate() + 1);
  }
  return threeDaysAfter;
};
