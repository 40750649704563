import React, { memo } from "react";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import "antd/es/date-picker/style/index";
import "antd/dist/antd.css";
import "dayjs/locale/ja";
import jaJP from "antd/lib/locale/ja_JP";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateTimePicker from "antd/es/date-picker/generatePicker";
import { Helmet } from "react-helmet";

const AntTimePicker = generateTimePicker(dayjsGenerateConfig);

export const TimePicker = memo((props) => {
  const { time, setTime, keyDownRegister, tabIndex } = props;
  let pickerProps = {};
  if (!!keyDownRegister) {
    pickerProps = keyDownRegister(tabIndex);
  }

  return (
    <>
      <ConfigProvider locale={jaJP}>
        <AntTimePicker
          picker={"time"}
          format={"HH:mm"}
          placeholder=""
          minuteStep={30}
          showNow={false}
          disabledMinutes={false}
          clearIcon={undefined}
          value={time}
          onSelect={setTime}
          {...pickerProps}
          // inputReadOnly={true}
        />
      </ConfigProvider>

      <Helmet>
        <style>{`
            .ant-picker-dropdown {
              z-index: 999999;
            }
            .ant-picker-time-panel-column {
              overflow-y: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
            .ant-picker-time-panel-column::-webkit-scrollbar {
              display:none;
            }
          }
          `}</style>
      </Helmet>
    </>
  );
});
