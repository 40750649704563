import React, { memo, useMemo, useCallback, useContext, useState } from "react";
import ComboBox from "react-responsive-combo-box";
import "react-responsive-combo-box/dist/index.css";
import { AppContext } from "../AppContext";

export const TimeTextComboBox = memo((props) => {
  const { meishos } = useContext(AppContext);

  // 時間区分
  const optionList = useMemo(() => {
    return (meishos || [])
      .filter((v) => v.type === "TIME_KBN")
      .sort((a, b) => a.hyojiJun - b.hyojiJun)
      .map((v) => v.name);
  }, [meishos]);

  return <ComboBox {...props} selectedOptionColor="#e9f5f5" options={optionList} enableAutocomplete />;
});
