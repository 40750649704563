import React, { memo, useRef, useState } from "react";
import { useBoolean } from "../hooks/useBoolean";

export const TextInput = memo((prop) => {
  const {
    addClass,
    style,
    title,
    value,
    onChange,
    placeholder,
    type,
    inputmode = "text",
    unitLayout,
    tabIndex,
    keyDownRegister,
    validation,
  } = prop;
  const [isError, errorControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");
  const refTitle = useRef(null);
  const refEl = useRef(null);
  const [focued, setFocused] = useState(false);

  let register = null;
  if (tabIndex && keyDownRegister) {
    register = { ...keyDownRegister(tabIndex) };
  }
  const { ref, onKeyDown } = register || {};

  const _onChange = (e) => {
    setErrorMessage("");
    errorControl.off();
    if (!!validation) {
      if (validation.type === "number") {
        if (/^\d*$/.test(e.target.value)) {
          const val = parseInt(e.target.value);
          const max = validation.max || 0;
          const min = validation.min || 0;
          const unit = validation.unit || "";

          if (max !== 0 && val > max) {
            setErrorMessage(max + unit + "以上は入力できません");
            errorControl.on();
          }
          if (min !== 0 && val < min) {
            setErrorMessage(min + unit + "以下は入力できません");
            errorControl.on();
          }
        }
      } else {
        setErrorMessage("");
        errorControl.off();
      }
    }

    onChange(e);
  };

  return (
    <div className={`form-layout ${addClass || ""} ${focued && "--current"}`} {...{ style }}>
      <div
        ref={refTitle}
        className="form-layout__title"
        onClick={() => {
          if (refEl.current) {
            refEl.current.focus();
          }
        }}
      >
        <span>{title}</span>
      </div>
      <div className="form-layout__detail">
        <div className="form-detail-layout">
          <span ref={refEl} className="form-detail-layout__value">
            <input
              type={type}
              placeholder={placeholder}
              inputMode={inputmode}
              value={value}
              onChange={_onChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onKeyDown={onKeyDown}
              ref={(el) => {
                refEl.current = el;
                if (!!ref) ref(el);
              }}
              style={style}
            />
          </span>
          {unitLayout}
        </div>
        {isError && (
          <div style={{ fontSize: "12px", color: "#f00", textAlign: "right", paddingRight: "16px" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});
