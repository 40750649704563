import axios from "axios";

export const KIBOBI_KBN = {
  NONE: "0",
  TWO: "1",
  THREE: "2",
  KIGEN_SITEI: "3",
  KIKAN_SITEI: "4",
};

export const KIBOTIME_KBN = {
  NONE: "0",
  AM: "1",
  PM: "2",
  SONOTA: "3",
};

export const NOTIFY_KBN = {
  MAIL: "0",
  TEL: "1",
};

export const KIBOSHASHU_KBN = {
  NONE: "0",
  WING: "1",
  ROLLER: "2",
  JORDER: "3",
  KANNON: "4",
};

export const NIYAKU_KBN = {
  LEASE: "1",
  JISHA: "2",
  COMMON: "3",
};

export const SORT_KBN = {
  NEW: 1,
  NEAR: -1,
};

export const EDIT_USER_KBN = {
  NEW: "0",
  EDIT: "1",
};

export const TONYA_CHECK_KBN = {
  NONE: "0",
  CHECKTYU: "1",
  OK: "2",
  NG: "3",
};

export const UNSO_CHECK_KBN = {
  SITAGAKI: "0",
  IRAITHU: "1",
  OK: "2",
  NG: "3",
};

export const CANCEL_KBN = {
  NONE: "0",
  CANCELTYU: "1",
  ZUMI: "2",
};

export const isSP = () => {
  let matchMedia =
    window.matchMedia ||
    function () {
      return {
        matches: false,
      };
    };

  return !!matchMedia("(max-width: 1023px)").matches;
};

export const createErrorMessage = (error) => {
  console.warn(error);
  let message = "";
  if (error.response) {
    const { status = "", data = {} } = error.response;
    if (typeof data === "object" && "errors" in data) {
      message = Object.keys(data.errors).reduce((str, key) => {
        return str + data.errors[key].join("\n") + "\n";
      }, "");
    } else {
      message = "失敗しました";
    }
  } else {
    message = error.message;
  }
  return message;
};

export const formatNumber = (str) => {
  let num;
  if (str === void 0 || str === "" || str === null) {
    return "";
  }
  num = (str + "").replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)).replace(/[^0-9]/g, "");

  return Number(num).toLocaleString();
};

export const getMeishos = (meishos, type) => {
  return (meishos || [])
    .filter((meisho) => meisho.type === type)
    .sort((pre, nxt) => {
      if (pre.hyojiJun > nxt.hyojiJun) return 1;
      if (pre.hyojiJun < nxt.hyojiJun) return -1;
      return 0;
    });
};

export const getMeishoName = (meishos, val, joinKey) => {
  let label = "";
  if (!meishos || meishos.length === 0) return label;
  if (!val) return label;

  if (val.indexOf(",") !== -1) {
    const ary = val.split(",");
    const res = ary.map((d) => {
      return (meishos.find((x) => x.kbn === d) || {}).name;
    });
    label = res.join(joinKey || "、");
  } else {
    label = (meishos.find((x) => x.kbn === val) || {}).name;
  }

  return label;
};

export const getTeikikaishuLabel = (teikis, youbis) => {
  if (!!!teikis) return "－";
  if (teikis.every === "1") return "毎日";
  if (teikis.heijitu === "1") return "毎週平日";

  return (
    youbis
      .map((youbi) => {
        if (teikis.settings[parseInt(youbi.kbn)] === 1) {
          return youbi.name;
        }
        return "";
      })
      .filter((e) => !!e)
      .join("、") || "－"
  );
};

export const getKohyoData = async (item, meishos) => {
  if (item.type === "kaishuirai") {
    const ngShakakuList = getMeishos(meishos, "SHAKAKU_KBN");
    const kiboShashuList = getMeishos(meishos, "TONYA_SHASHU_KBN");
    const niyakuList = getMeishos(meishos, "NIYAKU_KBN");

    const res = await axios.get("/api/irai/" + item.id);
    const irai = res.data;

    const number = irai.meisais.reduce((total, m) => total + parseInt(m.suryo || "0"), 0);
    const pallet = irai.meisais.map((y) => y.shohinName).join("、");
    const ngShakakus = getMeishoName(ngShakakuList, irai.kiboNgShakakuKbns, "、");
    const kiboSharyo = getMeishoName(kiboShashuList, irai.kiboSharyoKbn);
    const kiboNiyaku = getMeishoName(niyakuList, irai.kiboNiyakuKbn);

    const res2 = await Promise.all([
      axios.get("/api/tonya/" + irai.kokyakuId),
      axios.get("/api/tonya-shukkamoto?kokyakuId=" + irai.kokyakuId),
    ]).then((rs) =>
      rs.reduce((obj, cur) => {
        // irai.bikoとかぶるので削除(備考だけか)
        const tmpData = cur.data;
        delete tmpData.biko;
        return { ...obj, ...tmpData };
      }, {})
    );
    return { ...item, ...res.data, ...res2, number, pallet, ngShakakus, kiboSharyo, kiboNiyaku };
  }
  if (item.type === "tonya") {
    const res = await Promise.all([
      axios.get("/api/tonya/" + item.id),
      axios.get("/api/tonya-shukkamoto?kokyakuId=" + item.id),
    ]).then((rs) => rs.reduce((obj, cur) => ({ ...obj, ...cur.data }), {}));
    return { ...item, ...res };
  }
  if (item.type === "depo") {
    const res = await axios.get("/api/depo/" + item.id);
    return { ...item, ...res.data };
  }
  if (item.type === "teikikaishu") {
    const res = await Promise.all([
      axios.get("/api/tonya/" + item.kokyakuCode),
      axios.get("/api/tonya-shukkamoto?kokyakuId=" + item.kokyakuCode),
      // axios.get("/api/teiki/" + item.id), // TODO 落ちる
    ]).then((rs) => rs.reduce((obj, cur) => ({ ...obj, ...cur.data }), {}));
    return { ...item, ...res };
  }

  return {};
};

export const actionLog = async (title, action, biko) => {
  const url = window.location.href;
  const queryParam = window.location.search;
  const f = { title, action, biko, url, queryParam };
  await axios.post("/api/actionlog", f);
};
