import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../AppContext";
import { AdminAppContext } from "../AdminAppContext";
import { Select } from "../components/Select";
import { DateTimePicker } from "../components/DateTimePicker";
import { TimePicker } from "../components/TimePiker";
import { TimeTextComboBox } from "../components/TimeTextComboBox";
import { TaniComboBox } from "../components/TaniComboBox";
import { ShashuComboBox } from "../components/ShashuComboBox";
import { InfoModal, SaveModal, SendModal } from "../modals/admin/Dialogs";
import ErrorModal from "../modals/ErrorModal";
import { useBoolean } from "../hooks/useBoolean";
import { useKeyDown } from "../hooks/useKeyDown";
import { createErrorMessage, getMeishos, actionLog, TONYA_CHECK_KBN, UNSO_CHECK_KBN } from "../libs/common";
import { formatDate, toDayjs } from "../libs/date";

export default function ({
  onMoveMapCenter,
  onMapReload,
  onDetail,
  binId = null,
  setBinId,
  gridList,
  createTable,
  setHaishaList,
}) {
  const { me, meishos, unsos = [] } = useContext(AppContext);
  const {
    keiroList,
    setKeiroList,
    haishaInfo,
    setHaishaInfo,
    sendHaishaData,
    isViewBinShosaiPane,
    setViewBinShosaiPane,
    onDownKeiroItem,
    onUpKeiroItem,
    cancelBin,
  } = useContext(AdminAppContext);
  const location = useLocation();

  const [isSaveModal, saveModalControl] = useBoolean(false);
  const [isSendModal, sendModalControl] = useBoolean(false);
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const refOkEvent = useRef();
  const [hikitoriUnso, setHikitoriUnso] = useState(null);

  // 車両情報
  const [sharyos, setSharyos] = useState([]);

  const sekisaiMaster = useMemo(() => {
    try {
      return {
        4: parseInt(getMeishos(meishos, "TOSAI_RITUSU_4T")[0].name),
        10: parseInt(getMeishos(meishos, "TOSAI_RITUSU_10T")[0].name),
      };
    } catch {
      return null;
    }
  }, [meishos]);

  const inputFileRef = useRef();
  const keyDownRegister = useKeyDown();
  // ロジルートの車種リスト
  // const shashuList = useMemo(
  //   () => getMeishos(meishos, "SHASHU_KBN"),
  //   [meishos]
  // ).map((x) => ({
  //   code: x.kbn,
  //   name: x.name,
  // }));
  // 選択可能運送会社
  const unsoList = useMemo(() => {
    return (unsos || []).map((x, i) => ({
      code: x.kokyakuId,
      name: x.depoName,
      check: x.haishaFlg,
    }));
  }, [unsos]);

  const middleKeiro = useMemo(() => {
    return keiroList
      ?.filter((x) => x.type !== "depo")
      .map((x) => ({ ...x, subType: x.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && "hikitori" }));
  }, [keiroList]);

  const lastDepo = useMemo(() => {
    return keiroList?.find((x) => x.type === "depo");
  }, [keiroList]);

  const sekisaiRitu = useMemo(() => {
    if (!!middleKeiro && middleKeiro.length > 0 && !!sekisaiMaster) {
      const total = middleKeiro.reduce((t, c) => t + (c.number || 0), 0);
      const rate4 = Math.floor((total / sekisaiMaster["4"]) * 100);
      const rate10 = Math.floor((total / sekisaiMaster["10"]) * 100);
      return {
        4: rate4,
        color4: rate4 > 100 ? "red" : "green",
        10: rate10,
        color10: rate10 > 100 ? "red" : "green",
      };
    }
    return {};
  }, [middleKeiro, sekisaiMaster]);

  const onFileSelectHaishaInfo = useCallback(
    async (e) => {
      const files = e.target.files;
      try {
        if (files && files.length) {
          const tmps = [];
          const formData = new FormData();
          for (let file of files) {
            const blob = URL.createObjectURL(file);
            formData.append("file", file, file.name);
            tmps.push({ source: blob, name: file.name });
          }
          const res = await axios.post("/api/file/tmp", formData);
          if (res && res.data) {
            const newFiles = (res.data || []).map((path, index) => ({
              path,
              ...tmps[index],
            }));
            setHaishaInfo((prev) => ({ ...prev, files: newFiles }));
          }
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [setHaishaInfo]
  );

  const executable = useMemo(() => {
    // TODO もうちょっとチェック
    if (!middleKeiro || middleKeiro.length === 0) return false;
    const firstIrai = middleKeiro[0];
    if (!firstIrai.hikitoriDate) {
      return false;
    }
    if (!lastDepo) return false;
    if (!haishaInfo) return false;

    return true;
  }, [middleKeiro, lastDepo, haishaInfo]);

  const checkKeiro = useCallback(() => {
    const firstIrai = keiroList[0];
    if (!firstIrai.hikitoriDate) {
      alert("回収日時を入力して下さい");
      return false;
    }

    return true;
  }, [keiroList]);

  const closeBinShosaiPane = useCallback(
    (e) => {
      if (!!e) {
        e.preventDefault();
      }
      // setSelectRow(null);
      setBinId(null);
      setKeiroList([]);
      setHaishaInfo({});
      setViewBinShosaiPane(false);
    },
    [setBinId, setKeiroList, setHaishaInfo, setViewBinShosaiPane]
  );

  const displayClear = useCallback(
    (res, binId) => {
      const { pathname } = location;

      if (/\/admin\/irai-list/.test(pathname)) {
        const { irais } = res;
        const newDisplayList = [...gridList].filter((x) => !(irais || []).some((y) => y.id === x.id));
        createTable(newDisplayList);
        onMapReload();
        sendModalControl.off();
      }
      if (/\/admin\/haisha-list/.test(pathname)) {
        setHaishaList((prev) => prev.filter((e) => e.id !== binId));
        infoModalControl.off();
      }
      closeBinShosaiPane();
    },
    [
      location,
      onMapReload,
      sendModalControl,
      infoModalControl,
      gridList,
      createTable,
      setHaishaList,
      closeBinShosaiPane,
    ]
  );

  // 保存する
  const onClickSave = useCallback(
    async (e) => {
      e.preventDefault();

      await actionLog("新規注文 保存", null, null);
      const res = await sendHaishaData({});
      if (res) {
        saveModalControl.on();
      }
    },
    [sendHaishaData, saveModalControl]
  );

  // 送信する
  const onClickSend = useCallback(
    async (e) => {
      e.preventDefault();
      console.info("onSend", keiroList, haishaInfo);

      if (!haishaInfo.unsoName) {
        // TODO 表現をどうにかする
        alert("運送会社を選択してください。");
      } else {
        refOkEvent.current = async (e) => {
          e.preventDefault();

          await actionLog("新規注文 データ送信", null, null);

          // 運送チェック区分を設定する
          const unsoCheckKbn = TONYA_CHECK_KBN.CHECKTYU;

          // データ送信処理API連携
          const res = await sendHaishaData({ unsoCheckKbn });
          if (res) {
            displayClear(res, 0);
          }
          sendModalControl.off();
        };

        sendModalControl.on();
      }
    },
    [keiroList, haishaInfo, sendHaishaData, sendModalControl, displayClear]
  );

  const onClickCancel = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        refOkEvent.current = async (e) => {
          e.preventDefault();
          await cancelBin(binId);
          displayClear({}, binId);
        };

        setInfoMessage("この便を削除します。\n よろしいですか？");
        infoModalControl.on();
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [cancelBin, binId, displayClear, infoModalControl, errorModalControl]
  );

  const loadBin = useCallback(async () => {
    if (binId === null) {
      return;
    }
    try {
      const res = await axios.get("/api/fbin/" + binId);
      if (res && res.data) {
        const { nyukoDepo, irais, sharyos, ...haishaInfo } = res.data;
        if (!!setHaishaInfo) {
          // 回答期限展開
          setHaishaInfo({
            ...haishaInfo,
            kaitokigenDay: toDayjs(haishaInfo.kaitokigenDate),
            kaitokigenTime: toDayjs(haishaInfo.kaitokigenDate),
          });
        }
        if (!!setKeiroList) {
          let tmpList = [
            ...irais,
            {
              ...nyukoDepo,
              id: nyukoDepo?.kokyakuId, // kokyakuidをidとする
              type: "depo",
              name: nyukoDepo?.depoName,
              jusho: nyukoDepo?.depoAddr + nyukoDepo?.depoAddr2 || "",
              nyukoDate: haishaInfo?.nyukoDate,
              nyukoTimeText: haishaInfo?.nyukoTimeText,
            },
          ];

          tmpList = tmpList.map((row) => {
            row.type = row.type || "kaishuirai";
            if (row.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU) {
              row.subType = "hikitori";
            }
            row.number = row.meisais?.reduce((total, m) => total + parseInt(m.suryo || "0"), 0);
            row.pallet = row.meisais?.map((x) => x.shohinName).join("、");

            return row;
          });
          // const depoCode = res.data.nyukoDepoId;
          // const res2 = await axios.get("/api/depo/" + depoCode);
          setKeiroList(tmpList);
        }
        setSharyos(sharyos);
      }
    } catch (ex) {
      console.error(ex);
    }
  }, [binId, setHaishaInfo, setKeiroList]);

  useEffect(() => {
    if (!!Object.keys(sekisaiRitu).length) {
      setHaishaInfo((prev) => ({ ...prev, sekisaiRitu4t: sekisaiRitu["4"], sekisaiRitu10t: sekisaiRitu["10"] }));
    }
  }, [setHaishaInfo, sekisaiRitu]);

  useEffect(
    (e) => {
      if (!setHaishaInfo || !setKeiroList) {
        return;
      }
      if (binId === null) {
        setHaishaInfo({});
        setKeiroList([]);
        return;
      }
      loadBin();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [binId, setHaishaInfo, setKeiroList]
  );

  useEffect(() => {
    const unsoCodeSiteiItem = (keiroList || []).find((e) => !!e.hikitoriUnsoCode);
    if (!!unsoCodeSiteiItem) {
      const hikitorUnso = unsoList.find((e) => e.code === unsoCodeSiteiItem.hikitoriUnsoCode);
      setHikitoriUnso(hikitorUnso);
      if (!!hikitorUnso) {
        setHaishaInfo((pre) => ({
          ...pre,
          unsoCode: hikitorUnso.code,
          unsoName: hikitorUnso.name,
        }));
      }
    } else {
      setHikitoriUnso(null);
    }
  }, [unsoList, keiroList, setHaishaInfo]);

  // TODO 正しいステータスを戻す
  const haishaInfoStatus = () => {
    return (
      <div class="hdg__status">
        <div class="status --attention --exclamation">
          <span>依頼中｜回収店NG</span>
        </div>
      </div>
    );
  };

  // TODO アラート部分
  const haishaAlert = () => {
    return (
      <div className="alert">
        <div className="alert__inner">
          <div className="alert__message">回収店からの引取依頼NGです。引取依頼を変更してください。</div>
          <div className="alert__comment">コメント：運送会社コメントが入ります</div>
        </div>
      </div>
    );
  };

  const unsoAnswer = useMemo(() => {
    if (!sharyos || sharyos.length === 0) {
      return null;
    }

    const unsoCheckKbn = haishaInfo?.unsoCheckKbn || "0";
    if ([UNSO_CHECK_KBN.SITAGAKI, unsoCheckKbn.CHECKTYU].includes(unsoCheckKbn)) {
      return null;
    }
    const kakuteiUnchin = keiroList.reduce(
      (t, cur) => t + parseFloat(cur.unsoSuryo ?? 0) * parseFloat(cur.unsoTanka ?? 0),
      0
    );
    return (
      <div className="answer">
        <div className="answer__header">
          <span>運送会社回答</span>
          <span>{unsoCheckKbn === UNSO_CHECK_KBN.NG ? "配車不可" : "配車可"}</span>
        </div>
        <div className="answer__body">
          {sharyos?.map((sharyo, i) => (
            <div key={sharyo.id.toString()} className="answer__item">
              <div className="answer__num">
                <span className="num --kaishuirai --s">{i + 1}</span>
                {/* <span className="num --tonya --s">{i + 1}</span> */}
              </div>
              <div className="answer__text">
                <div>
                  <div>{`車種：${sharyo.shashu}`}</div>
                  <div>{`車番：${sharyo.shaban ?? ""}`}</div>
                </div>
                <div>
                  <div>{`料金：¥${kakuteiUnchin.toLocaleString()}`}</div>
                  <div>{`所属：${sharyo.subUnsoName ?? ""}`}</div>
                </div>
                <div>
                  <div>{`ドライバー：${sharyo.untenshu ?? ""}`}</div>
                  <div>{`TEL：${sharyo.untenshuTel ?? ""}`}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [haishaInfo, sharyos, keiroList]);

  return (
    <>
      <div className={`pane pane--full pane--shinkichumon ${isViewBinShosaiPane && "isOpen"}`}>
        <div className="pane__header">
          <div className="hdg">
            <div className="hdg__text">{binId === null ? "新規注文" : "注文詳細"}</div>
            {haishaInfoStatus}
            <a href="#" className="hdg__close paneClose" rel="shinkichumon" onClick={closeBinShosaiPane}>
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                  fill="#888888"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="pane__body --bg-gray">
          {haishaAlert}
          <div className="pane-layout --col2">
            <div>
              <div className="pane-layout__inner">
                <div className="title --border">
                  <div className="title__title --bold">経路</div>
                  <div className="title__total">合計</div>
                  <div className="title__load">
                    <span className={`ico --truck-4t-${sekisaiRitu?.color4} --l --fz-m`}>
                      {sekisaiRitu["4"] ?? "－"}%
                    </span>
                  </div>
                  <div className="title__load">
                    <span className={`ico --truck-10t-${sekisaiRitu?.color10} --l --fz-m`}>
                      {sekisaiRitu["10"] ?? "－"}%
                    </span>
                  </div>
                  <div className="title__distance">
                    距離：
                    <span>
                      {haishaInfo?.totalKyori ? Math.floor(haishaInfo.totalKyori / 1000).toLocaleString() : "－"}
                      km
                    </span>
                  </div>
                </div>
                <div className="route">
                  {middleKeiro?.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <div className="route-header">
                        <div className="route-header__num">
                          <span className={`num --${item.type}`}>{(index + 1).toString()}</span>
                        </div>
                        <div className="route-header__company">
                          <div>{item?.name}</div>
                          <div>{item?.address}</div>
                        </div>
                        <div className="route-header__down">
                          <a href="#">
                            <svg
                              width="19"
                              height="18"
                              viewbox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={(e) => onDownKeiroItem(e, index)}
                            >
                              <path
                                d="M18.293 9L16.3955 7.10258L10.6348 12.8633V0H7.95109V12.8633L2.19039 7.10258L0.292969 9L9.29297 18L18.293 9Z"
                                fill="#279E9E"
                              ></path>
                            </svg>
                          </a>
                        </div>
                        <div className="route-header__up">
                          <a href="#">
                            <svg
                              width="19"
                              height="18"
                              viewbox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={(e) => onUpKeiroItem(e, index)}
                            >
                              <path
                                d="M0.29297 9L2.19039 10.8974L7.95109 5.13672L7.95109 18L10.6349 18L10.6349 5.13672L16.3955 10.8974L18.293 9L9.29297 1.12054e-06L0.29297 9Z"
                                fill="#279E9E"
                              ></path>
                            </svg>
                          </a>
                        </div>
                        <div className="route-header__detail">
                          <a
                            href="javascript:void(0);"
                            className="link --secondary --fz-14 --bold paneOpen paneLeftDownClose"
                            rel="kaishuirai-left"
                            onClick={(e) => onDetail(e, item)}
                          >
                            詳細
                          </a>
                        </div>
                      </div>
                      <div className="route-body">
                        <div className="route-body__item">
                          <div className="route-body__title">回収パレット数</div>
                          <div className="route-body__value">{item?.number?.toLocaleString()}枚</div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">パレットの種類</div>
                          <div className="route-body__value">{item?.pallet}</div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">回収希望日</div>
                          <div className="route-body__value">
                            {item.kiboDateFrom || item.kiboDateTo
                              ? formatDate(item.kiboDateFrom, "M月D日(ddd)") +
                                "～" +
                                formatDate(item.kiboDateTo, "M月D日(ddd)")
                              : ""}
                          </div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">回収希望時間</div>
                          <div className="route-body__value">
                            {item.kiboTimeKbn === "0" || item.kiboTimeKbn === "3"
                              ? item?.kiboTimeBiko
                                ? item?.kiboTimeBiko
                                : ""
                              : (item?.kiboTimeFrom ? item?.kiboTimeFrom + "～" : "") +
                                (item?.kiboTimeTo
                                  ? item?.kiboTimeFrom
                                    ? item?.kiboTimeTo
                                    : "～" + item?.kiboTimeTo
                                  : "")}
                          </div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">回収日時指定</div>
                          <div className="route-body__value">
                            <DateTimePicker
                              day={item?.hikitoriDate}
                              setDay={(d) => {
                                const tmpList = [...keiroList].map((v, i) => {
                                  if (i === index) {
                                    v.hikitoriDate = d;
                                  }
                                  return v;
                                });
                                setKeiroList(tmpList);
                              }}
                              pastPermission={false}
                            />
                            <TimeTextComboBox
                              className="dsp--inline-block w--80 ml--5"
                              defaultValue={item?.hikitoriTimeText}
                              onChange={(e) => {
                                const hikitoriTimeText = e.target.value;
                                const tmpList = [...keiroList].map((v, i) => {
                                  if (i === index) {
                                    v.hikitoriTimeText = hikitoriTimeText;
                                  }
                                  return v;
                                });
                                setKeiroList(tmpList);
                              }}
                              onSelect={(hikitoriTimeText) => {
                                const tmpList = [...keiroList].map((v, i) => {
                                  if (i === index) {
                                    v.hikitoriTimeText = hikitoriTimeText;
                                  }
                                  return v;
                                });
                                setKeiroList(tmpList);
                              }}
                            />
                          </div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">NG車格</div>
                          <div className="route-body__value">{item?.kiboNgShakakus}</div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">希望車種</div>
                          <div className="route-body__value">{item?.kiboSharyo}</div>
                        </div>
                        <div className="route-body__item" hidden>
                          <div className="route-body__title">希望荷役</div>
                          <div className="route-body__value">{item?.kiboNiyaku}</div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">注意事項</div>
                          <div className="route-body__value">{item?.biko}</div>
                        </div>
                        <div className="route-body__item">
                          <div className="route-body__title">回収店備考</div>
                          <div className="route-body__value">{item?.biko2}</div>
                        </div>
                        {item.tonyaCheckKbn === TONYA_CHECK_KBN.CHECKTYU && (
                          <div className="route-body__item">
                            <div className="route-body__title">引取依頼</div>
                            <div className="route-body__value">
                              <span class="ico --xs --exclamation txt --fz-s --bold">引取依頼確認中</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  <a
                    href="#"
                    className="route-header --add mapOpen"
                    onClick={(e) => {
                      e.preventDefault();
                      onMoveMapCenter();
                    }}
                  >
                    <div className="route-header__add">
                      <span className="link --secondary --fx-24 --bold">回収先を追加する</span>
                    </div>
                  </a>
                  <div className="route-body"></div>
                  {!!lastDepo ? (
                    <div className="route-header">
                      <div className="route-header__num">
                        <span className="num --goal">&nbsp;</span>
                      </div>
                      <div className="route-header__company">
                        <div>{lastDepo?.name ? lastDepo?.name : ""}</div>
                        <div>{lastDepo?.jusho ? lastDepo?.jusho : ""}</div>
                        <div>
                          <span>保有枚数</span>
                          <span>
                            <span
                              style={{
                                width: lastDepo?.hoyuKanoSuu
                                  ? (lastDepo.zaikoSuryo / lastDepo.hoyuKanoSuu) * 100
                                  : 0 + "%",
                              }}
                            ></span>
                          </span>
                          <span>{lastDepo?.zaikoSuryo?.toLocaleString() || "－"}枚</span>
                        </div>
                      </div>
                      <div className="route-header__detail">
                        <a
                          href="javascript:void(0);"
                          className="link --secondary --fz-14 --bold paneOpen"
                          rel="depo-left-up"
                          onClick={(e) => onDetail(e, lastDepo)}
                        >
                          詳細
                        </a>
                      </div>
                    </div>
                  ) : (
                    <a
                      href="javascript:void(0);"
                      className="route-header --add-wht paneOpen paneLeftDownClose"
                      rel="depo-left"
                      onClick={(e) => {
                        e.preventDefault();
                        onMoveMapCenter();
                      }}
                    >
                      <div className="route-header__num">
                        <span className="num --goal">&nbsp;</span>
                      </div>
                      <div className="route-header__add">
                        <span className="link --secondary --fx-24 --bold">デポを指定する</span>
                      </div>
                    </a>
                  )}
                </div>
              </div>

              {!!lastDepo && (
                <div className="warehousing">
                  <div className="warehousing__title">入庫日時指定</div>
                  <div className="warehousing__date">
                    <DateTimePicker
                      day={lastDepo?.nyukoDate}
                      setDay={(d) => {
                        const tmpList = [...keiroList].map((v, i) => {
                          if (i === keiroList.length - 1) {
                            v.nyukoDate = d;
                          }
                          return v;
                        });
                        setKeiroList(tmpList);
                      }}
                      pastPermission={false}
                    />
                    <TimeTextComboBox
                      className="dsp--inline-block w--80 ml--5"
                      defaultValue={lastDepo?.nyukoTimeText}
                      onChange={(e) => {
                        const nyukoTimeText = e.target.value;
                        const tmpList = [...keiroList].map((v, i) => {
                          if (i === keiroList.length - 1) {
                            v.nyukoTimeText = nyukoTimeText;
                          }
                          return v;
                        });
                        setKeiroList(tmpList);
                      }}
                      onSelect={(nyukoTimeText) => {
                        const tmpList = [...keiroList].map((v, i) => {
                          if (i === keiroList.length - 1) {
                            v.nyukoTimeText = nyukoTimeText;
                          }
                          return v;
                        });
                        setKeiroList(tmpList);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="pane-layout__inner">
                <div className="title">
                  <div className="title__title --bold">配車</div>
                  <div className="title__author">
                    作成者<span>{haishaInfo.updateUserName}</span>
                  </div>
                </div>
                <div className="form">
                  <div className="form__item">
                    <div className="form__title">依頼先運送会社</div>
                    <div className="form__value">
                      {!!hikitoriUnso ? (
                        <span className="--s">{haishaInfo.unsoName}</span>
                      ) : (
                        <Select
                          options={unsoList}
                          placeholder="運送会社を選択してください"
                          keyDownRegister={keyDownRegister}
                          tabIndex={1}
                          selectValue={haishaInfo?.unsoName}
                          setSelectValue={(data) => {
                            setHaishaInfo((prev) => ({
                              ...prev,
                              unsoCode: data.suggestion.code,
                              unsoName: data.suggestion.name,
                            }));
                          }}
                          width={320}
                        />
                      )}
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">希望車種</div>
                    <div className="form__value">
                      <ShashuComboBox
                        placeholder="希望車種を選択してください"
                        keyDownRegister={keyDownRegister}
                        tabIndex={2}
                        defaultValue={haishaInfo?.kiboShashu}
                        onChange={(e) => {
                          const kiboShashu = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, kiboShashu }));
                        }}
                        onSelect={(kiboShashu) => {
                          setHaishaInfo((prev) => ({ ...prev, kiboShashu }));
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="form__item">
                    <div className="form__title">希望台数</div>
                    <div className="form__value">
                      <input
                        type="text"
                        className="w--55 mr--5 ta--r"
                        defaultValue={1}
                        value={haishaInfo?.kiboDaisu || "1"}
                        onChange={(e) => {
                          const kiboDaisu = e.target.value;
                          if (kiboDaisu === "" || /^\d{1,}$/.test(kiboDaisu)) {
                            setHaishaInfo((prev) => ({ ...prev, kiboDaisu }));
                          }
                        }}
                        {...keyDownRegister(4)}
                      />
                      台
                    </div>
                  </div> */}
                  <div className="form__item">
                    <div className="form__title">希望運賃</div>
                    <div className="form__value">
                      <input
                        type="text"
                        className="w--55 mr--5 ta--r"
                        value={haishaInfo?.kiboSuryo || ""}
                        onChange={(e) => {
                          const kiboSuryo = e.target.value;
                          if (kiboSuryo === "" || /^\d{1,}$/.test(kiboSuryo)) {
                            setHaishaInfo((prev) => ({ ...prev, kiboSuryo }));
                          }
                        }}
                        {...keyDownRegister(5)}
                      />
                      <TaniComboBox
                        className="dsp--inline-block w--80 mr--5 ta--c"
                        defaultValue={haishaInfo?.kiboTani || ""}
                        onChange={(e) => {
                          const kiboTani = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, kiboTani }));
                        }}
                        {...keyDownRegister(6)}
                        onSelect={(kiboTani) => {
                          setHaishaInfo((prev) => ({ ...prev, kiboTani }));
                        }}
                      />
                      ×
                      <input
                        type="text"
                        className="w--55 mr--5 ta--r"
                        value={haishaInfo?.kiboTanka || ""}
                        onChange={(e) => {
                          const kiboTanka = e.target.value;
                          if (kiboTanka === "" || /^\d{1,}$/.test(kiboTanka)) {
                            setHaishaInfo((prev) => ({ ...prev, kiboTanka }));
                          }
                        }}
                        {...keyDownRegister(7)}
                      />
                      円&nbsp;=&nbsp;
                      {(
                        parseFloat(haishaInfo?.kiboSuryo ?? "0") * parseFloat(haishaInfo?.kiboTanka ?? "0")
                      ).toLocaleString()}
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">運送備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.zentaiBiko || ""}
                        onChange={(e) => {
                          const zentaiBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, zentaiBiko }));
                        }}
                        {...keyDownRegister(8)}
                      >
                        {haishaInfo?.zentaiBiko}
                      </textarea>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">添付ファイル</div>
                    <div className="form__value">
                      <div className="form__file">
                        <div>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              if (inputFileRef.current) {
                                inputFileRef.current.click();
                              }
                            }}
                          >
                            ファイルを選択
                          </a>
                          <input type="file" ref={inputFileRef} hidden multiple onChange={onFileSelectHaishaInfo} />
                        </div>
                        <div>
                          {haishaInfo?.files?.map((file) => (
                            <span key={file.path}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = file.source;
                                }}
                              >
                                {file.name}
                              </a>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const files = haishaInfo?.files?.filter((x) => x.path !== file.path) || [];
                                  setHaishaInfo((prev) => ({ ...prev, files }));
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewbox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.66659 2.66683C4.66659 2.31321 4.80706 1.97407 5.05711 1.72402C5.30716 1.47397 5.6463 1.3335 5.99992 1.3335H9.99992C10.3535 1.3335 10.6927 1.47397 10.9427 1.72402C11.1928 1.97407 11.3333 2.31321 11.3333 2.66683V4.00016H13.9999C14.1767 4.00016 14.3463 4.0704 14.4713 4.19543C14.5963 4.32045 14.6666 4.49002 14.6666 4.66683C14.6666 4.84364 14.5963 5.01321 14.4713 5.13823C14.3463 5.26326 14.1767 5.3335 13.9999 5.3335H13.2873L12.7093 13.4282C12.6853 13.7646 12.5348 14.0794 12.288 14.3092C12.0412 14.5391 11.7165 14.6668 11.3793 14.6668H4.61992C4.28268 14.6668 3.95796 14.5391 3.71118 14.3092C3.46439 14.0794 3.31386 13.7646 3.28992 13.4282L2.71325 5.3335H1.99992C1.82311 5.3335 1.65354 5.26326 1.52851 5.13823C1.40349 5.01321 1.33325 4.84364 1.33325 4.66683C1.33325 4.49002 1.40349 4.32045 1.52851 4.19543C1.65354 4.0704 1.82311 4.00016 1.99992 4.00016H4.66659V2.66683ZM5.99992 4.00016H9.99992V2.66683H5.99992V4.00016ZM4.04925 5.3335L4.62059 13.3335H11.3799L11.9513 5.3335H4.04925ZM6.66659 6.66683C6.8434 6.66683 7.01297 6.73707 7.13799 6.86209C7.26301 6.98712 7.33325 7.15669 7.33325 7.3335V11.3335C7.33325 11.5103 7.26301 11.6799 7.13799 11.8049C7.01297 11.9299 6.8434 12.0002 6.66659 12.0002C6.48977 12.0002 6.32021 11.9299 6.19518 11.8049C6.07016 11.6799 5.99992 11.5103 5.99992 11.3335V7.3335C5.99992 7.15669 6.07016 6.98712 6.19518 6.86209C6.32021 6.73707 6.48977 6.66683 6.66659 6.66683ZM9.33325 6.66683C9.51006 6.66683 9.67963 6.73707 9.80466 6.86209C9.92968 6.98712 9.99992 7.15669 9.99992 7.3335V11.3335C9.99992 11.5103 9.92968 11.6799 9.80466 11.8049C9.67963 11.9299 9.51006 12.0002 9.33325 12.0002C9.15644 12.0002 8.98687 11.9299 8.86185 11.8049C8.73682 11.6799 8.66659 11.5103 8.66659 11.3335V7.3335C8.66659 7.15669 8.73682 6.98712 8.86185 6.86209C8.98687 6.73707 9.15644 6.66683 9.33325 6.66683Z"
                                    fill="#888888"
                                  />
                                </svg>
                              </a>
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">回答期限</div>
                    <div className="form__value">
                      {middleKeiro?.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <DateTimePicker
                            className={"mr--5"}
                            day={
                              haishaInfo?.kaitokigenDay
                                ? haishaInfo.kaitokigenDay
                                : item.hikitoriDate
                                ? item.hikitoriDate.add(-3, "d").format()
                                : null
                            }
                            setDay={(kaitokigenDay) => {
                              setHaishaInfo((prev) => ({ ...prev, kaitokigenDay }));
                              // const kaitokigenDay = kaitokigenDay.format("YYYY-MM-DD");
                              // const kaitokigenTime = haishaInfo?.kaitokigenDate
                              //   ? toDayjs(haishaInfo?.kaitokigenDate).format("HH:mm")
                              //   : "15:00";
                              // const kaitokigenDate = toDayjs(kaitokigenDay + " " + kaitokigenTime);
                              // setHaishaInfo((prev) => ({ ...prev, kaitokigenDate }));
                            }}
                            tabIndex={10}
                            keyDownRegister={keyDownRegister}
                            pastPermission={false}
                          />
                          <TimePicker
                            time={
                              haishaInfo?.kaitokigenTime ? haishaInfo.kaitokigenTime : toDayjs("2022-01-25T12:00:00")
                            }
                            setTime={(kaitokigenTime) => {
                              setHaishaInfo((prev) => ({ ...prev, kaitokigenTime }));
                              // const kaitokigenDay = toDayjs(haishaInfo?.kaitokigenDate).format("YYYY-MM-DD");
                              // const kaitokigenTime = kaitokigenTime.format("HH:mm");
                              // const kaitokigenDate = toDayjs(kaitokigenDay + " " + kaitokigenTime);
                              // setHaishaInfo((prev) => ({ ...prev, kaitokigenDate }));
                            }}
                            value={haishaInfo?.kaitokigenTime}
                            tabIndex={11}
                            keyDownRegister={keyDownRegister}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">デポ備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.unsoBiko || ""}
                        onChange={(e) => {
                          const unsoBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, unsoBiko }));
                        }}
                        {...keyDownRegister(8)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__title">社内備考</div>
                    <div className="form__value">
                      <textarea
                        name=""
                        id=""
                        className="w--full"
                        value={haishaInfo?.shanaiBiko || ""}
                        onChange={(e) => {
                          const shanaiBiko = e.target.value;
                          setHaishaInfo((prev) => ({ ...prev, shanaiBiko }));
                        }}
                        {...keyDownRegister(8)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {unsoAnswer}
              </div>
            </div>
          </div>
          <div className="toaster">
            <div className="toaster__message">依頼中の内容が変更されました、データの再送信が必要です</div>
            <div className="toaster__close">
              <a href="javascript:void(0);" className="closeToaster">
                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.1871 3L12 10.1871L4.81286 3L3 4.81286L10.1871 12L3 19.1871L4.81286 21L12 13.8129L19.1871 21L21 19.1871L13.8129 12L21 4.81286L19.1871 3Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="pane__footer">
          <div className="btn-layout --right">
            {(!("unsoCheckKbn" in haishaInfo) || haishaInfo?.unsoCheckKbn === UNSO_CHECK_KBN.SITAGAKI) && (
              <a href="#" className="btn --primary jsToaster" disabled={!executable} onClick={onClickSave}>
                <span>保存する</span>
              </a>
            )}
            <a href="#" className="btn --primary jsShowDialogSend" disabled={!executable} onClick={onClickSend}>
              <span>データを送信</span>
            </a>
            {!!binId && (
              <a href="#" className="btn --delete" onClick={onClickCancel}>
                <span>注文を削除する</span>
              </a>
            )}
          </div>
        </div>
      </div>
      <SaveModal isOpen={isSaveModal} control={saveModalControl} />
      <SendModal isOpen={isSendModal} control={sendModalControl} onOk={refOkEvent.current} />
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={infoMessage} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={errorMessage} />
    </>
  );
}
