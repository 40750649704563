import React, { useContext, memo } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../AppContext";

const links = [
  { name: "回収依頼", to: "/irai" },
  { name: "手配状況", to: "/tehai-jyokyo" },
  { name: "入出荷履歴", to: "/nyushukka-rireki" },
  { name: "マイページ", to: "/mypage" },
];

const MainNavi = (props) => {
  const { current } = props;
  const { me } = useContext(AppContext);

  if (me == null) {
    return null;
  }

  return (
    <div className="main__main-header">
      <div className="tab">
        <div className="tab__menu">
          {links.map(({ name, to }) => (
            <Link key={name + to} to={to} className={`tab__menu-item ${to === current && "--current"}`}>
              <span>{name}</span>
            </Link>
          ))}
        </div>
      </div>

      {/* <div className="hdg --h1">
        <span className="hdg__title">お問い合わせ</span>
      </div> */}
    </div>
  );
};

export default memo(MainNavi);
