import React, { useContext, useEffect, useCallback, useState, memo } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { AppContext } from "../../AppContext";
import { useBoolean } from "../../hooks/useBoolean";
import Menu from "./Menu";
import { routes } from "../../AdminRoutes";

const Header = (props) => {
  const { login } = props;
  const { me, resetMe } = useContext(AppContext);
  const history = useHistory();

  const [isMenuOpen, menuOpenController] = useBoolean(false);

  const onLogout = useCallback(
    (e) => {
      e.preventDefault();
      const isHikitori = me?.roleKbn === "2";
      axios
        .post("/api/logout")
        .then(() => {
          resetMe();
          if (isHikitori) {
            history.replace("/hikitori/hikitori-login");
          } else {
            history.replace("/admin/shain-login");
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    [history, resetMe, me]
  );

  const menus = routes.filter((route) => "funcKeyCode" in route);
  const escFunction = useCallback(
    (event) => {
      // event.preventDefault();
      let keyEvent = null;

      // F1 ~ F4はメニュー切り替えで使用
      const target = menus.find((menu) => menu.funcKeyCode === event.keyCode);
      if (target) {
        keyEvent = () => {
          history.push("/admin" + target.path);
          menuOpenController.off();
          return;
        };
      }

      // F5 ~ F12イベントなどはprops値で切り替え
      const funcKeyCode = (event.keyCode - 111).toString();
      if ("eventF" + funcKeyCode in props) {
        keyEvent = () => {
          props["eventF" + funcKeyCode]();
          return;
        };
      }

      if (!!keyEvent) {
        event.preventDefault();
        keyEvent();
      }
    },
    [props, menus, history, menuOpenController]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return (
    <>
      <div className="header">
        {me?.roleKbn !== "2" && (
          <a
            href="#"
            className="header__menu"
            onClick={(e) => {
              e.preventDefault();
              menuOpenController.on();
            }}
          >
            <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.5 18H0.5V15H18.5V18ZM27.5 10.5H0.5V7.5H27.5V10.5ZM18.5 3H0.5V0H18.5V3Z" fill="white"></path>
            </svg>
          </a>
        )}
        <div className="header__logo">
          <img src="/assets/admin/Images/lgo-h.png" alt="SANKO PALLET STATION" />
        </div>
        <div className="header__employee">
          <div className="employee">
            <div className="employee__dept"></div>
            <div className="employee__name">{me?.shainName || me?.name}</div>
            <div className="employee__pic">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 28.8C14.25 28.8 10.935 26.88 9 24C9.045 21 15 19.35 18 19.35C21 19.35 26.955 21 27 24C26.0083 25.4766 24.6688 26.6867 23.0993 27.5238C21.5299 28.3608 19.7787 28.7991 18 28.8ZM18 7.5C19.1935 7.5 20.3381 7.97411 21.182 8.81802C22.0259 9.66193 22.5 10.8065 22.5 12C22.5 13.1935 22.0259 14.3381 21.182 15.182C20.3381 16.0259 19.1935 16.5 18 16.5C16.8065 16.5 15.6619 16.0259 14.818 15.182C13.9741 14.3381 13.5 13.1935 13.5 12C13.5 10.8065 13.9741 9.66193 14.818 8.81802C15.6619 7.97411 16.8065 7.5 18 7.5ZM18 3C16.0302 3 14.0796 3.38799 12.2597 4.14181C10.4399 4.89563 8.78628 6.00052 7.3934 7.3934C4.58035 10.2064 3 14.0218 3 18C3 21.9782 4.58035 25.7936 7.3934 28.6066C8.78628 29.9995 10.4399 31.1044 12.2597 31.8582C14.0796 32.612 16.0302 33 18 33C21.9782 33 25.7936 31.4196 28.6066 28.6066C31.4196 25.7936 33 21.9782 33 18C33 9.705 26.25 3 18 3Z"
                  fill="#888888"
                ></path>
              </svg>
              <a href="#" onClick={onLogout}>
                ログアウト
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 左メニュー */}
      {me?.roleKbn !== "2" && <Menu {...{ isMenuOpen, menuOpenController }} />}
    </>
  );
};

export default memo(Header);
