import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import "./libs/date";
import axios from "axios";
// const head = document.getElementsByTagName("head").item(0);
// const link = document.createElement("link");
// link.rel = "stylesheet";
// link.href =
//   window.location.pathname.indexOf("/admin") === 0
//     ? "/assets/admin/Css/common.css"
//     : "/assets/Css/common.css";
// link.type = "text/css";
// head.appendChild(link);

// axios.defaults.baseURL = "https://pallet-station.com";
// axios.defaults.headers["authorization"] = "Basic c25rcHM6c2Fua28yMDIxIw==";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("wrapper");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement
);

// registerServiceWorker();
