import React, { useEffect } from "react";
import Header from "./Header";

export default function AnswerThanks() {
  useEffect(() => {}, []);
  return (
    <>
      <Header />
      <div className="main">
        <div className="main__main">
          <div className="main__main-contents">
            <div className="inner">
              <div className="frame --center pb--40 pt--40">
                <div className="hdg --message">
                  <span className="hdg__title">
                    回答ありがとうございます
                    <br />
                    承りました
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
